import React, { useEffect, useRef, useState } from 'react';
import { Modal } from "react-bootstrap";
import Cropper from 'react-easy-crop';
import PencilSimpleRoundedOutlineIcon from '../../assets/images/icons/PencilSimpleRoundedOutline.svg';
import Spinner from '../Common/Spinner';

interface ImageCropperProps {
  file: File;
  onSave: (croppedImage: string, imageName: string) => void;
}
const ImageUploader: React.FC<ImageCropperProps> = ({ file, onSave }) => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [croppedImageSrc, setCroppedImageSrc] = useState<string | null>(null);
  const [imageName, setImageName] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [openCropper, setOpenCropper] = useState(false);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => { setShow(false); onSave(null, null); };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const loadImage = async () => {
      if (file) {
        const imageDataUrl = await readFile(file);
        setImageName(file.name);
        setImageSrc(imageDataUrl);
        setOpenCropper(true);
        handleShow();
      }
    };

    loadImage(); // Call the async function inside the effect
  }, []);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const readFile = (file: File): Promise<string> => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result as string));
      reader.readAsDataURL(file);
    });
  };

  const getCroppedImg = (imageSrc: string, croppedAreaPixels: any): Promise<string> => {
    const image = new Image();
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');

    if (!ctx) return Promise.reject(new Error('Canvas context not available'));

    image.src = imageSrc;
    return new Promise((resolve) => {
      image.onload = () => {
        const { width, height } = croppedAreaPixels;
        canvas.width = width;
        canvas.height = height;
  
        ctx.drawImage(
          image,
          croppedAreaPixels.x,
          croppedAreaPixels.y,
          width,
          height,
          0,
          0,
          width,
          height
        );

        resolve(canvas.toDataURL('image/jpeg'));
      };
    });
  };

  const handleSave = async () => {
    setIsLoading(true);
    if (!croppedAreaPixels || !imageSrc) return;

    const croppedImage = await getCroppedImg(imageSrc, croppedAreaPixels);
    setCroppedImageSrc(croppedImage);
    if (openCropper){
      setOpenCropper(false);
      setIsLoading(false);
    }else
      onSave(croppedImage, imageName);

  };

  return (
   
      <>
        {/* Image Uploader Modal Pop-up */}
        <Modal
          show={show}
          onHide={handleClose}
          backdrop="static" // This disables closing the modal by clicking outside of it
          keyboard={false}
          className="modal__wd modal__wd--submitSellReq modal_imageUploader"
        >
          <Modal.Header className="flex-column align-items-start">
            <Modal.Title>Edit Photo</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {openCropper ? (<div style={{ position: 'relative', width: '100%', height: 250 }}>
                  <Cropper
                    image={imageSrc as string}
                    crop={crop}
                    zoom={zoom}
                    aspect={1}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    cropShape="round" // This makes the cropper circle-shaped
                    showGrid={false} // Hide the grid lines
                  />
                </div>

            ) : (
              <div className='image-uploader-wrapper' style={{ width: 250, height: 250, margin: 'auto' }}>
                <span className="profile-avatar position-relative d-inline-block w-100 h-100">
                  <img
                    src={croppedImageSrc}
                    alt="Cropped"
                    style={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '50%',
                      objectFit: 'cover',
                      boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
                    }}
                  />
                  <span className="profile-edit position-absolute" onClick={() => setOpenCropper(true)}>
                    <img src={PencilSimpleRoundedOutlineIcon} alt="ProfileAvatarIcon" width={32} height={32} />
                  </span>
                </span>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer className="justify-content-center">
            <div className="btn-group row d-flex justify-content-between w-100">
              <div className="col-6 ps-0">
                <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                  Cancel
                </button>
              </div>
              <div className="col-6 pe-0">
                <button className="primary-btn w-100" onClick={handleSave} disabled={isLoading}>
                  {isLoading && <Spinner />} Save
                </button>
              </div>
            </div>
          </Modal.Footer>
        </Modal>
      </>
  );
};

export default ImageUploader;
