import { useHistory } from "react-router";
import Swal from "sweetalert2";
import Cookies from "universal-cookie";
import { InventoryApiService } from "./InventoryApiService";

export const CommonService = {

  Toast: Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer);
      toast.addEventListener('mouseleave', Swal.resumeTimer);
    }
  }),

  showConfirmation,
  formatDateTime,
  isAdmin,
  isUser,
  getCompanyId,
  getCompanyName,
  getUserId,
  getProfile,
  showPdf,
  showPdfForOrder,
  formatTime,
  formatDate,
  numberFormatter,
  formatRecentActivityDate,
  formatRecentActivityTime,
  convertToEST
}

async function showConfirmation() {
  const result = await Swal.fire({
    title: 'Are you sure?',
    text: 'You won\'t be able to revert this!',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonColor: '#d33',
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
  });

  return result.isConfirmed;
}

function convertToEST(dateTimeString: string): string {
  // Define regex pattern to match the input format
  const dateTimePattern = /^(.*), (\d{4}) \| (\d{2}):(\d{2}) (AM|PM)$/;
  const match = dateTimeString.match(dateTimePattern);

  if (match) {
    // Extract the parts from the regex match
    const [ , monthDay, year, hour, minute, period ] = match;
    const dateString = `${monthDay}, ${year} ${hour}:${minute} ${period}`;

    // Parse the date string to a Date object
    const dateTime = new Date(dateString);

    // Check if period is PM and adjust the hour
    let hours = parseInt(hour, 10);
    if (period === 'PM' && hours < 12) {
      hours += 12;
    } else if (period === 'AM' && hours === 12) {
      hours = 0;
    }

    // Construct the new Date object in UTC
    const utcDate = new Date(Date.UTC(
      dateTime.getFullYear(),
      dateTime.getMonth(),
      dateTime.getDate(),
      hours,
      parseInt(minute, 10)
    ));

    // Format the date and time to EST
    const optionsDate: Intl.DateTimeFormatOptions = {
      timeZone: 'America/New_York',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      timeZone: 'America/New_York',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };

    const formattedDate = utcDate.toLocaleDateString('en-US', optionsDate);
    const formattedTime = utcDate.toLocaleTimeString('en-US', optionsTime);

    return `${formattedDate} | ${formattedTime}`;
  }

  // Return an error message if the input string is not in the expected format
  return 'Invalid date format';
}

function formatDateTime(dateTimeString: any): any {
  if (dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const optionsDate: Intl.DateTimeFormatOptions = {
      timeZone: 'America/New_York',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };
    const optionsTime: Intl.DateTimeFormatOptions = {
      timeZone: 'America/New_York',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    
    const formattedDate = dateTime.toLocaleDateString('en-US', optionsDate);
    const formattedTime = dateTime.toLocaleTimeString('en-US', optionsTime);
    
    return `${formattedDate} | ${formattedTime}`;
  } else {
    return '';
  }
}





function isAdmin() {
  const cookies = new Cookies();
  const role = cookies.get('userRole');
  return role == 'SUPER_ADMIN' ? true : false;
}

function isUser() {
  const cookies = new Cookies();
  const role = cookies.get('userRole');
  return role == 'ADMIN' ? true : false;
}

function getCompanyId() {
  const cookies = new Cookies();
  const companyId = cookies.get('companyId');
  return companyId;
}

function getCompanyName() {
  const cookies = new Cookies();
  const companyName = cookies.get('companyName');
  return companyName;
}

function getUserId() {
  const cookies = new Cookies();
  const userId = cookies.get('userId');
  return userId;
}

function getProfile() {
  const profile = localStorage.getItem('profile');
  return profile;
}

async function showPdf (id:any, companyId:any, siteId:any, fileName:any ) {
    
  try {
    const response:any = await InventoryApiService.showPDF(id, companyId, siteId,fileName);
    const dataUrl = URL.createObjectURL(response.data);

    Swal.fire({
      html: `<iframe src="${dataUrl}" width="100%" height="600px"></iframe>`,
      width: '80%',
      grow: 'fullscreen',
      showCloseButton: true,
      showConfirmButton: false,
    });
  } catch (error) {
    console.error('Error fetching PDF:', error);
    Swal.fire('Error', 'Failed to load PDF', 'error');
  }
}

async function showPdfForOrder (id:any, companyId:any, siteId:any, fileName:any ) {
    
  try {
    const response:any = await InventoryApiService.showPDFForOrder(id, companyId, siteId,fileName);
    const dataUrl = URL.createObjectURL(response.data);

    Swal.fire({
      html: `<iframe src="${dataUrl}" width="100%" height="600px"></iframe>`,
      width: '80%',
      grow: 'fullscreen',
      showCloseButton: true,
      showConfirmButton: false,
    });
  } catch (error) {
    console.error('Error fetching PDF:', error);
    Swal.fire('Error', 'Failed to load PDF', 'error');
  }
};

function formatDate(dateTimeString: string | null | undefined): string {
  if (dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const now = new Date();
    
    const isToday = dateTime.toDateString() === now.toDateString();
    const isYesterday = dateTime.toDateString() === new Date(now.setDate(now.getDate() - 1)).toDateString();

    if (isToday) {
      return ''; // Return empty string for today's date
    } else if (isYesterday) {
      return 'Yesterday';
    } else {
      const options: Intl.DateTimeFormatOptions = {
        timeZone: 'America/New_York',
        month: 'short',
        day: 'numeric',
        year: 'numeric'
      };
      return dateTime.toLocaleDateString('en-US', options);
    }
  } else {
    return '';
  }
}

function formatTime(dateTimeString: string | null | undefined): string {
  if (dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const now = new Date();
    const diffInSeconds = Math.floor((now.getTime() - dateTime.getTime()) / 1000);

    const minutes = Math.floor(diffInSeconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    const rtf = new Intl.RelativeTimeFormat('en', { numeric: 'auto' });

    if (days > 0) {
      const options: Intl.DateTimeFormatOptions = {
        timeZone: 'America/New_York',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
      };
      return dateTime.toLocaleTimeString('en-US', options);
    } else if (hours > 0) {
      return rtf.format(-hours, 'hour');
    } else if (minutes > 0) {
      return rtf.format(-minutes, 'minute');
    } else {
      return 'just now';
    }
  } else {
    return '';
  }
}

function numberFormatter(value: any) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'decimal', // Use 'decimal' style instead of 'currency'
    maximumFractionDigits: 2, // Adjust this to control the number of decimal places
  });
  return formatter.format(value);
}

function formatRecentActivityDate(dateTimeString: any): string {
  if (dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'America/New_York',
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    };
    return dateTime.toLocaleString('en-US', options);
  } else {
    return '';
  }
}

function formatRecentActivityTime(dateTimeString: any): string {
  if (dateTimeString) {
    const dateTime = new Date(dateTimeString);
    const options: Intl.DateTimeFormatOptions = {
      timeZone: 'America/New_York',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true
    };
    return dateTime.toLocaleString('en-US', options);
  } else {
    return '';
  }
}


