import React, { useState } from "react";
import { Button } from "@mui/material";
import { OrderApiService } from "../../Service/OrderApiService";
import { useRoute } from "../Common/route";
import Modal from 'react-bootstrap/Modal';
import { Button as BootstrapButton } from 'react-bootstrap';
import Spinner from '../Common/Spinner';
import { darken } from '@mui/material/styles';
import "../popups/CommonPopup.scss";

interface ChildProps {
  rejectUser: any;
  disabled: boolean;
}

const PurchaseReason: React.FC<ChildProps> = ({ rejectUser, disabled }) => {
  const [show, setShow] = useState(false);
  const [purposeNote, setPurposeNote] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const route = useRoute();

  const handleClose = () => {
    setShow(false);
    setError("");
  }
  const handleShow = () => setShow(true);

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setPurposeNote(value);
    setError("");
  };

  const addPurpose = async () => {
    if (!purposeNote.trim()) {
      setError("Please add purpose");
      return;
    }

    setIsLoading(true);
    const orderItemReq = { ...rejectUser, purposeNote };

    try {
      const response = await OrderApiService.saveOrderItems(orderItemReq);
      console.log(response.data);
      handleClose();
      route("/user/my-cart");
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Button
        disabled={disabled}
        variant="contained"
        sx={{
          ml: 1,
          textTransform: "none",
          backgroundColor: '#E15A0E',
          borderRadius: '100px',
          padding: '10px 48px',
          height: '42px',
          fontSize: '18px',
          fontWeight: 700,
          color: '#FFF',
          '&:hover': {
            backgroundColor: darken('#E15A0E', 0.2),
          },
        }}
        onClick={handleShow}
      >
        Add to Cart
      </Button>

      <Modal show={show} onHide={handleClose} className='modal modal__wd modal__wd--submitSellReq fade'>
        <Modal.Header className='flex-column align-items-start'>
          <Modal.Title>Purpose Note</Modal.Title>
          <p className="modal-text text-start">
            Please specify the purpose for this order.
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="form__inner">
            <textarea
              className={`form-control ${error ? "is_invalid" : ""}`}
              style={{ resize: "none" }}
              aria-label="With textarea"
              value={purposeNote}
              onChange={handleInputChange}
              rows={8}
              placeholder="Add purpose..."
            />
            {error && <div className="error-message">{error}</div>}
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                Cancel
              </button>
            </div>
            <div className="col-6 pe-0">
              <button className="primary-btn w-100" onClick={addPurpose} disabled={isLoading}>
                {isLoading && <Spinner />}Add
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default PurchaseReason;  