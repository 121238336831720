import { useEffect, useState } from "react";
import { Service } from "../../Service/ApiService";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import SearchBar from "../Static/SearchBar";
import "../Dynamic/LoginHistory.scss";
import { IconButton, Tooltip } from "@mui/material";
import { CommonService } from "../../Service/CommonService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory } from "react-router";

const LoginHistory = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [search, setSearch] = useState("");
  const history = useHistory();

  useEffect(() => {
    //call the service which load all the login history details
    loadLoginHistoryDetails();
  }, []);

  const loadLoginHistoryDetails = async () => {
    try {
      setIsLoading(true);
      const response = await Service.getLoginHistory().then((response) => {
        console.log(response.data);
        setTableData(Object.values(response.data));
        setIsLoading(false);
      });
    } catch (error) {
      console.log("error fetching data" + error);
      setIsLoading(false);
    }
  };

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "ipAddress",
      headerName: "IP Address",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "userAgent",
      headerName: "User Agent",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "address",
      headerName: "Location",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "loginTime",
      headerName: "Login Time",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value} />,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.loginTime ? row.loginTime : null;

        //call the method which give the formatted date and time
        if (dateTimeString) return CommonService.formatDateTime(dateTimeString);
      },
    },
  ];

  // Filter rows based on search query
  const filteredRows = tableData.filter(
    (row) =>
      row.name?.toLowerCase().includes(search?.toLowerCase()) ||
      row.email?.toLowerCase().includes(search?.toLowerCase()) ||
      row.city?.toLowerCase().includes(search?.toLowerCase())
  );

  // Map filtered rows with unique IDs
  const tableDataWithIds = filteredRows.map((rowData, index) => ({
    ...rowData,
    id: index + 1, // Assigning a unique ID based on the index
  }));

  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const handleBack = () => {
    history.goBack();
  }
  
  return (
    <div className="usersList">
      <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        <IconButton
          onClick={handleBack}
          aria-label="ArrowBackIcon"
          sx={{
            backgroundColor: "#FFF",
            mb: 1,
            "&:hover": { backgroundColor: "#FFF" },
          }}
        >
          <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
        </IconButton>
        <div className="header--title mb-2 me-2">
          <h1 className="title mb-0 ms-2">
            Login History <i className="bi bi-heart-fill"></i>
          </h1>
        </div>
        <div className="header--menu ms-auto mb-2">
          <div className="header--menu-items row d-flex justify-content-end">
            <div className="col-auto mb-2">
              <SearchBar search={search} setSearch={setSearch} />
            </div>
            {/* <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2"></div> */}
          </div>
        </div>
      </div>
      <div className="user-table-container">
        <div className="userTable">
          <DataGrid
            localeText={localeText}
            disableRowSelectionOnClick={true}
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            // checkboxSelection
            // getRowClassName={getCellClassName}
          />
        </div>
      </div>
    </div>
  );
};

export default LoginHistory;
