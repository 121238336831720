import { faUserCheck, faUserTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { InventoryApiService } from "../../Service/InventoryApiService";
import { CommonService } from "../../Service/CommonService";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import Spinner from "../Common/Spinner";

interface ChildProps {
  rejectUser: any;
  fetchData: any;
  isFromDropdown: boolean;
  popupShow: boolean;
}

const SingleRejectCSV: React.FC<ChildProps> = ({
  rejectUser,
  fetchData,
  isFromDropdown,
  popupShow,
}) => {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setisRejected(false);
    fetchData();
    setRejectedCSV(null);
    setNote("");

    setShow(false);
  };
  const handleShow = () => setShow(true);
  const [rejectionNote, setNote] = useState<string>("");

  const [rejectedCSV, setRejectedCSV] = useState<any>();
  const [isRejected, setisRejected] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>("");

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    setNote(value);
    setError("");
    const updatedUser = {
      id: rejectUser.id,
      rejectionNote: value,
      status: "REJECTED",
    };
    setRejectedCSV(updatedUser);
  };

  useEffect(() => {
    setShow(popupShow);
  }, []);

  const handleSubmit = () => {
    if (rejectionNote.trim().length === 0) {
      setError("Please enter rejection note");
      return;
    }

    setIsLoading(true);
    InventoryApiService.updateInventoryCSV(rejectedCSV)
      .then((response) => {
        setIsLoading(false);
        setisRejected(true);
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  return (
    <>
      {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
      {/* {isFromDropdown && <div onClick={handleShow}> <PersonOffIcon fontSize="small" sx={{ mr: 1 }} />Rejected</div>} */}
      {!isFromDropdown && (
        <Tooltip title="Reject" arrow>
          <IconButton onClick={handleShow}>
            <FontAwesomeIcon icon={faUserTimes} />
          </IconButton>
        </Tooltip>
      )}

      <Modal
        className="modal__wd modal__wd--submitSellReq"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title className="mb-1">Reject CSV</Modal.Title>

          {!isRejected && (
            <p className="modal-text text-start">
              Please specify the reason for this CSV being rejected.
            </p>
          )}
          {isRejected && (
            <p className="modal-text text-start">
              CSV Rejected successfully !{" "}
            </p>
          )}
        </Modal.Header>

        {!isRejected && (
          <Modal.Body>
            <div className="form__inner">
            <textarea
              className={`form-control ${error ? "is_invalid" : ""}`}
              aria-label="With textarea"
              value={rejectionNote}
              onChange={handleInputChange}
              rows={8}
              style={{resize:"none"}}
              placeholder="Enter text here..."
            />
             {error && <div className="error-message">{error}</div>}
             </div>
          </Modal.Body>
        )}

        {!isRejected && (
          <Modal.Footer className="justify-content-center">
            <div className="btn-group row d-flex justify-content-between w-100">
              <div className="col-6 ps-0">
                <button
                  className="secondary-btn w-100"
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  No
                </button>
              </div>
              <div className="col-6 pe-0">
                <button
                  className="primary-btn w-100"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading && <Spinner />}Yes
                </button>
              </div>
            </div>
          </Modal.Footer>
        )}

        {isRejected && (
          <Modal.Footer className="justify-content-center bg-transparent">
            <div className="btn-group row d-flex justify-content-between w-100">
              <div className="col-12">
                <Button className="secondary-btn w-100" onClick={handleClose}>
                  Close
                </Button>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default SingleRejectCSV;
