import React, { useEffect, useState } from 'react';
import { faUserTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Service } from '../../Service/ApiService';
import { CommonService } from '../../Service/CommonService';
import Spinner from '../Common/Spinner';
import "../popups/CommonPopup.scss"

interface ChildProps {
    rejectUser: any[];
    displayIcon: boolean;
    reloadTable: () => void;
}

const SingleReject: React.FC<ChildProps> = ({ rejectUser, displayIcon, reloadTable }) => {
    const [show, setShow] = useState(false);
    const [selectedUserName, setSelectedUserName] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error,setError] = useState<string>("");
    const [rejectionNote,setRejectionNote] = useState<string>("");

   

    useEffect(() => {
        if (rejectUser.length) {
            const emails = rejectUser.map(user => user.email);
            setSelectedUserName(emails);
        }
    }, [rejectUser]);

    const handleClose = () => {
        setShow(false);
        setError("");
    };

    const handleShow = () => setShow(true);

    const onSubmit = async (event:any) => {
        
        event.preventDefault();

        if(rejectionNote.trim().length === 0) {
            console.log("hello");
            setError("Please enter rejection note");
            return;
        }

        setIsLoading(true);
        const updatedUsers = rejectUser.map(user => ({
            ...user,
            rejectionNote: rejectionNote,
            userStatus: "REJECTED"
        }));

        try {
            await Service.updateUserProfile(updatedUsers);
            setIsLoading(false);
            CommonService.Toast.fire({
                title: "User rejected successfully!",
                icon: 'success',
            });
            reloadTable();
            handleClose();
        } catch (error) {
            setIsLoading(false);
            console.error(error);
            CommonService.Toast.fire({
                title: "An error occurred while rejecting the user.",
                icon: 'error',
            });
        }
    };

    function handleChange(event: any) {
        const value = event.target.value;
        console.log(value);
        setError("");
        setRejectionNote(value);
    }

    return (
        <>
            {displayIcon ? (
                <Tooltip title="Reject" arrow>
                    <IconButton onClick={handleShow}>
                        <FontAwesomeIcon icon={faUserTimes} />
                    </IconButton>
                </Tooltip>
            ) : (
                <Tooltip title="Reject" arrow>
                    <button 
                        onClick={handleShow} 
                        disabled={rejectUser.length === 0} 
                        style={{
                            border: 'none', 
                            background: 'transparent',
                            cursor: rejectUser.length === 0 ? 'not-allowed' : 'pointer',
                        }}
                    > 
                        Reject 
                    </button>
                </Tooltip>
            )}

            <Modal show={show} onHide={handleClose} className='modal__wd modal__wd--submitSellReq'>
                <form onSubmit={onSubmit}>
                    <Modal.Header className='flex-column align-items-start'>
                        <Modal.Title>Reject User</Modal.Title>
                        <p className="modal-text">
                            Please specify the reason for this user being rejected.
                        </p>
                        <ul className='names'>
                            {selectedUserName.map((item, index) => (
                                <li key={index}>{item}</li>
                            ))}
                        </ul>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="form__inner">
                        <textarea 
                            className={`form-control ${error ? 'is_invalid' : ''}`}
                            rows={8} 
                            onChange={handleChange}
                            value={rejectionNote}
                            style={{resize:"none"}}
                            placeholder="Enter rejection reason here..." 
                        />
                        {error && <div className="error-message">{error}</div>}
                        </div>
                    </Modal.Body>
                    <Modal.Footer className='justify-content-center'>
                        <div className="btn-group row d-flex justify-content-between w-100">
                            <div className="col-6 ps-0">
                                <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                                    No
                                </button>
                            </div>
                            <div className="col-6 pe-0">
                                <button type="submit" className="primary-btn w-100" disabled={isLoading}>
                                    {isLoading && <Spinner />}Yes
                                </button>
                            </div>
                        </div>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default SingleReject;