import { ListFormat } from 'typescript';
import AxiosApiService from './AxiosService';
import { Url } from '../Constants/Url';

export const Service = {
    getUserById, updateUser, getAllApprovedUsers, getAllUsers, sendInvite, registerUser, getAllRoles, getSubmittedUsers, authentication, getAllTTBTypeAndFacility, updateUserProfile,
    resetUserPassword, getSubmittedUsersCount, uploadAndSave , removeUserProfile, getLoginHistory, fetchOpenRequestCount,fetchRecentRequestCount, fetchUnreadMessages,
    uploadDocs,fetchCompaniesList,fetchCompanysOpenRequestCountByCompanyId, fetchCompanyUserData, fetchCompanyById, addUser, addCompany, getCityAndState, getEmailById, getActiveUsersCount, getAllRejectedUsers, getRejectedUsersCount
    ,fetchRequestData, changePassword, uploadProfileImage, getProfileImage,sendResetPasswordLink, removeProfileImage, logout, deleteCompanyById
}

async function getUserById(userId : any) {
    return await AxiosApiService.get<Object>(Url.GET_USER_BY_USER_ID + userId);
}

async function updateUser(user: any) {
    return await AxiosApiService.put<Object>(Url.UPDATE_USER, user);
}

async function getAllApprovedUsers(companyId:any) {
    return await AxiosApiService.get<Object>(Url.GET_ALL_USERS + "/" + companyId);
}

async function getAllUsers() {
    return await AxiosApiService.get<Object>(Url.GET_ALL_USERS);
}

async function sendInvite(emailIds: any, flag: any) {
    let path = Url.SEND_INVITE + "/" + flag;
    return await AxiosApiService.post<ListFormat>(path, emailIds);
}

async function authentication(credential: any) {
    return await AxiosApiService.post<any>(Url.LOGIN, credential);
}

async function logout() {
    return await AxiosApiService.get<any>(Url.LOGOUT);

}
async function registerUser(user: any) {
    return await AxiosApiService.post<Object>(Url.USER_REGISTRATION, user);
}

async function getAllRoles() {
    return await AxiosApiService.get<Object>(Url.GET_ALL_ROLES);

}

async function getSubmittedUsers() {
    return await AxiosApiService.get<Object>(Url.GET_SUBMITTED_USERS);

}

async function updateUserProfile(user: any) {
    return await AxiosApiService.put<Object>(Url.UPDATE_STATUS_IN_BULK, user)
}

async function removeUserProfile(list:any) {
    let path = Url.BULK_REMOVE_PROFILES + "/" + true;
    return await AxiosApiService.put<Object>(path, list)
}

async function resetUserPassword(user: any) {
    return await AxiosApiService.post<Object>(Url.RESET_PASSWORD, user)
}


//    async function getAllInventory() {
//        let path = Url.GET_ALL_INVENTORY;
//        axiosInstance.defaults.headers.Accept = 'text/event-stream'
//        return await get(path, {})
//    }

//    async function getProductById(productId: any) {
//        let path = Url.GET_INVENTORY_BY_ID + productId;
//        //axiosInstance.defaults.headers.Accept= 'text/event-stream'
//        return await get(path, {})
//    }

async function uploadAndSave(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.UPLOAD_CSV, fileUpload)
}

async function uploadDocs(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.UPLOAD_DOC, fileUpload)
}

async function getAllTTBTypeAndFacility() {
    return await AxiosApiService.get<any>(Url.GET_ALL_TYPE_AND_FACILITY);
}

async function getSubmittedUsersCount() {
    return await AxiosApiService.get<any>(Url.GET_SUBMITTED_USER_COUNT);
}

async function getLoginHistory() {
    return await AxiosApiService.get<any>(Url.GET_LOGIN_HISTORY);
}

async function fetchOpenRequestCount() {
    return await AxiosApiService.get<any>(Url.GET_OPEN_REQUEST_COUNT);
}

async function fetchRecentRequestCount(params: { id:string }) {
    return await AxiosApiService.getWithParams<Object>(Url.GET_RECENT_REQUEST_COUNT, {
        params: {
            companyId: params.id
        }
    });
}

async function fetchRequestData(params: { id:string }) {
    console.log("id" + params);
    return await AxiosApiService.getWithParams<Object>(Url.GET_REQUEST_DATA, {
        params: {
            companyId: params
        }
    });
}

async function fetchUnreadMessages(params: { id:string }) {
    return await AxiosApiService.getWithParams<Object>(Url.FETCH_UNREAD_MESSAGES, {
        params: {
            companyId: params.id
        }
    });
}

async function fetchCompaniesList(){
    return await AxiosApiService.get<any>(Url.FETCH_COMPANIES_LIST);
}

async function fetchCompanysOpenRequestCountByCompanyId(companyId:any) {
    return await AxiosApiService.get<any>(Url.FETCH_COMPANIES_OPEN_REQUEST_COUNT + '/' + companyId);
}

async function fetchCompanyUserData(companyId:any) {
    return await AxiosApiService.get<any>(Url.FETCH_COMPANIES_USER_DATA + '/' + companyId);
}

async function fetchCompanyById(companyId:any) {
    return await AxiosApiService.get<any>(Url.FETCH_COMPANIES_BY_ID + '/' + companyId);
}

async function getEmailById(id:any) {
    return await AxiosApiService.get<any>(Url.GET_EMAIL_BY_ID + '/' + id);
}

async function addUser(data:any) {
    return await AxiosApiService.post<any>(Url.ADD_USER, data);
}

async function addCompany(data:any) {
    return await AxiosApiService.post<any>(Url.ADD_COMPANY,data);
}

async function getCityAndState(zipcode:any) {
    return await AxiosApiService.post<any>(Url.GET_CITY_AND_STATE + '/' + zipcode, "");
}

async function getAllRejectedUsers(companyId:any) {
    return await AxiosApiService.get<any>(Url.GET_ALL_REJECTED_USERS + "/" + companyId);
}

async function getRejectedUsersCount(companyId:any) {
    return await AxiosApiService.get<any>(Url.GET_REJECTED_USER_COUNT + "/" + companyId);
}

async function getActiveUsersCount() {
    return await AxiosApiService.get<any>(Url.GET_ACTIVE_USER_COUNT);
}

async function changePassword(passwordDetails: any) {
    return await AxiosApiService.put<Object>(Url.CHANGE_PASSWORD, passwordDetails);
}

async function uploadProfileImage(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.UPLOAD_PROFILE_IMAGE, fileUpload)
}

async function getProfileImage(downloadDoc: any) {
    return await AxiosApiService.postBlob<Object>(Url.GET_PROFILE_IMAGE, downloadDoc);
}

async function sendResetPasswordLink(email:any) {
    return await AxiosApiService.get<any>(Url.SEND_RESET_PASSWORD_LINK + "/" + email);
}

async function removeProfileImage(fileUpload: any) {
    return await AxiosApiService.post<any>(Url.REMOVE_PROFILE_IMAGE, fileUpload)
}

async function deleteCompanyById(companyIds: any) {
    return await AxiosApiService.post<any>(Url.DELETE_COMPANIES_BY_ID , companyIds);
}