import React, { useEffect, useRef, useState } from "react";
import { OrderApiService } from "../../Service/OrderApiService";
import { useHistory, useParams } from "react-router";
import { CommonService } from "../../Service/CommonService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import "../Dynamic/UserList.scss";
import { IconButton, Tooltip, Typography } from "@mui/material";
import { ButtonGroup } from "react-bootstrap";
import ContainerMessageBox from "./ContainerMessageBox";
import UploadDocs from "../popups/uploadDocs";
import "../Dynamic/InventoryCSV.scss";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Stepper, Step, StepLabel, StepContent, Paper } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import Constant from "../../Constant";
import Modal from "react-bootstrap/Modal";
import AccountTransferIcon from "../../assets/images/icons/AccountTransferIcon.svg";
import AccountTransferCrossIcon from "../../assets/images/icons/AccountTransferCrossIcon.svg";
import ShippingCrossIcon from "../../assets/images/icons/LocalShippingCrossIcon.svg";
import StatusDropdown from "../Common/StatusDropdown";
import Spinner from "../Common/Spinner";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { common } from "@mui/material/colors";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";


const ViewOfferRequest: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [offerRequest, setOfferRequest] = useState<any>(null);
  const [offerRequestDocs, setOfferRequestDocs] = useState<any[]>([]);
  const [offerRequestHistoryData, setOfferRequestHistoryData] = useState<any[]>(
    []
  );
  const rejectionNote = useRef("");
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "" });
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [isOpenEditSection, setOpenEditSection] = useState(false);
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);

  const handleClose = () => {
    setShow(false);
    setError("");
    setNote("");
  };
  const [error, setError] = useState<string>("");

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const routeBack = () => {
    if (CommonService.isUser()) history.push("/user/offer-request");
    else history.push("/master/offer-request");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      fetchOfferRequestDetail();
      fetchOfferRequestDocs();
      fetchOfferRequestHistory();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOfferRequestDetail = async () => {
    const response: any = await OrderApiService.fetchOfferRequestById(id);
    setOfferRequest(response.data);
  };

//handle edit button click
const handleEditButtonClick = () => {
  if(isOpenEditSection) {
    setOpenEditSection(false);
  } else {
    setMessageBoxOpen(false);
    setOpenEditSection(true);
  }
}

//handle message box click
const handleMessageBoxClick = () => {

  if(isMessageBoxOpen) {
    setMessageBoxOpen(false);
  }
  else {
  setOpenEditSection(false);
  setMessageBoxOpen(true);
  }
}

  const fetchOfferRequestDocs = async () => {
    setIsLoading(true);
    const response: any = await OrderApiService.fetchOfferRequestDocs(id);
    setOfferRequestDocs(response.data);
    setIsLoading(false);
  };

  const fetchOfferRequestHistory = async () => {
    const response: any = await OrderApiService.fetchOfferRequestHistory(id);
    setOfferRequestHistoryData(response.data);
  };

  const downloadOfferRequestDOC = async (item: any) => {
    const requestDto = {
      id: item.id,
      siteId: offerRequest.siteId,
      companyId: offerRequest.companyId,
      fileName: item.fileName,
    };

    try {
      const response: any = await OrderApiService.downloadOfferRequestDOC(
        requestDto
      );
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  //formate the status
  function formatStatus(status?: string): string {
    let formattedStatus = "";
    if (status !== undefined) {
      formattedStatus = status
        ?.split(/[_\\]/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }

    return formattedStatus;
  }

  const getVariant = (status: any) => {
    switch (status) {
      case "ACCEPTED":
        return "success";
      case "PENDING_REVIEW":
        return "primary";
      case "REJECTED":
        return "danger";
      case "CANCELLED":
        return "dark";
      case "SHIPPED":
        return "info";
      case "DELIVERED":
        return "secondary";
      default:
        return "secondary";
    }
  };

  const handleStatusChange = async (newStatus: string) => {
    // const statusMap: { [key: string]: string } = {
    //   "1": "Accepted",
    //   "2": "Pending Review",
    //   "3": "Rejected",
    //   "4": "Shipped",
    //   "5": "Cancelled",
    //   "6": "Delivered",
    // };

    // const newStatus = statusMap[eventKey];

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: id,
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
    };

    statusDetailsRef.current = sd;
    if (statusDetailsRef.current.status === Constant.OrderStatus.SELLER_REJECTED) {
      handleShow();
    } else {
      changeOfferStatus();
    }
  };

  const changeOfferStatus = async () => {

    if (statusDetailsRef.current.status === Constant.OrderStatus.SELLER_REJECTED && rejectionNoteValue.trim().length === 0) {
      setError("Please enter rejection note");
      return;
    }

    setIsLoading(true);
    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;

      await OrderApiService.changeOfferStatus(statusDetailsRef.current);
      setIsLoading(false);
      CommonService.Toast.fire({
        title: "Offer Request updated successfully",
        icon: "success",
      });

      handleClose();
      fetchOfferRequestDetail();
    } catch (error) {
      setIsLoading(false);
      console.log("error occurred which api calling");
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;
  };

  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.fileName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Download document" arrow>
            <IconButton
              onClick={(event) => downloadOfferRequestDOC(params.row)}
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  return (
    <>
      <div className="usersList">
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
          {CommonService.isAdmin() && (<div className="back__button me-3" onClick={routeBack}>
            <IconButton
              aria-label="ArrowBackIcon"
              sx={{ backgroundColor: "#FFF", mb: 1 }}
            >
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton>
          </div>)}

          {CommonService.isAdmin() && (
            <div className="header--title mb-2 me-3">
              <h1 className="title mb-0">
                Offer Request
                <i className="bi bi-heart-fill"></i>
              </h1>
            </div>
          )}

          <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
            <div className="company__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Company name
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: "#333333",
                  fontSize: { xl: "18px", xs: "16px" },
                  fontWeight: 700,
                }}
              >
                {offerRequest?.companyName}
              </Typography>
            </div>
            <div className="person__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted by
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {offerRequest?.firstName}
              </Typography>
            </div>
            <div className="time__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted on
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {CommonService.formatDateTime(offerRequest?.createdDate)}
              </Typography>
            </div>
          </div>

          <div className="header--menu ms-auto mb-xxl-0">
            <div className="header--menu-items">
              {CommonService.isAdmin() && offerRequest?.status === "PENDING_REVIEW" && (<IconButton aria-label="edit" size="small" onClick={handleEditButtonClick} sx={{borderRadius: "50%", backgroundColor: isOpenEditSection? "#97AEB8": "#FFF", color: isOpenEditSection? "#FFF": "#1C1B1F", marginInline: "8px", padding: "8px"}}>
                <BorderColorIcon />
              </IconButton>)}
              <IconButton aria-label="message" size="small" onClick={handleMessageBoxClick} sx={{ borderRadius: "50%", backgroundColor: isMessageBoxOpen? "#97AEB8": "#FFF", color: isMessageBoxOpen? "#FFF": "#1C1B1F", marginInline: "8px", padding: "8px" }}>
                <MarkunreadOutlinedIcon />
              </IconButton>
              {offerRequest?.status === "PENDING_REVIEW" && CommonService.isUser() && (
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={false}
                  allowedLabels={["Cancelled"]}
                />
              )}
              {((offerRequest?.status !== "PENDING_REVIEW" && CommonService.isUser()) || CommonService.isAdmin()) &&
                <StatusDropdown
                  currentStatus={formatStatus(offerRequest?.status)}
                  onStatusChange={(newStatus) => {
                    handleStatusChange(newStatus);
                  }}
                  disableCondition={CommonService.isUser() || offerRequest?.status === "CANCELLED"}
                  allowedLabels={[
                    "Delivered",
                    "Under Review by Seller",
                    "Seller Accepted",
                    "Seller Rejected",
                    "Under Review by WDTP",
                    "Contract Sent",
                    "Contract Executed",
                    "Invoice Raised",
                    "Payment Received",
                    "Ownership Transferred",
                  ]}
                />}
              {/* <DropdownButton as={ButtonGroup} key={'up'} id={`dropdown-button-drop-${'up'}`}
                                drop={'start'} title={formatStatus(offerRequest?.status)} className="dropdown-status"
                                variant={getVariant(offerRequest?.status)}
                                onSelect={(eventKey) => handleStatusChange(eventKey as string)}
                                disabled={CommonService.isUser()}
                            >
                                    <Dropdown.Item eventKey="1" className="dropdown-item-accepted">
                                        <HowToRegIcon fontSize="small" sx={{ mr: 1 }} /> Accepted
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="2" className="dropdown-item-pendingReview">
                                        <PendingActionsIcon fontSize="small" sx={{ mr: 1 }} /> Pending Review
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="3" className="dropdown-item-rejected">
                                        <PersonOffIcon fontSize="small" sx={{ mr: 1 }} /> Rejected
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="4" className="dropdown-item-shipped">
                                        <LocalShippingOutlinedIcon fontSize="small" sx={{ mr: 1 }} /> Shipped
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="5" className="dropdown-item-cancelled">
                                        <HighlightOffIcon fontSize="small" sx={{ mr: 1 }} /> Cancelled
                                    </Dropdown.Item>
                                    <Dropdown.Item eventKey="6" className="dropdown-item-delivered">
                                        <NoCrashIcon fontSize="small" sx={{ mr: 1 }} /> Delivered
                                    </Dropdown.Item>
                            </DropdownButton> */}

              {/* <button onClick={handleMessageBoxClick} className="border-0 bg-transparent">
                <MarkunreadOutlinedIcon/>
                </button> */}

              {/* {CommonService.isAdmin() && (<button onClick={handleEditButtonClick} className="border-0 bg-transparent"> <BorderColorIcon/></button>)} */}

              

            </div>
          </div>
        </div>
        <div className="viewSampleRequest--wrapper d-flex flex-column flex-lg-row justify-content-between">
          <div className="flex-grow-1">
            <div className="view-sample-request mt-4 mb-3">
              <div className="row m-0">
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Inventory
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      {offerRequest?.inventoryName}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Quantity
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      {offerRequest?.quantity} barrels
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Price
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      <CurrencyFormatter amount={offerRequest?.pricePerBarrel} locale="en-US" currency="USD" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Total Price
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      <CurrencyFormatter amount={offerRequest?.totalAmount} locale="en-US" currency="USD" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Handling Fee
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc"><CurrencyFormatter amount={offerRequest?.handlingFees} locale="en-US" currency="USD" />
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-3">
                  <div className="sample-request-faq py-3 h-100">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Service Fee
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      <CurrencyFormatter amount={offerRequest?.serviceFees} locale="en-US" currency="USD" />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 d-flex">
                  <div className="row w-100">
                    <div className="col-sm-6">
                      <div className="sample-request-faq py-3">
                        <div className="faq-title position-relative pb-1 mb-3">
                          Seller
                          <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                        </div>
                        <div className="faq-desc">
                          {offerRequest?.typeSenseResponse?.is_relocation_required ? (
                            <>
                              <span className="d-flex my-1">
                                <LocalShippingOutlinedIcon
                                  sx={{ color: "#9E874A", mr: 1 }}
                                />
                                Relocation Required
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={ShippingCrossIcon}
                                  alt="AccountTransferCrossIcon"
                                  className="me-2"
                                  width={23}
                                  height={23}
                                />
                                Relocation not Required
                              </span>
                            </>
                          )}
                          {offerRequest?.typeSenseResponse?.is_account_transfer ? (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={AccountTransferIcon}
                                  alt="AccountTransferIcon"
                                  className="me-2"
                                  width={28}
                                  height={24}
                                />
                                Change of Account Required
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={AccountTransferCrossIcon}
                                  alt="AccountTransferCrossIcon"
                                  className="me-2"
                                  width={23}
                                  height={23}
                                />
                                Change of Account not Required
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="sample-request-faq py-3">
                        <div className="faq-title position-relative pb-1 mb-3">
                          Buyer
                          <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                        </div>
                        <div className="faq-desc">
                          {offerRequest?.isRelocationRequired ? (
                            <>
                              <span className="d-flex my-1">
                                <LocalShippingOutlinedIcon
                                  sx={{ color: "#9E874A", mr: 1 }}
                                />
                                Relocation Required
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={ShippingCrossIcon}
                                  alt="AccountTransferCrossIcon"
                                  className="me-2"
                                  width={23}
                                  height={23}
                                />
                                Relocation not Required
                              </span>
                            </>
                          )}
                          {offerRequest?.isAccountTransfer ? (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={AccountTransferIcon}
                                  alt="AccountTransferIcon"
                                  className="me-2"
                                  width={28}
                                  height={24}
                                />
                                Change of Account Required
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="d-flex my-1">
                                <img
                                  src={AccountTransferCrossIcon}
                                  alt="AccountTransferCrossIcon"
                                  className="me-2"
                                  width={23}
                                  height={23}
                                />
                                Change of Account not Required
                              </span>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-sm-6">
                            </div> */}
              </div>
            </div>
            <div className="row mt-5 mb-3 m-0">
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">List of Documents</h3>
                  <UploadDocs
                    fetchData={fetchData}
                    csvId={id}
                    uploadCSV={false}
                    requestType={"OFFER_REQUEST"}
                  />
                </div>
                <div
                  className="inventory-doc-table"
                  style={{ height: 350, width: "100%" }}
                >
                  <DataGrid
                    localeText={localeText}
                    rows={offerRequestDocs}
                    columns={columnsForDoc}
                    className="data-grid"
                    hideFooterPagination
                  />
                </div>
              </div>
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="recentActivity">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-2">Recent Activity</h3>
                  </div>
                  <div>
                  <HistoryStepper historyData={offerRequestHistoryData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="msgContainerBox">
            {isOpenEditSection && CommonService.isAdmin() && (<EditRequest requestType={"OFFER_REQUEST"} id={id}  //reload the date
      refreshData={fetchData} />)}
           {isMessageBoxOpen &&  (<ContainerMessageBox requestType={"OFFER_REQUEST"} />)}
          </div>
        </div>
      </div>

      {/* Status Change Model Pop-up */}
      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Offer Request Status</Modal.Title>
          <p className="modal-text">
            Are you sure, you want to change status to Rejected ?
          </p>
        </Modal.Header>

        <Modal.Body>
          <div className="form__inner">
            <textarea
              className={`form-control ${error ? "is_invalid" : ""}`}
              aria-label="With textarea"
              value={rejectionNoteValue}
              onChange={handleInputChange}
              rows={8}
              style={{ resize: "none" }}
              placeholder="Enter Rejection Note..."
            />
            {error && <div className="error-message">{error}</div>}
          </div>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button className="primary-btn w-100" onClick={changeOfferStatus} disabled={isLoading}>
                {isLoading && <Spinner />} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewOfferRequest;
