import React, { FC, useEffect, useState } from "react";
import loginBanner from "../../assets/images/login/LoginBanner.png"
import { FaEye, FaEyeSlash } from "react-icons/fa";
import Spinner from "../Common/Spinner";
import UATbelt from "../Common/UATbelt";
import { Service } from "../../Service/ApiService";
import { CommonService } from "../../Service/CommonService";
import { useHistory } from "react-router";



const EmailSender:  React.FC = () => {

    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [emailError, setEmailError] = useState("");
    const history = useHistory();


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {

      e.preventDefault(); // Prevent default form submission

      if(emailError || email.length === 0) {

        if(email.length === 0) {
          setEmailError("Please enter email");
        }
        return ;
      }

      console.log("this is my email "+ email);

      //if everything is okay then call the api which send the reset password link
      setIsLoading(true);

      try{
        const response = await Service.sendResetPasswordLink(email);
        setIsLoading(false);

        CommonService.Toast.fire({
        title: "Password reset link sent to your email.",
        icon: "success",
        });

        history.push("/login");


      } catch(error:any) {
          setIsLoading(false);
          console.log("error occurred while api calling");
          setEmailError("account doesn't exist with "+ email);
      }



    }

    const handleChange = (e:any) => {
        setEmailError("");
        const value = e.target.value;
        setEmail(value?.trim());
    }

    const handleBlur = () => {

      if(!email || email.length < 1) {
        setEmailError("Please enter email");
      }

      if(!validateEmail(email)) {
        setEmailError("Please enter valid email");
      }

    }

    const validateEmail = (email: string): boolean => {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    };

    return (
        <><UATbelt /><div className="login text-start">
        <div className="login__form mx-auto">
          <div className="row d-flex align-items-lg-center">
            <div className="col-lg-6">
              <div className="login__form--outer">
            
                <div className="row mb-3">
                  <div className="col-12">
                    <h1 className="login__heading">Forgot Password</h1>
                    <p className="login__text">Enter your registered email below, and we'll send you instructions to reset it.</p>
                  </div>
                </div>
                <form className="form__inner row d-flex" onSubmit={handleSubmit}>
                  <div className="col-12 mb-3">
                    <label htmlFor="loginEmail" className="form-label">
                      Email address <span className="star-required text-danger">*</span>
                    </label>
                    <input
                      type="email"
                      className={`form-control ${emailError ? "is_invalid" : ""}`}
                      placeholder="Enter your register email address"
                      value={email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="username"
                      id="loginEmail" />
                    {emailError && <div className="error-message">{emailError}</div>}
                  </div>
                 
                  
                 
                  <div className="col-12 mb-3">
                    <div className="form__btn--group row d-flex flex-wrap justify-content-center">
                      <div className="col-6">
                        <button
                          type="submit"
                          className="btn secondary-btn w-100 mb-2"
                          disabled={isLoading}
                        >
                          {isLoading && <Spinner />}
                          send
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-lg-6 d-none d-lg-block">
              <div className="login__form--banner d-flex justify-content-center">
                <img src={loginBanner} alt="logo" />
              </div>
            </div>
          </div>
        </div>
      </div></>
    )
}

export default EmailSender