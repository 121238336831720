import { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import { Icon } from "@mui/material";
import { BsCheckCircleFill } from "react-icons/bs";
import { useHistory, useLocation } from "react-router";

const RegisterSuccess: React.FC = () => {
    const history = useHistory();

    useEffect(() => {



    }, []);

    const routeToLogin = () => {
        history.push("/login");
    }   

    return (
        <>
            <div className="login register">
                <div className="login__form register__form mx-auto p-5">
                    {/* <div className="d-flex justify-content-center align-items-lg-center">
                        <div className="login__form--outer bg-none px-0 py-2">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <Logo size="150px" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="row mb-3">
                        <div className="col-12">
                            <h1 className='login__heading text-center'>
                                <BsCheckCircleFill style={{ 'color': 'green' }} />
                            </h1>
                            <p className='login__text text-center'>Your application has been successfully submitted for review.
                                If the application is approved, you will receive an email at xxxxxxxx@gmail.com with further instructions.</p>
                        </div>
                    </div>
                    <div className="form__btn--group d-flex flex-wrap justify-content-center">
                        <div className="col-auto">
                            <button type="submit" className="btn secondary-btn register-btn" onClick={routeToLogin}>Go to Login Page</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="div-ctrl">
                <Icon name="check-circle-fill" style="color:green;font-size:60px" size="6em" />
                <p>
                    Your application has been successfully submitted for review.
                    If the application is approved, you will receive an email at xxxxxxxx@gmail.com with further instructions.
                </p>
                <a href="/login">Back to Login</a>
            </div> */}
        </>
    );
}

export default RegisterSuccess