import React from "react";
import logo from '../../assets/images/logo.svg';
import brandlogo from '../../assets/images/logo_brand_name.svg';

interface LogoProps {
    size: string; // Size in pixels (e.g., '50px', '75px', '100px')
}

const Logo: React.FC<LogoProps> = ({ size }) => {
    const logoSizeStyle: React.CSSProperties = {
        width: size,
        height: 'auto',
    };
    
    return(
        <div className="logo__wrapper">
            {/* <img src={logo} alt="logo" style={logoSizeStyle} /> */}
            <img src={brandlogo} alt="logo" style={logoSizeStyle} />
        </div>
    );
};

export default Logo;