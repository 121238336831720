import React, { useEffect, useRef, useState } from "react";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
import "../Dynamic/UserList.scss";
import { useHistory, useParams } from "react-router";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { CookieService } from "../../Service/CookieService";
import { OrderApiService } from "../../Service/OrderApiService";
import { ButtonGroup } from "react-bootstrap";
import ContainerMessageBox from "./ContainerMessageBox";
import UploadDocs from "../popups/uploadDocs";
import "../Dynamic/InventoryCSV.scss";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Stepper, Step, StepLabel, StepContent, Paper } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Constant from "../../Constant";
import Modal from "react-bootstrap/Modal";
import { CommonService } from "../../Service/CommonService";
import StatusDropdown from "../Common/StatusDropdown";
import Spinner from "../Common/Spinner";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";

const ViewSampleRequest: React.FC = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const userRole = CookieService.getCookie("userRole");
  const [sampleRequest, setSampleRequest] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [sampleRequestDocs, setSampleRequestDocs] = useState<any[]>([]);
  const [historyData, setHistoryData] = useState<any[]>([]);
  const [isApproved, setIsApproved] = useState(false);
  const [rejectionNoteValue, setNote] = useState<string>("");
  const [trackNumberValue, setTrackNumber] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isOpenEditSection, setOpenEditSection] = useState(false);
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);


  const statusDetailsRef = useRef({
    id: "",
    inventoryId: "",
    sampleRequestStatus: "",
    trackNumber: "",
    rejectionNote: "",
  });
  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    setError("");
  };
  const [show, setShow] = useState(false);
  const rejectionNote = useRef("");
  const trackNumber = useRef("");
  const [isSpinner, setIsSpinner] = useState(false);

  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };
  const routeBack = () => {
    if (userRole == "ADMIN") history.push("/user/sample-request");
    else history.push("/master/sample-request");
  };

  const fetchData = async () => {
    try {
      fetchSampleRequestDetail();
      fetchSampleRequestDocs();
      fetchSampleRequestHistory();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSampleRequestDetail = async () => {
    const response: any = await OrderApiService.fetchSampleRequestById(id);
    setSampleRequest(response.data);
  };

  const fetchSampleRequestDocs = async () => {
    setIsLoading(true);
    const response: any = await OrderApiService.fetchSampleRequestDocs(id);
    setSampleRequestDocs(response.data);
    setIsLoading(false);
  };

  const fetchSampleRequestHistory = async () => {
    const response: any = await OrderApiService.fetchSampleRequestHistory(id);
    setHistoryData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatDateString = (dateString: any) => {
    if (dateString) {
      // Parse the input date string
      const date = new Date(dateString.replace(" ", "T"));

      // Define arrays for month names and add leading zero function
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const addLeadingZero = (num: any) => (num < 10 ? `0${num}` : num);

      // Extract date parts
      const year = date.getFullYear();
      const month = monthNames[date.getMonth()];
      const day = addLeadingZero(date.getDate());
      const hours = addLeadingZero(date.getHours());
      const minutes = addLeadingZero(date.getMinutes());
      const seconds = addLeadingZero(date.getSeconds());

      // Format the date string
      return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}`;
    } else return "";
  };

  //formate the status
  function formatStatus(status?: string): string {
    let formattedStatus = "";
    if (status !== undefined) {
      formattedStatus = status
        ?.split(/[_\\]/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }

    return formattedStatus;
  }

  // const getVariant = (status: any) => {
  //     switch (status) {
  //         case 'APPROVED':
  //             return 'warning';
  //         case 'PENDING_REVIEW':
  //             return 'primary';
  //         case 'REJECTED':
  //             return 'danger';
  //         default:
  //             return 'info';
  //     }
  // };

  const handleStatusChange = async (newStatus: string) => {
    // const statusMap: { [key: string]: string } = {
    //     "1": "Approved",
    //     "2": "Pending Review",
    //     "3": "Rejected",
    // };

    // const newStatus = statusMap[eventKey];
    console.log(newStatus);

    const sampleRequestStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: id,
      inventoryId: sampleRequest.inventoryId,
      sampleRequestStatus: sampleRequestStatus.toUpperCase(),
      trackNumber: "",
      rejectionNote: "",
    };

    statusDetailsRef.current = sd;
    if (
      statusDetailsRef.current.sampleRequestStatus ===
      Constant.SampleRequestStatus.REJECTED
    ) {
      setIsApproved(false);
      handleShow();
    } else if (
      statusDetailsRef.current.sampleRequestStatus ===
      Constant.SampleRequestStatus.ACCEPTED
    ) {
      setIsApproved(true);
      handleShow();
    } else {
      //if status is not ACCEPTED and REJECTED then call method don't open any popup
      changeSampleRequestStatus();
    }
  };

  const changeSampleRequestStatus = async () => {

    if (statusDetailsRef.current.sampleRequestStatus === Constant.SampleRequestStatus.REJECTED && rejectionNoteValue.trim().length === 0) {
      setError("Please enter rejection note");
      return;
    }

    if (statusDetailsRef.current.sampleRequestStatus === Constant.SampleRequestStatus.ACCEPTED && trackNumberValue.trim().length === 0) {
      setError("Please enter track number");
      return;
    }

    setIsSpinner(true);
    try {
      if (
        statusDetailsRef.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.REJECTED
      ) {
        statusDetailsRef.current.rejectionNote = rejectionNote.current;
      } else if (
        statusDetailsRef.current.sampleRequestStatus ===
        Constant.SampleRequestStatus.ACCEPTED
      ) {
        statusDetailsRef.current.trackNumber = trackNumber.current;
      }

      await OrderApiService.changeSampleRequestStatus(statusDetailsRef.current);
      setIsSpinner(false);
      CommonService.Toast.fire({
        title: "Sample Request updated successfully",
        icon: "success",
      });

      handleClose();
      fetchSampleRequestDetail();
    } catch (error) {
      console.log("error occurred which api calling");
    }
  };

 //handle edit button click
const handleEditButtonClick = () => {
  if(isOpenEditSection) {
    setOpenEditSection(false);
  } else {
    setMessageBoxOpen(false);
    setOpenEditSection(true);
  }
}
 

//handle message box click
const handleMessageBoxClick = () => {

  if(isMessageBoxOpen) {
    setMessageBoxOpen(false);
  }
  else {
  setOpenEditSection(false);
  setMessageBoxOpen(true);
  }
}
  

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setError("");
    if (
      statusDetailsRef.current.sampleRequestStatus ===
      Constant.SampleRequestStatus.REJECTED
    ) {
      setNote(event.target.value);
      rejectionNote.current = event.target.value;
    } else {
      setTrackNumber(event.target.value);
      trackNumber.current = event.target.value;
    }
  };

  const downloadSampleRequestDOC = async (item: any) => {
    const requestDto = {
      id: item.id,
      siteId: sampleRequest.siteId,
      companyId: sampleRequest.companyId,
      fileName: item.fileName,
    };

    try {
      const response: any = await OrderApiService.downloadSampleRequestDOC(
        requestDto
      );
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.fileName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div>
          <Tooltip title="Download document" arrow>
            <IconButton
              onClick={(event) => downloadSampleRequestDOC(params.row)}
            >
              <CloudDownloadOutlinedIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  return (
    <>
      <div className="usersList">
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
          {CommonService.isAdmin() && (
            <div className="back__button me-3" onClick={routeBack}>
              <IconButton
                aria-label="ArrowBackIcon"
                sx={{ backgroundColor: "#FFF", mb: 1 }}
              >
                <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
              </IconButton>
            </div>
          )}

          {CommonService.isAdmin() && (
            <div className="header--title mb-2 me-3">
              <h1 className="title mb-0">
                Sample Request
                <i className="bi bi-heart-fill"></i>
              </h1>
            </div>
          )}

          <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
            <div className="company__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Company name
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: "#333333",
                  fontSize: { xl: "18px", xs: "16px" },
                  fontWeight: 700,
                }}
              >
                {sampleRequest?.companyName}
              </Typography>
            </div>
            <div className="person__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted by
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {sampleRequest?.firstName}
              </Typography>
            </div>
            <div className="time__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted on
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {CommonService.formatDateTime(sampleRequest?.createdDate)}
              </Typography>
            </div>
          </div>

          <div className="header--menu ms-auto mb-xxl-0">
            <div className="header--menu-items">
             {CommonService.isAdmin() && sampleRequest?.sampleRequestStatus === "PENDING_REVIEW" && (<IconButton aria-label="edit" size="small" onClick={handleEditButtonClick} sx={{ borderRadius: "50%", backgroundColor: isOpenEditSection? "#97AEB8": "#FFF", color: isOpenEditSection? "#FFF": "#1C1B1F", marginInline: "8px", padding: "8px" }}>
                <BorderColorIcon />
              </IconButton>)}
              <IconButton aria-label="message" size="small" onClick={handleMessageBoxClick} sx={{ borderRadius: "50%", backgroundColor: isMessageBoxOpen? "#97AEB8": "#FFF", color: isMessageBoxOpen? "#FFF": "#1C1B1F", marginInline: "8px", padding: "8px" }}>
                <MarkunreadOutlinedIcon />
              </IconButton>
              {/* <button onClick={handleMessageBoxClick} className="border-0 bg-transparent">
                <MarkunreadOutlinedIcon />
              </button>
              <button onClick={handleEditButtonClick} className="border-0 bg-transparent">
                <BorderColorIcon />
              </button> */}

              {sampleRequest?.sampleRequestStatus === "PENDING_REVIEW" && CommonService.isUser() && (
                <StatusDropdown
                  currentStatus={sampleRequest?.sampleRequestStatus}
                  onStatusChange={(newStatus) =>
                    handleStatusChange(
                      newStatus,
                    )
                  }
                  disableCondition={false}
                  allowedLabels={["Cancelled"]}
                />
              )}

              {((sampleRequest?.sampleRequestStatus !== "PENDING_REVIEW" && CommonService.isUser()) || CommonService.isAdmin()) &&
                <StatusDropdown
                  currentStatus={sampleRequest?.sampleRequestStatus}
                  onStatusChange={(newStatus) =>
                    handleStatusChange(
                      newStatus,
                    )
                  }
                  disableCondition={CommonService.isUser() || sampleRequest?.sampleRequestStatus === "CANCELLED"}
                  allowedLabels={["Rejected", "Accepted", "Under Review", "Invoice Sent", "Payment Received", "In Transit", "Delivered"]}
                />}
              {/* <DropdownButton as={ButtonGroup} key={'up'} id={`dropdown-button-drop-${'up'}`}
                                drop={'start'} title={formatStatus(sampleRequest?.sampleRequestStatus)} className="dropdown-status"
                                variant={getVariant(sampleRequest?.sampleRequestStatus)}
                                onSelect={(eventKey) => handleStatusChange(eventKey as string)}
                                disabled={userRole === "USER"}
                            >
                                {sampleRequest?.sampleRequestStatus !== 'APPROVED' && (
                                    <Dropdown.Item eventKey="1" className="dropdown-item-accepted"><HowToRegIcon fontSize="small" sx={{ mr: 1 }} /> Approved</Dropdown.Item>
                                )}
                                {(sampleRequest?.sampleRequestStatus !== 'PENDING_REVIEW' && sampleRequest?.sampleRequestStatus !== 'APPROVED' && sampleRequest?.sampleRequestStatus !== 'REJECTED') && (
                                    <Dropdown.Item eventKey="2" className="dropdown-item-pendingReview"><PendingActionsIcon fontSize="small" sx={{ mr: 1 }} /> Pending Review</Dropdown.Item>
                                )}
                                {sampleRequest?.sampleRequestStatus !== 'REJECTED' && (
                                    <Dropdown.Item eventKey="3" className="dropdown-item-rejected"><PersonOffIcon fontSize="small" sx={{ mr: 1 }} /> Rejected</Dropdown.Item>
                                )}
                            </DropdownButton> */}



              {/* {sampleRequest?.sampleRequestStatus == 'APPROVED' && (
                                <StatusDropdown
                                    currentStatus={sampleRequest?.sampleRequestStatus}
                                    onStatusChange={(newStatus) =>
                                        handleStatusChange(
                                            newStatus
                                        )
                                    }
                                    disableCondition={CommonService.isUser()}
                                    allowedLabels={["Rejected"]}
                                />
                            )} */}

              {/* {sampleRequest?.sampleRequestStatus == 'REJECTED' && (
                                <StatusDropdown
                                    currentStatus={sampleRequest?.sampleRequestStatus}
                                    onStatusChange={(newStatus) =>
                                        handleStatusChange(
                                            newStatus
                                        )
                                    }
                                    disableCondition={CommonService.isUser()}
                                    allowedLabels={["Approved"]}
                                />
                            )} */}
            </div>
          </div>
        </div>
        <div className="viewSampleRequest--wrapper d-flex flex-column flex-lg-row justify-content-between">
          <div className="flex-grow-1">
            <div className="view-sample-request mt-4 mb-3">
              <div className="row m-0">
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Purpose
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">{sampleRequest?.purpose}</div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Is Multiple barrels from same lot?
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      {sampleRequest?.multipleBarrels ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Is Multiple lots needed?
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">
                      {sampleRequest?.multiplelots ? "Yes" : "No"}
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Size Needed
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">{sampleRequest?.size}</div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Qty Requested of Size
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">{sampleRequest?.quantity}</div>
                  </div>
                </div>
                <div className="col-sm-6 col-lg-4">
                  <div className="sample-request-faq py-3">
                    <div className="faq-title position-relative pb-1 mb-3">
                      Request Notes
                      <div className="ul-title d-inline-block position-absolute top-100 start-0"></div>
                    </div>
                    <div className="faq-desc">{sampleRequest?.comment}</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-5 mb-3 m-0">
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">List of Documents</h3>
                  <UploadDocs
                    fetchData={fetchData}
                    csvId={id}
                    uploadCSV={false}
                    requestType={"SAMPLE_REQUEST"}
                  />
                </div>
                <div
                  className="inventory-doc-table"
                  style={{ height: 350, width: "100%" }}
                >
                  <DataGrid
                    localeText={localeText}
                    rows={sampleRequestDocs}
                    columns={columnsForDoc}
                    className="data-grid"
                    hideFooterPagination
                  />
                </div>
              </div>
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="recentActivity">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-2">Recent Activity</h3>
                  </div>
                  <div>
                  <HistoryStepper historyData={historyData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="msgContainerBox">
            {isOpenEditSection && (<EditRequest requestType={"SAMPLE_REQUEST"} id={id} refreshData={fetchData} />)}
            {isMessageBoxOpen && (<ContainerMessageBox requestType={"SAMPLE_REQUEST"} />)}
          </div>
        </div>
      </div>

      {/* Status Change Model Pop-up */}
      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Sample Request Status</Modal.Title>
          {isApproved && (
            <p className="modal-text">
              Are you sure, you want to change status to Approved?
            </p>
          )}
          {!isApproved && (
            <p className="modal-text">
              Are you sure, you want to change status to Rejected?
            </p>
          )}
        </Modal.Header>

        <Modal.Body >
          <div className="form__inner">
            {isApproved && (

              <Form.Control
                className={`form-control ${error ? "is_invalid" : ""}`}
                type="text"
                value={trackNumberValue}
                onChange={handleInputChange}
                placeholder="Enter Tracking Number..."
              />
            )}
            {isApproved && error && <div className="error-message"> {error}</div>}

            {!isApproved && (
              <textarea
                className={`form-control ${error ? "is_invalid" : ""}`}
                aria-label="With textarea"
                value={rejectionNoteValue}
                onChange={handleInputChange}
                rows={8}
                style={{ resize: "none" }}
                placeholder="Enter Rejection Note..."
              />
            )}
            {!isApproved && error && <div className="error-message"> {error}</div>}
          </div>

        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button
                className="secondary-btn w-100"
                onClick={handleClose}
                disabled={isSpinner}
              >
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button
                className="primary-btn w-100"
                onClick={changeSampleRequestStatus}
                disabled={isSpinner}
              >
                {isSpinner && <Spinner />} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ViewSampleRequest;
