import React, { useState } from 'react';
import { Form } from 'react-bootstrap';

const PasswordStrength = ({ password }) => {
  // Function to calculate password strength
  const calculateStrength = (password) => {
    if (password.length >= 12) {
      return 'strong';
    } else if (password.length >= 8) {
      return 'good';
    } else if (password.length >= 4) {
      return 'medium';
    } else {
      return 'weak';
    }
  };

  // Determine strength level
  const strength = calculateStrength(password);

  // Determine colors based on strength
  let box1Color = 'secondary-subtle';
  let box2Color = 'secondary-subtle';
  let box3Color = 'secondary-subtle';
  let box4Color = 'secondary-subtle';
  let strengthText = 'Weak';
  let strengthTextColor = 'text-danger';

  if (strength === 'weak') {
    box1Color = 'danger';
  } else if (strength === 'medium') {
    box1Color = 'warning';
    box2Color = 'warning';
    strengthText = 'Medium';
    strengthTextColor = 'text-warning';
  } else if (strength === 'good') {
    box1Color = 'primary';
    box2Color = 'primary';
    box3Color = 'primary';
    strengthText = 'Good';
    strengthTextColor = 'text-primary';
  } else if (strength === 'strong') {
    box1Color = 'success';
    box2Color = 'success';
    box3Color = 'success';
    box4Color = 'success';
    strengthText = 'Strong';
    strengthTextColor = 'text-success';
  }

  return (
    <>
      <div className="row d-flex justify-content-between mt-2 mb-1">
        <div className="col-3"><div className={`password-strength-box bg-${box1Color}`} /></div>
        <div className="col-3"><div className={`password-strength-box bg-${box2Color}`} /></div>
        <div className="col-3"><div className={`password-strength-box bg-${box3Color}`} /></div>
        <div className="col-3"><div className={`password-strength-box bg-${box4Color}`} /></div>
      </div>
      <p className={`${strengthTextColor} text-end fw-bold mt-0 mb-0`}><small>{strengthText}</small></p>
    </>
  );
};

export default PasswordStrength;
