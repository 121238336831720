import React, { useEffect, useRef, useState } from "react";
import { Service } from "../../Service/ApiService";
import "../Dynamic/Dashboard.scss";
import CompanyIcn from "../../assets/images/icons/Company.svg";
import InventoryIcn from "../../assets/images/icons/Inventory.svg";
import UsersIcn from "../../assets/images/icons/Users.svg";
import BarrelIcn from "../../assets/images/icons/Barrels.svg";
import ReservedBarrelIcn from "../../assets/images/icons/Reserved_Barrels.svg";
import OrderReqIcn from "../../assets/images/icons/Order_Request.svg";
import OrderValueIcn from "../../assets/images/icons/Order_Value.svg";
import OfferReqIcn from "../../assets/images/icons/Offer_Request.svg";
import SampleReqIcn from "../../assets/images/icons/Sample_Request.svg";
import SellReqIcn from "../../assets/images/icons/Sell_Request.svg";
import CompanyStoreIcon from "../../assets/images/icons/CompanyStoreIcon.svg";
import ApproveCart from "../../assets/images/icons/ApproveCart.svg";
import RejectCart from "../../assets/images/icons/RejectCart.svg";
import Info from "../../assets/images/icons/Info.svg";
import User from "../../assets/images/icons/User.svg";
import DeviceMobileCamera from "../../assets/images/icons/DeviceMobileCamera.svg";
import EnvelopeSimpleOpen from "../../assets/images/icons/EnvelopeSimpleOpen.svg";
import MapPin from "../../assets/images/icons/MapPin.svg";
import CalendarCheck from "../../assets/images/icons/CalendarCheck.svg";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import Cookies from "universal-cookie";
import { Button, Dropdown } from "react-bootstrap";
import { useParams } from "react-router";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import RecentRequest from "./RecentRequest";
import ListGroup from "react-bootstrap/ListGroup";
import UnreadMessage from "./UnreadMessage";
import { format } from "date-fns";
import { IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useRoute } from "../Common/route";
import { DisplayGrid } from "./DisplayGrid";
import { OrderApiService } from "../../Service/OrderApiService";
import { InventoryApiService } from "../../Service/InventoryApiService";
import { CommonService } from "../../Service/CommonService";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { GridColDef } from "@mui/x-data-grid";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import StatusDropdown from "../Common/StatusDropdown";

const WarehouseCompanyDashboard: React.FC = () => {
  const route = useRoute();
  const [openRequestCount, setOpenRequestCount] = useState<any>({});
  const { id } = useParams<{ id: string }>();
  const userName = new Cookies().get("userName");
  const [companyDetails, setCompanyDetails] = useState<any>({});
  const [formattedDate, setFormattedDate] = useState<string>("");
  const [tableData, setTableData] = useState<any[]>([]);
  const [title, setTitle] = useState<any>();
  const [isLoading, setIsLoading] = useState(false);
  let columns: GridColDef[] = [];
  const [activeTab, setActiveTab] = useState("");
  const displayGridRef = useRef<HTMLDivElement>(null);
  const params = id === undefined ? CommonService.getCompanyId() : id;

  const handleScrollToGrid = () => {
    if (displayGridRef.current) {
      displayGridRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  const fetchCompanysOpenRequestCountByCompanyId = async () => {
    const response: any =
      await Service.fetchCompanysOpenRequestCountByCompanyId(params);
    setOpenRequestCount(response.data);
  };

  const fetchCompanyById = async () => {
    const response: any = await Service.fetchCompanyById(params);
    setCompanyDetails(response.data);
    setFormattedDate(
      format(new Date(response.data.createdDate), "MMMM dd, yyyy")
    );
  };

  useEffect(() => {
    fetchCompanysOpenRequestCountByCompanyId();
    fetchCompanyById();
    loadData("order");
  }, []);

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const loadData = async (value: any) => {
    try {
      columns = [];
      if (value === "user") users();
      else if (value === "order") openOrderRequests();
      else if (value === "sell") openSellRequests();
      else if (value === "sample") openSampleRequests();
      else if (value === "offer") openOfferRequests();
      else if (value === "inventory_change") openInventoryChangeRequests();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const users = async () => {
    try {
      setIsLoading(true);
      const response: any = await Service.fetchCompanyUserData(params);

      setTableData(Object.values(response.data)); // Convert object to array
      setTitle("Users");
      setActiveTab("user");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openOrderRequests = async () => {
    try {
      setIsLoading(true);
      const response: any = await OrderApiService.getCompanyWiseOrderRequests(
        params
        , true); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data)); // Convert object to array
      setTitle("Open_order_requests");
      setActiveTab("order");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openSellRequests = async () => {
    try {
      setIsLoading(true);
      const response: any =
        await InventoryApiService.getInventorySellRequestsByCompanyId(params);

      setTableData(Object.values(response.data)); // Convert object to array
      setTitle("Open_sell_requests");
      setActiveTab("sell");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openSampleRequests = async () => {
    try {
      setIsLoading(true); // Set the loading flag to true before the API call
      const response = await OrderApiService.getCompanyWiseSampleRequests(
        params, true); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data)); // Convert object to array
      setTitle("Open_sample_requests");
      setActiveTab("sample");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openOfferRequests = async () => {
    try {
      setIsLoading(true);
      const response = await OrderApiService.getCompanyWiseOfferRequests(
        params, true); // Applies the status filter only if isFetchOpenRequests is true;

      setTableData(Object.values(response.data)); // Convert object to array
      setTitle("Open_offer_requests");
      setActiveTab("offer");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const openInventoryChangeRequests = async () => {
    try {
      setIsLoading(true);
      const response: any = await InventoryApiService.getInventoryChangeRequests(params, true);

      setTableData(Object.values(response.data)); // Convert object to array
      setTitle("Open_inventory_change_requests");
      setActiveTab("inventory_change");
      setIsLoading(false);
      handleScrollToGrid();
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  }

  const handleRoute = (routePath: string) => {
    if (CommonService.isAdmin()) {
      route(`/master/my-warehouse/${routePath}/` + params);
    } else {
      route(`/user/my-warehouse/${routePath}`);
    }
  };

  switch (title) {
    case "Users":
      columns.push(
        {
          field: "user",
          headerName: "User",
          flex: 1,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "phoneNumber",
          headerName: "Phone",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "companyName",
          headerName: "Company Name",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params) => (
            <CellWithTooltip value={params?.row?.ecommCompany?.companyName} />
          ),
        },
        {
          field: "location",
          headerName: "Location",
          flex: 1,
          valueGetter: (value: any, row: any) => {
            const city = row?.ecommCompany?.city;
            const state = row?.ecommCompany?.state;
            const zipcode = row?.ecommCompany?.zipcode;
            const cityWithComma = city ? city + ", " : "";
            const stateWithSpace = state ? state + " " : "";
            return `${cityWithComma}${stateWithSpace}${zipcode || ""}`;
          },
          renderCell: (params) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        }
      );
      break;

    case "Open_order_requests":
      columns.push(
        {
          field: "orderNo",
          headerName: "Order Number",
          flex: 1,
          headerClassName: "table-header",
          renderCell: (params: any) => {
            const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

            return (
              <a href="#">
                <Link
                  to={`${linkPrefix}/order-detail/${params.row.id}`}
                  className="link"
                >
                  <CellWithTooltip value={params.row.orderNo} />
                </Link>
              </a>
            );
          },
        },
        {
          field: "createdDate",
          headerName: "Created At",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;

            if (dateTimeString) {
              //call the method which formate the time
              return CommonService.formatDateTime(dateTimeString);
            }
          },
        },
        {
          field: "quantity",
          headerName: "Total Quantity",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) =>
            `${row.quantity || ""} barrels`,
        },
        {
          field: "finalAmount",
          headerName: "Total Amount",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "user",
          headerName: "Created By",
          flex: 1,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          valueGetter: (value: any, row: any) => `${row.email || ""} `,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => {}}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );
      break;

    case "Open_sell_requests":
      columns.push(
        {
          field: "ecommUser.firstName",
          headerName: "Submitted By",
          flex: 1,
          valueGetter: (value: any, row: any) =>
            `${row.ecommUser.firstName || ""} ${row.ecommUser.lastName || ""}`,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },
        {
          field: "ecommUser.email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          valueGetter: (value: any, row: any) =>
            `${row.ecommUser.email || ""} `,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "ecommUser.companyName",
          headerName: "Company Name",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) =>
            `${row.ecommUser.companyName || ""} `,
        },
        {
          field: "fileName",
          headerName: "Inventory File",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params) => {
            const csvFileName = params.value;
            if (csvFileName) {
              const parts = csvFileName.split("_");
              const fileNameToShow = parts.slice(1).join("_");
              const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

              return (
                <a href="#">
                  <Link
                    to={`${linkPrefix}/view-sell-request/${params.row.id}`}
                    className="link"
                  >
                    <CellWithTooltip value={fileNameToShow} />
                  </Link>
                </a>
              );
            } else {
              return "";
            }
          },
        },
        {
          field: "createdDate",
          headerName: "Submitted On",
          flex: 1,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;
            if (dateTimeString) {
              return CommonService.formatDateTime(dateTimeString);
            }
          },
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          headerClassName: "table-header",
        },
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => (
            <div>
              {/* <Tooltip title={params.row.rejectionNote} arrow> */}
              {/* <Button
                                style={{
                                    background:
                                        params.row.status === "APPROVED"
                                            ? "green"
                                            : params.row.status === "REJECTED"
                                                ? "red"
                                                : params.row.status === "PENDING_REVIEW"
                                                    ? "#e8eaeb"
                                                    : params.row.status === "FINALIZED"
                                                        ? "#50b1ba"
                                                        : params.row.status === "PUBLISHED"
                                                            ? "#50b1ba"
                                                            : "",
                                    color:
                                        params.row.status === "PENDING_REVIEW" ? "#000000DE" : "white",
                                    border: "1px transparent",
                                    padding: "revert",
                                }}
                                size="sm"
                            >
                                {toTitleCase(params.row.status)}
                            </Button> */}
              {/* </Tooltip> */}
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => {}}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );

      break;

    case "Open_sample_requests":
      columns.push(
        {
          field: "companyName",
          headerName: "Company Name",
          flex: 1,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "requestedBy",
          headerName: "Requested By",
          flex: 1,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "requestedOn",
          headerName: "Requested On",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;

            if (dateTimeString) {
              //call the method which formate the time
              return CommonService.formatDateTime(dateTimeString);
            }
          },
        },
        {
          field: "inventoryName",
          headerName: "Inventory",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => {
            if (params.value) {
              const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

              return (
                <a href="#">
                  <Link
                    to={`${linkPrefix}/view-sample-request/${params.row.id}`}
                    className="link"
                  >
                    <CellWithTooltip value={params.value} />
                  </Link>
                </a>
              );
            } else {
              return "";
            }
          },
        },
        {
          field: "size",
          headerName: "Sample Size",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "quantity",
          headerName: "Quantity",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-IN"
              currency="INR"
            />
          ),
        },
        {
          field: "purpose",
          headerName: "Purpose",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => {}}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );
      break;

    case "Open_offer_requests":
      columns.push(
        {
          field: "user",
          headerName: "User",
          flex: 1,
          valueGetter: (value: any, row: any) =>
            `${row.firstName || ""} ${row.lastName || ""}`,
          headerClassName: "table-header",
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "email",
          headerName: "Email",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
        },
        {
          field: "createdDate",
          headerName: "Request Date",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          valueGetter: (value: any, row: any) => {
            const dateTimeString = row.createdDate ? row.createdDate : null;

            if (dateTimeString) {
              //call the method which formate the time
              return CommonService.formatDateTime(dateTimeString);
            }
          },
        },
        {
          field: "inventoryName",
          headerName: "Inventory",
          headerClassName: "table-header",
          flex: 1,
          //renderCell: (params: any) => <CellWithTooltip value={params.value} />,
          renderCell: (params) => {
            if (params.value) {
              const linkPrefix = CommonService.isAdmin() ? "/master" : "/user";

              return (
                <a href="#">
                  <Link
                    to={`${linkPrefix}/view-offer-request/${params.row.id}`}
                    className="link"
                  >
                    <CellWithTooltip value={params.value} />
                  </Link>
                </a>
              );
            } else {
              return "";
            }
          },
        },
        {
          field: "quantity",
          headerName: "Quantity",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-IN"
              currency="INR"
            />
          ),
        },
        {
          field: "pricePerBarrel",
          headerName: "Offer Price",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "totalAmount",
          headerName: "Total Price",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "status",
          headerName: "Status",
          headerClassName: "table-header",
          flex: 1,
          renderCell: (params: any) => (
            <div>
              <StatusDropdown
                currentStatus={toTitleCase(params.row.status)}
                onStatusChange={(newStatus) => {}}
                disableCondition={true}
                allowedLabels={[]}
              />
              {params.row.status === "REJECTED" && (
                <Tooltip
                  title={`Rejection Note: ${params.row.rejectionNote}`}
                  arrow
                >
                  <StickyNote2Icon
                    className="note-icon cursor-pointer"
                    style={{ color: "gray" }}
                  />
                </Tooltip>
              )}
            </div>
          ),
        }
      );
      break;

    case "Open_inventory_change_requests":
      columns.push(
        {
          field: "title",
          headerName: "Inventory",
          flex: 1,
          renderCell: (params) => {
            const productName = params.value;
            if (productName) {
    
              const rolePrefix = CommonService.isAdmin() ? 'master' : 'user';
              // const linkPrefix = currentPath === '/user/inventory-change-requests' || currentPath === '/master/inventory-change-requests'
              //  ? 'compare-inventory' : 'edit-inventory';
    
              return (
                <a href="#">
                  <Link
                    to={`/${rolePrefix}/compare-inventory/${params.row.id}`}
                    className="link"
                  >
                    {productName}
                  </Link>
                </a>
              );
            } else {
              return "";
            }
          },
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "facility",
          headerName: "Facility",
          // flex: 1
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "age",
          headerName: "Age",
          flex: 0.5,
          valueGetter: (value: any, row: any) =>
            `${row.spiritAgeYear || ""}years ${row.spiritAgeMonth || ""}months`,
          headerClassName: "table-header",
        },
        {
          field: "initialQuantity",
          headerName: "Initial Quantity",
          // // flex: 1
          //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
          headerClassName: "table-header",
        },
        {
          field: "quantity",
          headerName: "Quantity for Sales",
          // // flex: 1
          valueGetter: (value: any, row: any) => row.isAvailableForSell ? row.quantity : 0,
          headerClassName: "table-header",
        },
        {
          field: "isNotAvailableForSell",
          headerName: "Quantity not for Sale",
          headerClassName: "table-header",
          cellClassName: "info-dropdown",
        },
        {
          field: "lotNo",
          headerName: "Lot ID",
          headerClassName: "table-header",
        },
        {
          field: "price",
          headerName: "Price",
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "platformFee",
          headerName: "Fees",
          headerClassName: "table-header",
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.value}
              locale="en-US"
              currency="USD"
            />
          ),
        },
        {
          field: "total",
          headerName: "Total",
          // flex: 1
          renderCell: (params: any) => (
            <CurrencyFormatter
              amount={params.row.price * params.row.platformFee}
              locale="en-US"
              currency="USD"
            />
          ),
        }
      );

      break;
    
    default:
      // Default case if no other cases match
      break;
  }

  return (
    <div className="dashboard company-dashboard">
      <div className="container-fluid pt-1 pb-3">
        <div className="dashboard__user d-flex">
          {CommonService.isAdmin() && (
            <div
              className="back__button my-3 mx-3"
              onClick={() => route("/master/company-list")}
            >
              <IconButton
                aria-label="ArrowBackIcon"
                sx={{ backgroundColor: "#FFF", mb: 1 }}
              >
                <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
              </IconButton>
            </div>
          )}
          <h4 className="dashboard__user--title my-3 px-2 lh-base">
            {companyDetails["companyName"]}
          </h4>
          <div className="dashboard__user--companyInfo my-3 px-2">
            <Dropdown>
              <Dropdown.Toggle variant="info">
                <img src={Info} alt="Info" />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <ListGroup>
                  <ListGroup.Item>
                    <div className="company-details d-flex align-items-center">
                      <img src={CompanyStoreIcon} alt="CompanyStoreIcon" />
                      <span className="company-name ms-2">
                        {companyDetails["companyName"]}
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="icon">
                      <img src={User} alt="User" />
                    </div>
                    <div className="info d-flex flex-column mx-3">
                      <span className="sub-title">Contact Person</span>
                      <span className="detail">Anderson John</span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="icon">
                      {/* <SmartphoneOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                      <img src={DeviceMobileCamera} alt="DeviceMobileCamera" />
                    </div>
                    <div className="info d-flex flex-column mx-3">
                      <span className="sub-title">Contact Number</span>
                      <span className="detail">+11 123 456 7890</span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="icon">
                      {/* <EmailOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                      <img src={EnvelopeSimpleOpen} alt="EnvelopeSimpleOpen" />
                    </div>
                    <div className="info d-flex flex-column mx-3">
                      <span className="sub-title">Email</span>
                      <span className="detail">anderson@email.com</span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="icon">
                      {/* <LocationOnOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                      <img src={MapPin} alt="MapPin" />
                    </div>
                    <div className="info d-flex flex-column mx-3">
                      <span className="sub-title">Location</span>
                      <span className="detail">
                        {companyDetails["city"]}, {companyDetails["state"]}{" "}
                        {companyDetails["zipcode"]}
                      </span>
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="icon">
                      {/* <EventAvailableOutlinedIcon sx={{ fontSize: '40px', color: '#8F9195' }} /> */}
                      <img src={CalendarCheck} alt="CalendarCheck" />
                    </div>
                    <div className="info d-flex flex-column mx-3">
                      <span className="sub-title">Joining Date</span>
                      <span className="detail">{formattedDate}</span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
        <div className="dashboard__inner">
          <div className="row">
            <div className="col-xl-7">
              <div className="dashboard__data mb-5 mb-xl-0">
                <div className="dashboard__data--card card-span">
                  <div className="row h-100">
                    <div className="col-6 border-end">
                      <div
                        className="d-flex flex-column position-relative cursor-pointer"
                        onClick={() => handleRoute("my-inventory")}
                      >
                        <img
                          src={InventoryIcn}
                          alt="CompanyIcn"
                          width={68}
                          height={60}
                        />
                        <ExpandCircleDownOutlinedIcon />
                        <span className="count">
                          {openRequestCount["inventories"]
                            ? CommonService.numberFormatter(
                                openRequestCount["inventories"]
                              )
                            : 0}
                        </span>
                        <span className="details">Total Inventories</span>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="d-flex flex-column position-relative ps-1">
                        <ExpandCircleDownOutlinedIcon className="end-0" />
                        <span className="count">
                          <img
                            src={ApproveCart}
                            alt="ApproveCart"
                            width={34}
                            className="me-3"
                          />
                          {openRequestCount["avilableForSales"]
                            ? CommonService.numberFormatter(
                                openRequestCount["avilableForSales"]
                              )
                            : 0}
                        </span>
                        <span className="details mt-1">
                          Available for sales
                        </span>
                      </div>
                      <div className="d-flex flex-column position-relative ps-1">
                        <ExpandCircleDownOutlinedIcon className="end-0" />
                        <span className="count">
                          <img
                            src={RejectCart}
                            alt="RejectCart"
                            width={34}
                            className="me-3"
                          />
                          {openRequestCount["notAvilableForSales"]
                            ? CommonService.numberFormatter(
                                openRequestCount["notAvilableForSales"]
                              )
                            : 0}
                        </span>
                        <span className="details mt-1">
                          Not Available for sales
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="dashboard__data--card">
                                    <div className='d-flex flex-column position-relative'>
                                        <ExpandCircleDownOutlinedIcon className='end-0' />
                                        <span className="count"><img src={ApproveCart} alt="ApproveCart" width={34} className='me-3' />66</span>
                                        <span className="details mt-1">Avilable for sales</span>
                                    </div>
                                    <div className='d-flex flex-column position-relative'>
                                        <ExpandCircleDownOutlinedIcon className='end-0' />
                                        <span className="count"><img src={RejectCart} alt="RejectCart" width={34} className='me-3' />0</span>
                                        <span className="details mt-1">Not Avilable for sales</span>
                                    </div>
                                </div> */}
                <div
                  className={`dashboard__data--card cursor-pointer ${
                    activeTab === "user" ? "active" : ""
                  }`}
                  onClick={() => loadData("user")}
                >
                  <img src={UsersIcn} alt="CompanyIcn" width={67} height={65} />
                  <ExpandCircleDownOutlinedIcon
                      onClick={() => {
                        const basePath = CommonService.isAdmin() ? "/master" : "/user";
                        route(`${basePath}/company/user-list/${params}`);
                    }}
                  />
                  <span className="count">{openRequestCount["users"]}</span>
                  <span className="details">Users</span>
                </div>
                <div className="dashboard__data--card">
                  <img
                    src={BarrelIcn}
                    alt="CompanyIcn"
                    width={51}
                    height={52}
                  />
                  <ExpandCircleDownOutlinedIcon />
                  <span className="count">
                    {openRequestCount["avilableBarrels"]
                      ? CommonService.numberFormatter(
                          openRequestCount["avilableBarrels"]
                        )
                      : 0}
                  </span>
                  <span className="details">Total Available Barrels</span>
                </div>
                <div className="dashboard__data--card">
                  <img
                    src={ReservedBarrelIcn}
                    alt="CompanyIcn"
                    width={51}
                    height={52}
                  />
                  <ExpandCircleDownOutlinedIcon />
                  <span className="count">
                    {openRequestCount["reservedBarrels"]}
                  </span>
                  <span className="details">Total Reserved Barrels</span>
                </div>
                <div
                  className={`dashboard__data--card cursor-pointer ${
                    activeTab === "order" ? "active" : ""
                  }`}
                  onClick={() => loadData("order")}
                >
                  <img
                    src={OrderReqIcn}
                    alt="CompanyIcn"
                    width={70}
                    height={65}
                  />
                  <ExpandCircleDownOutlinedIcon
                    onClick={() => handleRoute("active-order")}
                  />
                  <span className="count">
                    {openRequestCount["orderRequestCount"]}
                  </span>
                  <span className="details">Open Order Requests</span>
                </div>
                <div className="dashboard__data--card">
                  <img
                    src={OrderValueIcn}
                    alt="CompanyIcn"
                    width={61}
                    height={60}
                  />
                  <ExpandCircleDownOutlinedIcon />
                  <span className="count">
                    <CurrencyFormatter
                      amount={openRequestCount["pendingOrderValue"]}
                      locale="en-US"
                      currency="USD"
                    />
                  </span>
                  <span className="details">Pending Order Value</span>
                </div>
                <div
                  className={`dashboard__data--card cursor-pointer ${
                    activeTab === "offer" ? "active" : ""
                  }`}
                  onClick={() => loadData("offer")}
                >
                  <img
                    src={OfferReqIcn}
                    alt="CompanyIcn"
                    width={61}
                    height={60}
                  />
                  <ExpandCircleDownOutlinedIcon
                    onClick={() => handleRoute("offer-request")}
                  />
                  <span className="count">
                    {openRequestCount["offerRequestCount"]}
                  </span>
                  <span className="details">Open Offer Requests</span>
                </div>
                <div
                  className={`dashboard__data--card cursor-pointer ${
                    activeTab === "sample" ? "active" : ""
                  }`}
                  onClick={() => loadData("sample")}
                >
                  <img
                    src={SampleReqIcn}
                    alt="CompanyIcn"
                    width={61}
                    height={60}
                  />
                  <ExpandCircleDownOutlinedIcon
                    onClick={() => handleRoute("sample-request")}
                  />
                  <span className="count">
                    {openRequestCount["sampleRequestCount"]}
                  </span>
                  <span className="details">Open Sample Requests</span>
                </div>
                <div
                  className={`dashboard__data--card cursor-pointer ${
                    activeTab === "sell" ? "active" : ""
                  }`}
                  onClick={() => loadData("sell")}
                >
                  <img
                    src={SellReqIcn}
                    alt="CompanyIcn"
                    width={65}
                    height={60}
                  />
                  <ExpandCircleDownOutlinedIcon
                    onClick={() =>
                      route(
                        CommonService.isAdmin()
                          ? "/master/inventory-sell-requests"
                          : "/user/inventory-sell-requests"
                      )
                    }
                  />
                  <span className="count">
                    {openRequestCount["sellRequestCount"]}
                  </span>
                  <span className="details">Open Sell Requests</span>
                </div>
                <div
                  className={`dashboard__data--card cursor-pointer ${
                    activeTab === "inventory_change" ? "active" : ""
                  }`}
                  onClick={() => loadData("inventory_change")}
                >
                  <img
                    src={SellReqIcn}
                    alt="CompanyIcn"
                    width={65}
                    height={60}
                  />
                  <ExpandCircleDownOutlinedIcon
                    onClick={() =>
                      route(
                        CommonService.isAdmin()
                          ? "/master/inventory-change-requests"
                          : "/user/inventory-change-requests"
                      )
                    }
                  />
                  <span className="count">
                    {openRequestCount["inventoryChangeRequestCount"]}
                  </span>
                  <span className="details">Open Inventory Change Requests</span>
                </div>
              </div>
            </div>
            <UnreadMessage />
          </div>
          <div className="row">
            <div className="col-xl-7" ref={displayGridRef}>
              <DisplayGrid
                tableData={tableData}
                columns={columns}
                localeText={localeText}
                title={title}
              />
            </div>
            <div className="col-xl-5">
              <RecentRequest />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseCompanyDashboard;
