import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SendOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Service } from '../../Service/ApiService';
import { CommonService } from '../../Service/CommonService';
import Tooltip from '@mui/material/Tooltip';
import Spinner from '../Common/Spinner';

interface ChildProps {
    reInvitedUser: any;
}

const SingleReInvite: React.FC<ChildProps> = ({ reInvitedUser  }) => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const sendInvite = async () => {
        setIsLoading(true);
        try {
            const response = await Service.sendInvite([reInvitedUser], true);
            CommonService.Toast.fire({
                title: 'User re-invited successfully',
                icon: 'success',
            });
            setIsLoading(false)
        } catch (error: any) {
            setIsLoading(false);
            CommonService.Toast.fire({
                title: error.response.data.error,
                icon: 'error',
            });
        }
        handleClose();
    };
    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
            <Tooltip title="Resend Invitation" arrow>
                <IconButton onClick={handleShow}>
                    < SendOutlined />
                </IconButton>
            </Tooltip>
            <Modal show={show} onHide={handleClose} className='modal__wd modal__wd--submitSellReq'>
                <Modal.Header className='flex-column align-items-start'>
                    <Modal.Title>Re Invite User</Modal.Title>
                    <p className="modal-text">
                        Are you sure, you want to re-invite this user?
                    </p>
                </Modal.Header>
                <Modal.Footer className='justify-content-center bg-transparent'>
                    <div className="btn-group row d-flex justify-content-between w-100">
                        <div className="col-6 ps-0">
                            <button className="secondary-btn w-100" onClick={handleClose} disabled = {isLoading}>
                                No
                            </button>
                        </div>
                        <div className="col-6 pe-0">
                            <button className="primary-btn w-100" onClick={sendInvite} disabled= {isLoading}>
                              {isLoading && <Spinner/>}  Yes
                            </button>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SingleReInvite;