import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import WhiskyBarrelBig from "../../assets/images/products/WhiskyBarrelBig.png";
import "../Dynamic/ProductDetail.scss";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ShippingCrossIcon from "../../assets/images/icons/LocalShippingCrossIcon.svg";
import AccountTransferIcon from "../../assets/images/icons/AccountTransferIcon.svg";
import AccountTransferCrossIcon from "../../assets/images/icons/AccountTransferCrossIcon.svg";
import { Button as BootstrapButton, Form } from "react-bootstrap";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import CircleBarrel from "../../assets/images/products/circleBarrel.jpg";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { useParams } from "react-router";
import { createTypesenseAdapter } from "../../typesenseAdapter";
import { Configure, Hits, InstantSearch } from "react-instantsearch";
import "../../App.css";
import PurchaseReason from "../popups/PurchaseReason";
import { ErrorOutlineSharp, Inventory } from "@mui/icons-material";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import SampleOrder from "../popups/SampleOrder";
import { OrderApiService } from "../../Service/OrderApiService";
import PurchaseOffer from "../popups/PurchaseOffer";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Cookies from "universal-cookie";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { CommonService } from "../../Service/CommonService";
import OpacityIcon from "@mui/icons-material/Opacity";
import Constant from "../../Constant";
import Alert from "react-bootstrap/Alert";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { SubmitHandler, useForm } from "react-hook-form";
import "../popups/CommonPopup.scss"
import Spinner from "../Common/Spinner";
import { error } from "console";

const ProductImage = styled("img")({
  maxWidth: "100%",
  border: "1px solid #D9D9D9", // 1px border for the image
  borderRadius: "10px",
});

const ProductCaption = styled(Typography)({
  textAlign: "center",
  marginTop: "8px",
});

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
  },
  firstColumn: {
    minWidth: 115,
    width: "max-content",
    borderBottom: "none",
    color: "#496673",
    fontSize: "14px",
    fontWeight: "700",
    backgroundColor: "transparent",
  },
  secondColumn: {
    width: "100%",
    borderBottom: "none",
    color: "#242424",
    backgroundColor: "transparent",
  },
});

type FormValue = {
  quantity: number;
  pricePerBarrel: number;
  comment: string;
  inventoryId: string;
  status: string;
  handlingFees: number;
  serviceFees: number;
  totalAmount: number;
  inventoryName: string;
  isAccountTransfer: boolean;
  isRelocationRequired: boolean;
  isThirdPartyStorage: boolean;
  companyId: string;
};

const ProductDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [dynamicSearchClient, setDynamicSearchClient] = useState(null);
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const collectionName = process.env.REACT_APP_TYPESENSE_COLLECTIONNAME; //Typesense collection name

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  useEffect(() => {
    console.log("Added");
    let filters = [`id:${id}`];

    // Generate dynamic filter string
    const filterValue = filters.join(",");
    setGlobalFilter(filterValue);
    // Create a new TypesenseInstantsearchAdapter instance with dynamic filter
    const dynamicAdapter = createTypesenseAdapter();

    // // Set searchClient to state
    setDynamicSearchClient(dynamicAdapter.searchClient);
  }, []);

  if (!dynamicSearchClient) {
    return <div>Loading...</div>;
  }

  return (
    <InstantSearch searchClient={dynamicSearchClient} indexName={collectionName}>
      <Configure filters={globalFilter} />
      <Hits hitComponent={HitItem} />
    </InstantSearch>
  );
};

const HitItem: React.FC<{ hit: any }> = ({ hit }) => {
  const classes = useStyles();
  const [count, setCount] = React.useState(1);
  const [errorMessage, setErrorMessage] = useState("");
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isInWishlist, setIsInWishlist] = useState<boolean | null>(null);
  const [isOffer, setIsOffer] = useState<boolean | null>(false);
  const [isSample, setIsSample] = useState<boolean | null>(false);
  const [formData, setFormData] = useState<FormValue>({
    quantity: 0,
    pricePerBarrel: 0,
    comment: "",
    inventoryId: hit.id,
    status: "PENDING_REVIEW",
    handlingFees: 0,
    serviceFees: 0,
    totalAmount: 0,
    inventoryName: hit.product_name,
    isAccountTransfer: hit.is_account_transfer,
    isRelocationRequired: hit.is_relocation_required,
    isThirdPartyStorage: hit.is_third_party_storage,
    companyId: CommonService.getCompanyId()
  });
 
  const userId = new Cookies().get("userId");
  const [multipleBarrels, setMultipleBarrels] = useState<string>("");
  const [multiplelots, setMultiplelots] = useState<string>("");
  const [purpose, setPurpose] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [quantity, setQuantity] = useState<any>(null);
  const [descriptionError, setDescriptionError] = useState<string>("");
  const [isSampleOrderRequestCreated, setSampleOrderRequestCreated] =
    useState<boolean>(false);
  const [isSampleOrderRequestUpdated, setSampleOrderRequestUpdated] =
    useState<boolean>(false);
  const { id } = useParams<{ id: string }>();
  const [sampleRequestValidate, setSampleRequestValidate] = useState(false);
  const [offerRequestValidate, setOfferRequestValidate] = useState(false);

  const [sampleRequestError, setSampleRequestError] = useState({
    purpose: "",
    multipleBarrels: "",
    multipleLots: "",
    size: "",
    quantity: "",
    requestNote: ""
  });

  const validateSampleRequestFields = () => {
     //validate all the fields and set the error if have and return true of false 

     let isValid = true;
     let errors = {
       purpose: "",
       multipleBarrels: "",
       multipleLots: "",
       size: "",
       quantity: "",
       requestNote: ""
     };

      // Validate purpose
  if (!purpose || purpose.trim().length === 0) {
    errors.purpose = "Please enter your purpose";
    isValid = false;
  }

  // Validate multipleBarrels
  if (!multipleBarrels) {
    errors.multipleBarrels = "Multiple barrels are required";
    isValid = false;
  }

  // Validate multipleLots
  if (!multiplelots) {
    errors.multipleLots = "Multiple lots are required";
    isValid = false;
  }

  // Validate size
  if (!size) {
    errors.size = "Please enter size";
    isValid = false;
  }

  // Validate quantity
  if (!quantity || isNaN(quantity) || quantity <= 0) {
    errors.quantity = "Please enter quantity";
    isValid = false;
  }

  //Validate request note
  if(description.trim().length == 0) {
    errors.requestNote = "Please enter request note";
    isValid = false;

  }

  setSampleRequestError(errors);
  return isValid;

  }

  let orderItems: any;
  orderItems = {
    inventoryId: hit.inventory_id,
    inventoryName: hit.product_name,
    price: hit.price,
    quantity: count,
    totalPrice: hit.price * count,
    handlingFees: hit.is_relocation_required == true ? count * 10 : 0,
    // serviceFees: (hit.is_account_transfer == true ? 10 : 0)+(hit.is_third_party_storage == true ? count*10 : 0),
    serviceFees: hit.is_account_transfer == true ? 10 : 0,
    isRelocationRequired: hit.is_relocation_required,
    isAccountTransfer: hit.is_account_transfer,
    isThirdPartyStorage: hit.is_third_party_storage,
    companyId: CommonService.getCompanyId()
  };

  const wishListRequest = {
    inventoryId: hit.inventory_id,
    userId: userId,
  };

  const handleQuanityChange = (e: any) => {
    sampleRequestError.quantity = "";
    const newCount = parseInt(e.target.value);
    console.log(newCount);

    // Validate the range before updating the state
    if (!isNaN(newCount)) {
      if (newCount < 1) {
        setCount(1);
        setErrorMessage("");
      } else if (newCount > 99999) {
        setCount(99999);
        setErrorMessage("");
      } else {
        setCount(newCount);
        setErrorMessage("");
      }
    } else {
      setCount(1);
      setErrorMessage("");
    }
  };

  useEffect(() => {
    console.log("call use Effect");
    isInventoryInWishlist();
  }, []);

  const isInventoryInWishlist = async () => {
    try {
      const isInWishlistValue = await OrderApiService.isInventoryInWishlist(
        hit.inventory_id
      );
      setIsInWishlist(isInWishlistValue.data);
    } catch (error) {
      console.error("Error while fetching wishlist:", error);
    }
  };

  const manageWishList = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    isInWishlist ? removeFromWishList() : addToWishList();
    setIsInWishlist((prev) => !prev);
  };

  const addToWishList = async () => {
    try {
      const response: any = await OrderApiService.addToWishlist(
        wishListRequest
      );
    } catch (error) {
      console.error("Error while adding wishlist data:", error);
    }
  };

  const removeFromWishList = async () => {
    try {
      const response: any = await OrderApiService.removeFromWishlist(
        wishListRequest
      );
    } catch (error) {
      console.error("Error while removing wishlist data:", error);
    }
  };

  function calculateWidth(
    count: number
  ): import("csstype").Property.Width<string | number> | undefined {
    const countLength = count.toString().length;
    if (countLength <= 2) {
      return "30px"; // Base width + some increment for 2 digits
    } else if (countLength === 3) {
      return "35px"; // More width for 3 or more digits
    } else if (countLength === 4) {
      return "40px"; // More width for 3 or more digits
    }
    return "45px"; // Base width for 1 digit
  }

  const handleOfferShow = async () => {
    setIsOffer(!isOffer);
    setIsSample(false);
    setOfferRequestValidate(false);
    formData.handlingFees = 0;
    formData.serviceFees = orderItems.isAccountTransfer ? 10 : 0;
    formData.totalAmount = formData.serviceFees;
    formData.quantity = null;
    formData.pricePerBarrel = null;
  };

  const handleRequestShow = () => {
    setIsSample(!isSample);
    setIsOffer(false);
    setSampleRequestValidate(false);
    if (isSample === true) handleRequestSampleCancel();

    checkSampleOrderStatus();
  };

  //call the service and check user is already created sample order request or not
  const checkSampleOrderStatus = async () => {
    try {
      const sampleOrder = {
        inventoryId: id,
      };
      const response = await OrderApiService.isSampleRequestCreated(
        sampleOrder
      ).then((response) => {
        const data = response.data;
        if (data != "") {
          console.log(response.data);
          setStatus(true);
          setPurpose(data.purpose);
          setMultipleBarrels(data.multipleBarrels === true ? "yes" : "no");
          setMultiplelots(data.multiplelots === true ? "yes" : "no");
          setSize(data.size);
          setQuantity(data.quantity);
          setDescription(data.comment);
        }
      });
    } catch (error) {
      console.log("error occured while status checking");
      setDescription("");
    }
  };

  const handlePriceChange = (event: any) => {
    const inputValue = event.target.value.trim();
    const value = Number(inputValue);
  
    let newPrice = 0;
    let isValid = true;
  
    // Check if the input value is a valid number and greater than 0
    if (inputValue === "" || isNaN(value) || value < 1) {
      isValid = false;
    } else {
      newPrice = value;
    }
  
    // Update the state only if the input is valid
    if (isValid) {
      const totalAmount = newPrice * formData.quantity + (formData.handlingFees + formData.serviceFees);
  
      setFormData((prevData) => ({
        ...prevData,
        pricePerBarrel: newPrice,
        totalAmount: totalAmount,
      }));
    }

  };
  

  const handleQuantityChange = (event: any) => {
  sampleRequestError.quantity = "";
  const inputValue = event.target.value.trim();
  const value = Number(inputValue);

  let handleFee = 0;
  let totalAmount = 0;

  console.log("Relocation status "+ formData.isRelocationRequired);


  if (inputValue !== "" && !isNaN(value) && value > 0) {
        handleFee = formData.isRelocationRequired ? 10 * value : 0;
        totalAmount = value * formData.pricePerBarrel + (handleFee + formData.serviceFees);
  }   


  
  // const handleFee = orderItems.isRelocationRequired ? 10 * newQuantity : 0;
  // const totalamount = newQuantity * formData.pricePerBarrel + (handleFee + formData.serviceFees);

  setFormData((prevData) => ({
    ...prevData,
    quantity: value,
    handlingFees: handleFee,
    totalAmount: totalAmount,
  }));

};

  const offerRequest: SubmitHandler<FormValue> = async () => {
    console.log(formData);
    setOfferRequestValidate(true);
    if (formData.quantity && formData.pricePerBarrel) {
      setIsLoading(true);
      try {
        // Call the service
        const response = await OrderApiService.savePurchaseOffer(formData);
        handleOfferShow();
        console.log("Data saved successfully: ", response);
        setIsLoading(false);
        CommonService.Toast.fire({
          title: "Offer Made Successfully",
          icon: "success",
        });
      } catch (error) {
        setIsLoading(false);
        console.error("Error occurred while saving the data: ", error);
      }
    }
  };

  const handleOfferCancel = async () => {
    setIsOffer(false);
    setOfferRequestValidate(false);
    formData.quantity = null;
    formData.pricePerBarrel = null;
    formData.comment = "";
    formData.inventoryId = hit.id;
    formData.inventoryName = hit.product_name;
    formData.status = "PENDING_REVIEW";
    formData.handlingFees = 0;
    formData.serviceFees = 0;
    formData.totalAmount = 0;
    formData.isRelocationRequired = hit.is_relocation_required;
    formData.isAccountTransfer = hit.is_account_transfer;
    formData.isThirdPartyStorage = hit.is_third_party_storage;
    formData.companyId = CommonService.getCompanyId()
  };

  const handleRequestSampleCancel = async () => {
    setIsSample(false);
    setQuantity(0);
    setMultipleBarrels("");
    setMultiplelots("");
    setPurpose("");
    setSize("");
    setDescription("");
    setDescriptionError("");
    setSampleRequestValidate(false);
    setSampleOrderRequestCreated(false);
    setSampleOrderRequestUpdated(false);
  };

  const handlePurposeDropdown = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    sampleRequestError.purpose = "";
    setPurpose(event.target.value);
  };

  function handleRadioChange1(event: ChangeEvent<HTMLInputElement>): void {
    sampleRequestError.multipleBarrels = "";
    setMultipleBarrels(event.target.value);
  }

  function handleRadioChange2(event: ChangeEvent<HTMLInputElement>): void {
    sampleRequestError.multipleLots = "";
    setMultiplelots(event.target.value);
  }

  const handleSizeDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    sampleRequestError.size = "";
    setSize(event.target.value);
  };

  

  const handleInputTextChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    sampleRequestError.quantity = "";
    const value = event.target.value;
    console.log(value);
    setQuantity(value);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    sampleRequestError.requestNote = "";
    console.log(value);
    setDescription(value);
    //check the length of description
    if (!value?.trim()) {
      setDescriptionError("Description is required");
    } else if (value.trim().length > 500) {
      setDescriptionError("Description can't greater then 500 characters.");
    } else {
      setDescriptionError("");
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValue>();

  //handle submit
  const requestSample: SubmitHandler<FormValue> = async () => {
    console.log("inside request submit");

    if(!validateSampleRequestFields()){
      return ;
    }

    setSampleRequestValidate(true);
    //validate description
    if (description.trim() && description.length > 0) {
      console.log("Description:", description);
      console.log("Id is", hit.id);

      if (multiplelots && multipleBarrels && size && purpose && quantity) {
        const sampleOrder = {
          description: description, 
          inventoryId: hit.id,
          inventoryName: hit.product_name,
          purpose: purpose,
          multipleBarrels: multipleBarrels == "yes" ? true : false,
          multiplelots: multiplelots == "yes" ? true : false,
          size: size,
          quantity: quantity,
          sampleRequestStatus: Constant.SampleRequestStatus.PENDING_REVIEW,
          companyId: CommonService.getCompanyId()
        };

        console.log(sampleOrder);
        //check the status
        if (status == true) {
          //call the update api
          console.log("updated called");
          updateSampleOrderRequest(sampleOrder);
          setSampleOrderRequestUpdated(true);
        } else {
          //call the new Sample Order Request api
          console.log("created api");
          addSampleOrderRequest(sampleOrder);
          setSampleOrderRequestCreated(true);
        }
      }
    } else {
      setDescriptionError("description is required");
    }
  };

  const addSampleOrderRequest = async (sampleOrder: any) => {
    setIsLoading(true);
    try {
      //call the service
      const response = await OrderApiService.saveSampleRequest(
        sampleOrder
      ).then((response) => {
        setIsSample(false);
        setIsLoading(false);
        CommonService.Toast.fire({
          title: "Your request is successfully submitted.",
          icon: "success",
        });
      });
    } catch (error) {
      setIsLoading(false);
      console.log("error adding the sample order request" + error);
      CommonService.Toast.fire({
        title: "There is some technical problem, please try again later.",
        icon: "error",
      });
    }
  };

  const updateSampleOrderRequest = async (sampleOrder: any) => {
    setIsLoading(true);
    try {
      const response = OrderApiService.updateSampleRequest(sampleOrder).then(
        (response) => {
          setIsSample(false);
          setIsLoading(false);
          CommonService.Toast.fire({
            title: "Your request is successfully updated.",
            icon: "success",
          });
        }
      );
    } catch (error) {
      console.log("error occurred in sample order request updation");
      setIsLoading(false);
      CommonService.Toast.fire({
        title: "There is some technical problem, please try again later.",
        icon: "error",
      });
    }
  };

  const manageHandlingFees = async (event) => {

    const isChecked = event.target.checked;
    if (isChecked) {
      console.log("Checked >>>>>>>>>>>>>>>>");
      const handleFee = 10 * formData.quantity;
      const total = formData.totalAmount + handleFee;
       
      console.log("total fees "+ total);

      setFormData((prevData) => ({
        ...prevData,
        handlingFees: handleFee,
        totalAmount: total,
        isRelocationRequired: true,
      }));
      formData.isRelocationRequired = true;
    } else {

      console.log("Checked >>>>>>>>>>>>>>>>");
      console.log("handling fees " + formData.handlingFees);
      const total =
        formData.totalAmount === 0
          ? 0
          : formData.totalAmount - formData.handlingFees;
      const handleFee = 0;

      setFormData((prevData) => ({
        ...prevData,
        totalAmount: total,
        handlingFees: handleFee,
        isRelocationRequired: false,
      }));
      formData.isRelocationRequired = false;
    }
  };

  const manageServiceFees = async (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setFormData((prevData) => ({
        ...prevData,
        serviceFees: 10,
        totalAmount: formData.totalAmount + 10,
        isAccountTransfer: true,
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        serviceFees: 0,
        totalAmount: formData.totalAmount === 0 ? 0 : formData.totalAmount - 10,
        isAccountTransfer: false,
      }));
    }
  };

  return (
    <>
      {/* Start */}
      <section className="ProductDetail my-3">
        <div className="container my-3">
          {/* <div className="col-lg-12 mb-3">
              <Box
                sx={{
                  display: "inline-flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    borderRadius: "10px",
                    display: "flex",
                    justifyContent: "center",
                    border: "1px solid #D9D9D9",
                    width: "100%",
                    height: "350px",
                  }}
                >
                  <ProductImage
                    src={CircleBarrel}
                    alt="Product Image"
                    sx={{ mx: "auto", border: "none", alignSelf: "center" }}
                    width={112}
                    height={112}
                  />
                </Box>
                <ProductCaption variant="body2">
                  <LocalShippingOutlinedIcon sx={{ color: "#7E84A3", mr: 1 }} />
                  Relocation Not Required
                </ProductCaption>
              </Box>
            </div> */}
          <div className="row d-flex mb-5">
            <div className="col-12 col-lg-6 mb-5 mb-lg-0">
              <div className="product__img d-flex justify-content-center">
                <img src={WhiskyBarrelBig} alt="WhiskyBarrelBig" />
              </div>
              <div className="product__content">
                <div className="content__title">
                  <h3>About Bourbon Whisky Sample Product</h3>
                </div>
                <div className="product__inner-content">
                  <p>{hit.description}</p>
                  <h6>
                    <strong>Grab your commemorative barrel today!</strong>
                  </h6>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="product__info">
                <div className="row">
                  <div className="col-12">
                    <div className="product__heading d-flex justify-content-between">
                      <Typography
                        gutterBottom
                        variant="h2"
                        component="h2"
                        fontSize={24}
                        sx={{
                          fontWeight: 700,
                          color: "#9E874A",
                          lineHeight: "22px",
                        }}
                      >
                        {hit.product_name}
                      </Typography>
                      <div onClick={manageWishList}>
                        {isInWishlist ? (
                          <Tooltip title="Remove from Wishlist" arrow>
                            <FavoriteIcon
                              sx={{ cursor: "pointer", color: "red" }}
                            />
                          </Tooltip>
                        ) : (
                          <Tooltip title="Add to Wishlist" arrow>
                            <FavoriteBorderIcon
                              sx={{ cursor: "pointer", color: "#A1A1A1" }}
                            />
                          </Tooltip>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="product__cart mb-3">
                  <div className="row d-flex flex-wrap justify-content-end align-items-center">
                    <div className="col-auto my-2 my-sm-1 px-1">
                      {/* <Button variant="outlined">
                                                <ArrowBackIcon sx={{ mr: 1 }} />
                                                Back
                                            </Button> */}
                      <div className="price__lable">
                        <CurrencyFormatter
                          amount={hit.price}
                          locale="en-US"
                          currency="USD"
                        />{" "}
                        / Barrel
                      </div>
                    </div>
                    <div className="col-auto ms-auto my-2 my-sm-1 px-1">
                      <div className="cart__add--btn d-flex justify-content-end">
                        <Tooltip
                          title={errorMessage}
                          arrow
                          open={!!errorMessage}
                        >
                          <ButtonGroup sx={{ alignSelf: "center", my: 1 }}>
                            <Button
                              aria-label="reduce"
                              onClick={() => {
                                if (Math.max(count - 1, 0) < 1) {
                                  setCount(1);
                                  setErrorMessage("");
                                } else {
                                  setCount(Math.max(count - 1, 0));
                                  setErrorMessage("");
                                }
                              }}
                            >
                              <RemoveIcon fontSize="small" />
                            </Button>
                            {/* Replace Button with TextField for input */}
                            <TextField
                              size="small"
                              inputProps={{
                                style: {
                                  textAlign: "center",
                                  width: calculateWidth(count),
                                },
                                "aria-label": "count",
                              }}
                              value={count}
                              onChange={handleQuanityChange}
                            />
                            <Button
                              aria-label="increase"
                              onClick={() => {setCount(count + 1);
                              }}
                            >
                              <AddIcon fontSize="small" />
                            </Button>
                          </ButtonGroup>
                        </Tooltip>
                        <PurchaseReason
                          disabled={!!errorMessage}
                          rejectUser={orderItems}
                        />
                      </div>
                      {/* <IconButton sx={{ mr: 1, my: 1 }}>
                                                <FavoriteBorderRoundedIcon sx={{ mr: 1 }} />
                                                <Typography>Add to Wishlist</Typography>
                                                </IconButton> */}
                      {/* <SampleOrder
                                                id={hit.id}
                                                orderDescription={description}
                                                status={status}
                                                onDescriptionUpdate={updateDescription}
                                            /> */}
                    </div>
                  </div>
                  <div className="row d-flex flex-wrap justify-content-end">
                    <div className="col-auto">
                      {hit.is_account_transfer ? (
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              py: 1,
                              fontSize: "14px",
                              color: "#9E874A",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={AccountTransferIcon}
                              alt="ShippingCrossIcon"
                              className="me-2"
                              width={28}
                              height={24}
                            />
                            Change of Account Required
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              py: 1,
                              fontSize: "14px",
                              color: "#AFAFB0",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={AccountTransferCrossIcon}
                              alt="ShippingCrossIcon"
                              className="me-2"
                              width={23}
                              height={23}
                            />
                            Change of Account not Required
                          </Typography>
                        </>
                      )}
                    </div>
                    <div className="col-auto ms-auto">
                      {hit.is_relocation_required ? (
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              py: 1,
                              fontSize: "14px",
                              color: "#9E874A",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <LocalShippingOutlinedIcon
                              sx={{ color: "#9E874A", mr: 1 }}
                            />
                            Relocation Required
                          </Typography>
                        </>
                      ) : (
                        <>
                          <Typography
                            variant="body2"
                            sx={{
                              py: 1,
                              fontSize: "14px",
                              color: "#AFAFB0",
                              display: "inline-flex",
                              alignItems: "center",
                            }}
                          >
                            <img
                              src={ShippingCrossIcon}
                              alt="ShippingCrossIcon"
                              className="me-2"
                              width={30}
                              height={19}
                            />
                            Relocation not Required
                          </Typography>
                        </>
                      )}
                    </div>
                  </div>
                  <hr className="hr-line" />
                  <div className="row">
                    <div
                      className="col-auto my-2 my-sm-1"
                      style={{ cursor: "pointer" }}
                    >
                      <div className={`request__sample d-inline-flex align-items-center ${isSample? "active": ""}`}>
                        {/* <SampleOrder
                          id={hit.id}
                          inventoryName={hit.product_name}
                          text={true}
                        /> */}
                        <Tooltip title="Request Sample" arrow>
                          <Button
                            variant="contained"
                            onClick={handleRequestShow}
                          >
                            {/* Request Sample */}
                            <OpacityIcon sx={{ color: "#496673" }} />
                          </Button>
                        </Tooltip>
                        {/* {text && ( */}
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#242424",
                            fontWeight: 600,
                            fontSize: "16px",
                            ml: 1,
                          }}
                          onClick={handleRequestShow}
                        >
                          Request Sample
                        </Typography>
                        {/* )} */}
                      </div>
                    </div>
                    {hit.is_make_an_offer && (
                      <div
                        className="col-auto my-2 my-sm-1"
                        style={{ cursor: "pointer" }}
                        onClick={handleOfferShow}
                      >
                        <div className={`make__offer d-inline-flex align-items-center ${isOffer? "active": ""}`}>
                          {/* <PurchaseOffer inventoryId={hit.id} /> */}
                          <Tooltip title="Make an Offer" arrow>
                            <Button variant="contained" sx={{ ml: 2, my: 1 }}>
                              <MonetizationOnOutlinedIcon
                                sx={{ color: "#496673" }}
                              />
                            </Button>
                          </Tooltip>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#242424",
                              fontWeight: 600,
                              fontSize: "16px",
                              ml: 1,
                            }}
                          >
                            Make an offer
                          </Typography>
                        </div>
                      </div>
                    )}
                  </div>

                  {isSample && (
  <div className="request__sample--details my-3">
    <div className="card card-body border border-0">
      <form
        className="mb-3"
        onSubmit={handleSubmit(requestSample)}
        noValidate
        // validated={sampleRequestValidate}
      >
        <div className="mb-3">
          <div className="row mb-3">
            <label className="col-sm-2" htmlFor="purpose">
              <span className="text-nowrap">Purpose
              <span className="star-required text-danger">*</span>
              </span>              
            </label>
            <div className="col-sm-10">
              <select
                id="purpose"
                required
                className={`form-select ${sampleRequestError.purpose ? "is_invalid": ""}`}
                style={{ cursor: "pointer" }}
                value={purpose}
                onChange={handlePurposeDropdown}
              >
                <option value="">Select Purpose</option>
                <option value="Taste Profile Analysis">Taste Profile Analysis</option>
                <option value="Product Testing">Product Testing</option>
                <option value="Quantity Testing">Quantity Testing</option>
              </select>
              {sampleRequestError.purpose && (<div className="error-message">{sampleRequestError.purpose}</div>)}
            </div>
          </div>

          <div className="d-flex flex-wrap mb-3">
            <label className="fw-normal">
              Multiple barrels from same lot? <span className="star-required text-danger">*</span>
            </label>
            <input
              type="radio"
              id="radioOption1"
              className="form-check-inline fw-semibold ps-0"
              name="radioOptions1"
              value="yes"
              onChange={handleRadioChange1}
              checked={multipleBarrels === "yes"}
              style={{ cursor: "pointer" }}
              required
            />
            <label htmlFor="radioOption1">Yes</label>
            <input
              type="radio"
              id="radioOption2"
              className="form-check-inline fw-semibold ps-0"
              name="radioOptions1"
              value="no"
              onChange={handleRadioChange1}
              checked={multipleBarrels === "no"}
              style={{ cursor: "pointer" }}
              required
            />
            <label htmlFor="radioOption2" className="ms-2">No</label>
            {sampleRequestError.multipleBarrels && (<div className="error-message w-100">{sampleRequestError.multipleBarrels}</div>)}

          </div>

          <div className="d-flex flex-wrap mb-3">
            <label className="fw-normal">
              Multiple lots needed? <span className="star-required text-danger">*</span>
            </label>
            <input
              type="radio"
              id="radioOption3"
              className="form-check-inline fw-semibold ps-0"
              name="radioOptions2"
              value="yes"
              onChange={handleRadioChange2}
              checked={multiplelots === "yes"}
              style={{ cursor: "pointer" }}
              required
            />
            <label htmlFor="radioOption3">Yes</label>
            <input
              type="radio"
              id="radioOption4"
              className="form-check-inline fw-semibold ps-0"
              name="radioOptions2"
              value="no"
              onChange={handleRadioChange2}
              checked={multiplelots === "no"}
              style={{ cursor: "pointer" }}
              required
            />
            <label htmlFor="radioOption4" className="ms-2">No</label>
            {sampleRequestError.multipleLots && (<div className="error-message w-100">{sampleRequestError.multipleLots}</div>)}

          </div>

          <div className="row mb-3">
            <div className="col-md-6">
              <div className="row d-flex flex-column">
                <label htmlFor="size" className="col">
                  Size Needed? <span className="star-required text-danger">*</span>
                </label>
                <div className="col">
                  <select
                    id="size"
                    className={`form-select ${sampleRequestError.size ? "is_invalid": ""}`}
                    style={{ cursor: "pointer" }}
                    value={size}
                    onChange={handleSizeDropdown}
                    required
                  >
                    <option value="">Select Size</option>
                    <option value="750mL">750mL</option>
                    <option value="200mL">200mL</option>
                    <option value="100mL">100mL</option>
                  </select>
                  {sampleRequestError.size && (<div className="error-message">{sampleRequestError.size}</div>)}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row d-flex flex-column">
                <label htmlFor="quantity" className="col">
                  Qty Requested of Size <span className="star-required text-danger">*</span>
                </label>
                <div className="col">
                  <input
                    id="quantity"
                    className={`form-control ${sampleRequestError.quantity ? "is_invalid": ""}`}
                    required
                    type="text"
                    value={quantity}
                    onChange={handleInputTextChange}
                    placeholder="Enter Qty..."
                  />
                  {sampleRequestError.quantity && (<div className="error-message">{sampleRequestError.quantity}</div>)}
                  </div>
              </div>
            </div>
          </div>

          <div className="mb-3 form__inner">
            <label htmlFor="description">
              Request Notes <span className="star-required text-danger">*</span>
            </label>
            <textarea
              id="description"
              className={`form-control textarea ${sampleRequestError.requestNote ? "is_invalid" : ""}`}
              aria-label="With textarea"
              value={description}
              onChange={handleInputChange}
              rows={3}
              style={{ resize: "none" }}
              placeholder="Enter notes..."
              required
            ></textarea>
            <p className="d-flex mt-1">
            {sampleRequestError.requestNote && (
                <span className="error-message">{sampleRequestError.requestNote}</span>
              )}
              <span className="ms-auto">{description.length}/500</span>
            </p>

            
          </div>

          <div className="text-end border-top border-dark-subtle pt-3">
            <button
              className="secondary-btn"
              onClick={handleRequestSampleCancel}
              disabled={isLoading}
              type="button"
            >
              Cancel
            </button>
            <button type="submit" className="primary-btn ms-2" disabled={isLoading}>
              {isLoading && <Spinner />} Submit Request
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
)}



{isOffer && (
  <form
    className="mb-3"
    onSubmit={handleSubmit(offerRequest)}
    noValidate
  >
    <div className="make__offer--details my-3">
      <div className="card card-body row d-flex flex-row border border-0 mx-0">
        <div className="col-md-6 ps-md-0">
          <div className="d-flex align-items-center mb-4">
            <Typography
              variant="body2"
              sx={{
                color: "#242424",
                fontWeight: 600,
                fontSize: "16px",
                mr: 1,
                textWrap: "nowrap",
              }}
            >
              Price / Barrel  <span className="star-required text-danger">*</span>
            </Typography>
            <div className="position-relative form__inner">
              <input
                type="number"
                value={formData.pricePerBarrel}
                id="price-per-barrel"
                placeholder="Price($)"
                // size="small"
                onChange={handlePriceChange}
                // sx={{ width: "100%" }}
                // rows={1}
                // style={{resize:"none"}}
                min={1}
                className={`form-control ${offerRequestValidate && !formData.pricePerBarrel ? "is_invalid" : ""}`}
              />
              {offerRequestValidate && !formData.pricePerBarrel && (
                <div className="error-message position-absolute">Please enter price</div>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 pe-md-0">
          <div className="d-flex align-items-center mb-4">
            <Typography
              variant="body2"
              sx={{
                color: "#242424",
                fontWeight: 600,
                fontSize: "16px",
                mr: 1,
                textWrap: "nowrap",
              }}
            >
              Barrels  <span className="star-required text-danger">*</span>
            </Typography>
            <div className="position-relative form__inner">
              <input
                type="number"
                value={formData.quantity}
                id="quantity"
                placeholder="Quantity"
                // size="small"
                onChange={handleQuantityChange}
                min={1}
                // sx={{ width: "100%" }}
                className={`form-control ${offerRequestValidate && !formData.quantity ? "is_invalid" : ""}`}
                required
              />
              {offerRequestValidate && !formData.quantity && (
                <div className="error-message position-absolute">Please enter quantity</div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 px-0">
          <div className="row d-flex mx-0 mb-3">
          <div className="col-md-6 mb-2 mb-md-0 ps-md-0">
    {!hit.is_account_transfer && (
      <label className="d-flex">
        <Checkbox
          sx={{
            color: "#AFAFB0",
            height: "max-content",
            p: 0,
            mr: 1,
            "&.Mui-checked": {
              color: "#6750A4",
              "& .MuiSvgIcon-root": {
                backgroundColor: "transparent",
              },
            },
          }}
          onChange={manageServiceFees}
        />
        <span>I would like to change account</span>
      </label>
    )}
  </div>
  <div className="col-md-6 pe-md-0">
    {!hit.is_relocation_required && (
      <label className="d-flex">
        <Checkbox
          sx={{
            color: "#AFAFB0",
            height: "max-content",
            p: 0,
            mr: 1,
            "&.Mui-checked": {
              color: "#6750A4",
              "& .MuiSvgIcon-root": {
                backgroundColor: "transparent",
              },
            },
          }}
          onChange={manageHandlingFees}
        />
        <span>I would like to relocate barrels</span>
      </label>
    )}
  </div>
          </div>
        </div>
        <div className="col-12">
          <div className="d-flex flex-column border-top border-dark-subtle py-2">
            <div className="text-end my-3">
              <Typography
                variant="body2"
                sx={{
                  color: "#242424",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                }}
              >
                Total Handling Fees:{" "}
                <CurrencyFormatter
                  amount={formData.handlingFees}
                  locale="en-US"
                  currency="USD"
                />
              </Typography>
            </div>
            <div className="text-end my-3">
              <Typography
                variant="body2"
                sx={{
                  color: "#242424",
                  fontWeight: 600,
                  fontSize: "18px",
                  lineHeight: "22px",
                }}
              >
                Total Service Fees:{" "}
                <CurrencyFormatter
                  amount={formData.serviceFees}
                  locale="en-US"
                  currency="USD"
                />
              </Typography>
            </div>
          </div>
        </div>
        <div className="col-12 px-0">
          <div className="d-flex flex-column border-top border-dark-subtle pt-3">
            <div className="text-end my-2">
              <Typography
                variant="body2"
                sx={{
                  color: "#242424",
                  fontWeight: 600,
                  fontSize: "20px",
                  lineHeight: "22px",
                }}
              >
                Total offer:{" "}
                <CurrencyFormatter
                  amount={formData.totalAmount}
                  locale="en-US"
                  currency="USD"
                />
              </Typography>
            </div>
            <div className="text-end my-2">
              <button
                className="secondary-btn"
                onClick={handleOfferCancel}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="primary-btn ms-2"
                disabled={isLoading}
              >
               {isLoading && <Spinner/>} Submit Offer
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
)}
                </div>
                <TableContainer
                  component={Paper}
                  className={classes.root}
                  sx={{ mt: 3 }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Price
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          <CurrencyFormatter
                            amount={hit.price}
                            locale="en-US"
                            currency="USD"
                          />
                          / barrel
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Volume
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {" "}
                          {hit.volume.toFixed(2)} {hit.volume_unit}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Barrel Size
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.barrel_size}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Mash Bill
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.mash_bill}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Proof
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.proof} pf
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Proof Gallons
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.proof_gallons} pf-gal
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Spirit Age
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.spirit_age_year} years {hit.spirit_age_month}{" "}
                          months
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Spirit
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.ttb_type}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Tasting Note
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.tasting_note}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Stage
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.stage}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Facility
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.facility}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Lot Id
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.lot_no}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Barrel Number
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.barrel_number}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Fill Date
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.fill_date}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Barrel #
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.barrel_number}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Barrel Profile
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.barrel_profile}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Cost
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          ${hit.barrel_cost}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          className={classes.firstColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          Type
                        </TableCell>
                        <TableCell
                          className={classes.secondColumn}
                          sx={{ verticalAlign: "top" }}
                        >
                          {hit.ttb_type}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <div className="row mb-5">
                  <div className="col-12">
                    <Typography
                      variant="h5"
                      sx={{ mb: 1, pb: 1, borderTop: "2px solid #ABABAB" }}
                    >
                      About {hit.ttb_type}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      {hit.description}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* End */}

      {/* <section className="customer--view__cart my-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div className="col-auto">
                                    <Typography variant="h5" sx={{ mb: 2 }}>
                                        My Cart
                                    </Typography>
                                </div>
                                <div className="col-auto">
                                    <Button variant="outlined">Clear Cart</Button>
                                </div>
                            </div>
                            <div className="row">
                                <List sx={{ bgcolor: 'background.paper' }}>
                                    <ListItem sx={{ fontSize: '16px' }}>
                                        <div className="row d-flex w-100">
                                            <div className="col-2">
                                                <div className="row">
                                                    <img src={BourbonWhiskyBarrel} alt="BourbonWhiskyBarrel" />
                                                </div>
                                            </div>
                                            <div className="col-10">
                                                <div className="row">
                                                    <div className="col-lg-7 mb-2 mb-lg-0">
                                                        <Typography variant="h5" sx={{ mb: 1, fontWeight: 800, color: '#1E5EFF' }}>Bourbon Whisky</Typography>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                            <Typography variant="body2" sx={{ mb: 0, fontWeight: 700, fontSize: '16px' }}>2+ Years Old</Typography>
                                                            </div>
                                                            <div className="col-auto">
                                                            <Typography variant="body2" sx={{ mb: 0, fontWeight: 700, fontSize: '16px' }}>53 Gal</Typography>
                                                            </div>
                                                            <div className="col-auto">
                                                            <Typography variant="body2" sx={{ mb: 0, fontWeight: 700, fontSize: '16px' }}>C(60%) / R(33%) / W(0%) / MB (7%)</Typography>
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex">
                                                            <div className="col-auto mb-2">
                                                            <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '16px' }}>Purpose: </Typography>
                                                            </div>
                                                            <div className="col-9 ps-0">
                                                            <Typography variant="body2" sx={{ fontSize: '16px' }}>For investment purposes and to build a collection of rare and sought-after bottles</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <div className="row d-flex justify-content-lg-center">
                                                            <div className="col-auto">
                                                                <ButtonGroup>
                                                                    <Button
                                                                        aria-label="reduce"
                                                                        onClick={() => {
                                                                            setCount(Math.max(count - 1, 0));
                                                                        }}
                                                                    >
                                                                        <RemoveIcon fontSize="small" />
                                                                    </Button>
                                                                    <Button>
                                                                        {count}
                                                                    </Button>
                                                                    <Button
                                                                        aria-label="increase"
                                                                        onClick={() => {
                                                                            setCount(count + 1);
                                                                        }}
                                                                    >
                                                                        <AddIcon fontSize="small" />
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </div>
                                                            <div className="col-auto">
                                                                <Button variant="text"><DriveFileRenameOutlineOutlinedIcon  sx={{ mr: 1 }}/>Edit</Button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <Button variant="text"><DeleteOutlinedIcon  sx={{ mr: 1 }}/>Remove</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>
                                </List>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className='border border-secondary-subtle p-2 h-100'>
                                <div className="row d-flex justify-content-between align-items-center">
                                    <div className="col-auto">
                                        <Typography variant="h5" sx={{ mb: 2 }}>
                                            Summary
                                        </Typography>
                                    </div>
                                </div>
                                <div className="row px-3">
                                    <List sx={{ bgcolor: 'transparent', borderBottom: '1px solid #C4C8CB' }}>
                                        <ListItem sx={{ pr: 0 }}>
                                            <div className="row d-flex justify-content-between align-items-center w-100">
                                                <div className="col-auto">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-auto">
                                                            <div className="row d-flex flex-column">
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span" sx={{ mb: 2 }}>
                                                                        Bourbon Whisky
                                                                    </Typography>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span">
                                                                        $2,050 / barrel
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="row d-flex">
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span">
                                                                        <CloseOutlinedIcon />
                                                                    </Typography>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span">
                                                                        5
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <Typography variant="body1">
                                                        $10,250.00
                                                    </Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                        <ListItem sx={{ pr: 0 }}>
                                            <div className="row d-flex justify-content-between align-items-center w-100">
                                                <div className="col-auto">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-auto">
                                                            <div className="row d-flex flex-column">
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span" sx={{ mb: 2 }}>
                                                                        Bourbon Whisky
                                                                    </Typography>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span">
                                                                        $2,050 / barrel
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="row d-flex">
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span">
                                                                        <CloseOutlinedIcon />
                                                                    </Typography>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span">
                                                                        5
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <Typography variant="body1">
                                                        $10,250.00
                                                    </Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                        <ListItem sx={{ pr: 0 }}>
                                            <div className="row d-flex justify-content-between align-items-center w-100">
                                                <div className="col-auto">
                                                    <div className="row d-flex align-items-center">
                                                        <div className="col-auto">
                                                            <div className="row d-flex flex-column">
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span" sx={{ mb: 2 }}>
                                                                        Bourbon Whisky
                                                                    </Typography>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span">
                                                                        $2,050 / barrel
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <div className="row d-flex">
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span">
                                                                        <CloseOutlinedIcon />
                                                                    </Typography>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <Typography variant="body1" component="span">
                                                                        5
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-auto">
                                                    <Typography variant="body1">
                                                        $10,250.00
                                                    </Typography>
                                                </div>
                                            </div>
                                        </ListItem>
                                    </List>
                                </div>
                                <div className="row d-flex justify-content-end p-2">
                                    <div className="col-auto">
                                        <Typography variant="h6">Total Price</Typography>
                                    </div>
                                    <div className="col-auto">
                                        <Typography variant="h6">$16,910.00</Typography>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-end px-2">
                                    <div className="col-auto">
                                        <Typography variant="body2">Ownership Transfer Fee and Transport Fee are excluded</Typography>
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-center py-3">
                                    <div className="col-auto">
                                    <Button variant="contained">Place Order Request</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
    </>
  );
};

export default ProductDetail;