import {
  FormControl,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { Service } from "../../Service/ApiService";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  VisibilityOutlined,
  EditOutlined,
  DeleteOutlined,
  SendOutlined,
  Iso,
} from "@mui/icons-material";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/UserList.scss";
import InviteUser from "../popups/InviteUser";
import React from "react";
import SingleApprove from "../popups/SingleApprove";
import SingleReInvite from "../popups/SingleReInvite";
import SingleReject from "../popups/SingleReject";
import { Button, ButtonGroup } from "react-bootstrap";
import DeleteUser from "../popups/DeleteUser";
import Tooltip from "@mui/material/Tooltip";
import SearchBar from "../Static/SearchBar";
import { isDebuggerStatement } from "typescript";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import SplitButton from "react-bootstrap/SplitButton";
import { useHistory, useLocation, useParams } from "react-router";
import StatusDropdown from "../Common/StatusDropdown";
import AddSharpIcon from '@mui/icons-material/AddSharp';
import InfoIcon from "@mui/icons-material/Info";
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import { CookieService } from "../../Service/CookieService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const UserList = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { id } = useParams<{ id: string }>();
  const [tableData, setTableData] = useState<any[]>([]);
  const [reviewUserCount, setReviewCount] = useState(0);
  const [search, setSearch] = useState("");
  let approved: any[] = [];
  const approvedUserRef = useRef(approved);
  let rejected: any[] = [];
  const rejectedUserRef = useRef(rejected);
  const disableAction = useRef(false);
  const isNeedReviewClicked = useRef(false);
  const selectedRows = useRef(approved);
  const history = useHistory();
  const [activeUserCount, setActiveUserCount] = useState(0);
  const [rejectedUsersCount, setRejectedUserCount] = useState(0);
  const isRejectedUserClicked = useRef(false);
  const isActiveUserClicked = useRef(false);

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const reloadTable = () => {
    if (isActiveUserClicked.current) {
      fetchApprovedUserData();
    } else if(isNeedReviewClicked.current) {
      showSubmittedAndInvitedUsers(true);
      fetchRejectedUsersCount();
      fetchActiveUsersCount();
    } else {
      showRejectedUsers(true);
    }

  };

  const showSubmittedAndInvitedUsers = (isReload: boolean) => {
    if (!isReload) {
      isNeedReviewClicked.current = !isNeedReviewClicked.current;
    }
    if (isNeedReviewClicked.current) {
      isActiveUserClicked.current = false;
      isRejectedUserClicked.current = false;
      Service.getSubmittedUsers()
        .then((response) => {
          let c: any = [];
          c = response.data;
          setTableData(c);
          setReviewCount(c.length);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // CommonService.unAuthorizedError();
          } else {
            // Process the response data
            CommonService.Toast.fire({
              icon: "error",
              title: "error.response.data.error",
            });
          }
        });
    } else {
      console.log("called approved user : ", approvedUserRef.current);
      fetchApprovedUserData();
    }
  };

  const showActiveUsers = (isReload: boolean) => {
      //write the logic for show active users
      if(!isActiveUserClicked.current) {
        isRejectedUserClicked.current = false;
        isNeedReviewClicked.current = false;
        isActiveUserClicked.current = true;
        fetchApprovedUserData();
      }
  }

  const showRejectedUsers = (isReload: boolean) => {
    
    if (!isReload) {
      isRejectedUserClicked.current = !isRejectedUserClicked.current;
    }
    if (isRejectedUserClicked.current) {
      isActiveUserClicked.current = false;
      isNeedReviewClicked.current = false;
      Service.getAllRejectedUsers(CookieService.getCookie("companyId"))
        .then((response) => {
          let c: any = [];
          c = response.data;
          setTableData(c);
          setRejectedUserCount(c.length);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            // CommonService.unAuthorizedError();
          } else {
            // Process the response data
            CommonService.Toast.fire({
              icon: "error",
              title: "error.response.data.error",
            });
          }
        });
    } else {
      console.log("called rejected user : ", rejectedUserRef.current);
      fetchApprovedUserData();
    }
  }

  //redirect to the add user page
  const handleAddUser = () => {
    history.push("/master/add-user");
  };

  const fetchApprovedUserData = async () => {
    try {
      setIsLoading(true);
      isActiveUserClicked.current = true;
      const response = await Service.getAllApprovedUsers(CookieService.getCookie("companyId"));
      approved = Object.values(response.data); // Convert object to array
      setActiveUserCount(approved.length);
      approvedUserRef.current = approved;
      // Access updated value of approvedUser using approvedUserRef.current
      if (approved) {
        setTableData(approvedUserRef.current);
      }
      Service.getSubmittedUsersCount().then((response) => {
        setReviewCount(response.data);
      });
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const fetchActiveUsersCount = async() => {

    try{

      const response = await Service.getActiveUsersCount();
      setActiveUserCount(response.data);

    } catch (error) {
      console.error("Error fetching counts:", error);
    }
  }

  const fetchCompanyUserData = async () => {
    const response: any = await Service.fetchCompanyUserData(
      CommonService.isUser() ? CommonService.getCompanyId() : id
    );
    setTableData(response.data);
  };

  const fetchRejectedUsersCount = async() =>  {
    try{
      const response = await Service.getRejectedUsersCount(CookieService.getCookie("companyId"));
      setRejectedUserCount(response.data);
    } catch(error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (
      currentPath === "/master/company/user-list/" + id ||
      currentPath === "/user/user-list"
    )
      fetchCompanyUserData();
    fetchApprovedUserData();
    fetchRejectedUsersCount();
  }, []);

  const handleVisibilityClick = (event: any, params: any) => {
    // Logic to handle the click event for Visibility button
    event.stopPropagation(); // Prevent event from bubbling up to the row
    CommonService.Toast.fire({
      title: "View icon clicked",
    });
  };

  //add tooltip
  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  const commonColumns: GridColDef[] = [
    {
      field: 'user',
      headerName: 'User',
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const firstName = row.firstName || '';
        const lastName = row.lastName || '';
        return (firstName || lastName) ? `${firstName} ${lastName}` : '-';
      },
      headerClassName: 'table-header',
      renderCell: (params: any) => <div>{params.value}</div>,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value || '-'} />,
    },
    {
      field: "phoneNumber",
      headerName: "Phone",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value || '-'} />,
    },
    {
      field: "birthdate",
      headerName: "Birth Date",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params.value || '-'} />,
      valueGetter: (value: any, row: any) => {
        const dateTime = row.birthdate ? new Date(row.birthdate) : null;

        // Format Date and time
        if (dateTime) {
          const options: any = {
            month: "short", // Abbreviated month name (e.g., "Jul")
            day: "numeric", // Day of the month (e.g., "16")
            year: "numeric", // Full year (e.g., "2024")
          };
          return dateTime.toLocaleString("en-US", options);
        } else {
          return "";
        }
      },
    },
    {
      field: "ecommCompanyName",
      headerName: "Company Name",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => (
        <CellWithTooltip value={params?.row?.ecommCompanyName || '-'} />
      ),
    },
    {
      field: "siteId",
      headerName: "Site Id",
      headerClassName: "table-header",
      flex: 1,
      renderCell: (params) => <CellWithTooltip value={params?.value || '-'} />,
    },
    {
      field: "location",
      headerName: "Location",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const city = row?.city;
        const state = row?.state;
        const zipcode = row?.zipcode;
        // Check if city exists and add comma
        const cityWithComma = city ? city + ", " : "";
        // Check if state exists and add space
        const stateWithSpace = state ? state + " " : "";
        return `${cityWithComma}${stateWithSpace}${zipcode || ""}`;
      },
      renderCell: (params) => <CellWithTooltip value={params.value || '-'} />,
      headerClassName: "table-header",
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      width: 200,
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <div onClick={(e) => e.stopPropagation()}>
        
        {params.row.userStatus !== "INVITED" && params.row.userStatus !== "PENDING_REVIEW" && (
            <Tooltip title="View user" arrow>
              <IconButton
                onClick={() => handleProfile(params.row.id)}
              >
                <VisibilityOutlined />
              </IconButton>
            </Tooltip>
          )} 
          {params.row.userStatus === "INVITED" && (
            // <IconButton onClick={(event) => handleResendClick(event, params)}>
            //     < SendOutlined />
            // </IconButton>
            <SingleReInvite reInvitedUser={params.row.email} />
          )}
          {params.row.userStatus === "PENDING_REVIEW" && (
            // <IconButton onClick={(event) => handleApproveClick(event, params)}>
            //                         <FontAwesomeIcon icon={faUserCheck} />
            //                     </IconButton>
            <SingleApprove
              approveUser={[params.row]}
              displayIcon={true}
              reloadTable={reloadTable}
            />
          )}
          {params.row.userStatus === "PENDING_REVIEW" && (
            // <IconButton onClick={(event) => handleRejectClick(event, params)}>
            //     <FontAwesomeIcon icon={faUserTimes} />
            // </IconButton>
            <SingleReject
              rejectUser={[params.row]}
              displayIcon={true}
              reloadTable={reloadTable}
            />
          )}
        </div>
      ),
    },
  ];

  const userStatusColumn: GridColDef = {
    field: "userStatus",
    headerName: "Status",
    flex: 1,
    headerClassName: "table-header",
    renderCell: (params) => (<div>
      <StatusDropdown
        currentStatus={params.row.userStatus}
        onStatusChange={(newStatus) => {}}
        disableCondition={CommonService.isUser() || CommonService.isAdmin()}
        allowedLabels={[]}
      />
       {params.row.userStatus === "REJECTED" && (
          <Tooltip title={`Rejection Note: ${params.row.rejectionNote}`} arrow>
            <StickyNote2Icon
              className="note-icon cursor-pointer"
              style={{ color: "gray" }}
            />
          </Tooltip>
        )}
    </div>)
  };


  // const columns: GridColDef[] = [
  //   ...commonColumns,
  //   ...(isNeedReviewClicked.current ? conditionalColumns : []),
  // ];

  const columns: GridColDef[] = useMemo(() => {

    if (isRejectedUserClicked.current || isNeedReviewClicked.current) {
      // Insert the userStatus column after the "location" column
      const locationIndex = commonColumns.findIndex(col => col.field === "location");
      if (locationIndex !== -1) {
        return [
          ...commonColumns.slice(0, locationIndex + 1),
          userStatusColumn,
          ...commonColumns.slice(locationIndex + 1)
        ];
      }
    }
  
    return commonColumns;
  }, [isNeedReviewClicked.current, isRejectedUserClicked.current]);


  const handleRowClick = (params) => {

    console.log("this is my current status "+ params.row.userStatus);

    //row clickable only when status is not invited and pending_review
      if(params.row.userStatus !== "INVITED")
        handleProfile(params.row.id);
  }
  

  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    const newSelectedRows = newSelection.map((id: GridRowId) => {
      return tableData.find((row: any) => row.id === id); // Assuming each row object has an 'id' property
    });
    selectedRows.current = newSelectedRows;
    let flag: any = false;
    flag = selectedRows.current.some((user) => user.userStatus === "INVITED");
    disableAction.current = flag;
  };

  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };

  // Filter rows based on search query
  const filteredRows = tableData.filter(
    (row) =>
      row.email?.toLowerCase().includes(search?.toLowerCase()) ||
      row.companyName?.toLowerCase().includes(search?.toLowerCase()) ||
      row.firstName?.toLowerCase().includes(search?.toLowerCase()) ||
      row.lastName?.toLowerCase().includes(search?.toLowerCase()) ||
      row.phoneNumber?.toLowerCase().includes(search?.toLowerCase()) ||
      (row.firstName + " " + row.lastName)
        .toLowerCase()
        .includes(search?.toLowerCase())
  );

  // Map filtered rows with unique IDs
  const tableDataWithIds = filteredRows.map((rowData, index) => ({
    ...rowData,
  }));

  //redirect to the profile page
  const handleProfile = (id:any) => {
    CommonService.isAdmin() ? history.push("/master/user-detail/" + id ) : history.push("/user/user-detail/" + id);
  };

  const handleBack = () => {
    history.goBack();
  }

  return (
    <div className="usersList">
      <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
                <IconButton
                  onClick={handleBack}
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    mb: 1,
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>
        <div className="header--title mb-2 me-2">
          <h1 className="title mb-0 ms-2">
            Users <i className="bi bi-heart-fill"></i>
          </h1>
        </div>
        <div className="header--menu ms-auto mb-2">
          <div className="header--menu-items row d-flex justify-content-end">
            {/* <div className="col-auto input-container menu-items__search d-inline-flex mb-2 position-relative">
                            <input type="search" id="gsearch" name="gsearch" />
                            <img src={SearchIcon} alt="SearchIcon" className="icon position-absolute top-50 start-50 translate-middle" />
                        </div> */}
            {/* <input placeholder="Search something..." className="input" name="text" type="text"></input> */}
            <div className="col-auto mb-2">
              <SearchBar search={search} setSearch={setSearch} />
            </div>
            {/* <img src="../images/img_vert_line.svg" alt="vertline_one" className="h-[35px] md:w-full" /> */}
            <div className="col-auto menu-items__btnGroup btnGroup-border d-inline-flex mb-2">
              
              {CommonService.isAdmin() && (<InviteUser reloadTable={reloadTable} />)}

              {CommonService.isAdmin() && (<button
                className="primary-btn d-inline-flex me-1"
                onClick={handleAddUser}
              >
                <AddSharpIcon sx={{ mr: 0.5 }} />
                Add User
              </button>)}

              <div className="btn-group" role="group" aria-label="Basic mixed styles example">
                <button
                  className={`white-outline-btn d-inline-flex justify-content-between align-items-center ms-1 pe-1 rounded-end-0 ${isActiveUserClicked.current ? "bg-white" : ""
                    }`}
                  onClick={() => {
                    showActiveUsers(false);
                  }}
                >
                  Active
                  <span className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle ms-2 ${isActiveUserClicked.current ? "bg-secondary-subtle" : "bg-white"}`}>
                    {activeUserCount}
                  </span>
               </button>

              {CommonService.isAdmin() && (<button
                  className={`white-outline-btn d-inline-flex justify-content-between align-items-center pe-1 rounded-0 ${isNeedReviewClicked.current ? "bg-white" : ""
                    }`}
                  onClick={() => {
                    showSubmittedAndInvitedUsers(false);
                  }}
                >
                  Need Review
                  <span className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle ms-2 ${isNeedReviewClicked.current ? "bg-secondary-subtle" : "bg-white"}`}>
                    {reviewUserCount}
                  </span>
                </button>)}

                <button
                  className={`white-outline-btn d-inline-flex justify-content-between align-items-center pe-1  rounded-start-0 ${isRejectedUserClicked.current ? "bg-white" : ""
                    }`}
                  onClick={() => {
                    showRejectedUsers(false);
                  }}
                >
                  Rejected
                  <span className={`review--number d-inline-flex align-items-center justify-content-center rounded-circle ms-2 ${isRejectedUserClicked.current ? "bg-secondary-subtle" : "bg-white"}`}>
                    {rejectedUsersCount}
                  </span>
                </button>
              </div>

            </div>
            <div className="col-auto menu-items__btnGroup d-inline-flex mb-2">
              
             {CommonService.isAdmin() && ( <button className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                <DeleteOutlinedIcon sx={{ me: 2, color: '#1010104D' }} />
                <DeleteUser
                  deleteUser={selectionModel}
                  reloadTable={reloadTable}
                />
                <span className="review--number text-secondary d-inline-flex align-items-center justify-content-center rounded-circle bg-white ms-2">
                {selectionModel.length}
                </span>
              </button>)}
              {/* <span className="counting d-inline-flex justify-content-between align-items-center ms-2">
                {selectionModel.length}
              </span> */}
            </div>
          </div>
        </div>
      </div>
      <div className="user-table-container">
        <div className="userTable">
          <DataGrid
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 5 },
              },
            }}
            disableRowSelectionOnClick={true}
            pageSizeOptions={[5, 10]}
            localeText={localeText}
            checkboxSelection
            rowSelectionModel={selectionModel}
            onRowSelectionModelChange={handleSelectionModelChange}
            onRowClick={handleRowClick}
          //  getRowClassName={getRowClassName}
          />
        </div>
      </div>
    </div>
  );
};

export default UserList;
