import React, { useEffect, useState } from 'react';
import { Form, Button, ListGroup, Card } from 'react-bootstrap';
import { MessageApiService } from '../../Service/MessageApiService';
import { CookieService } from '../../Service/CookieService';
import { useWebSocket } from '../../Websocket/WebSocketProvider';
import { useParams } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton } from '@mui/material';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { format, isToday, isYesterday } from 'date-fns';

interface MessageBoxProps {
  user1: string;
  user2: string;
  groupRequestId: string;
  searchQuery: string;
  requestType: string;
}

const MessageBox: React.FC<MessageBoxProps> = ({ groupRequestId, user1, user2, searchQuery, requestType }) => {
  const [messages, setMessages] = useState<any[]>([]);
  const [messageContent, setMessageContent] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState<string>('user');
  const [admin, setAdmin] = useState<string>('admin');
  const userId = CookieService.getCookie("userId");
  const { id } = useParams<{ id: string }>();
  const requestId = groupRequestId;
  const { webSocketService } = useWebSocket();
  let isTodayLineDisplay = false;
  
  useEffect(() => {
    const module = 'myModule';
    const callbackMethod = (data: any) => {
      console.log('Received data:', data);
      setMessages(prevMessages => [...prevMessages, data]);
    };

    webSocketService.subscribe(module, callbackMethod);

    return () => {
      // Clean up subscriptions if necessary
    };
  }, [webSocketService]);

  useEffect(() => {
    loadMessages(groupRequestId, requestType);
  }, [groupRequestId]);

  const loadMessages = async (groupRequestId: any, requestType: any) => {
    try {
      setIsLoading(true);
      let requestId = groupRequestId
      const params = { requestId, requestType };

      const response = await MessageApiService.getActiveMessagesByRequestType(params);
      console.log(response.data);
      setMessages(Object.values(response.data));
      setIsLoading(false);
    } catch (error) {
      console.log("error fetching data" + error);
      setIsLoading(false);
    }
  };

  const filteredMessages = searchQuery !== '' ?
    messages.filter((msg: any) =>
      typeof msg.content === 'string' &&
      msg.content.trim().toLowerCase().includes(searchQuery.trim().toLowerCase())
    ) :
    messages;

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (messageContent.trim() !== '') {
      const newMessage: any = {
        requestId: groupRequestId,
        requestType: requestType,
        content: messageContent,
      };

      console.log("Sending message");

      MessageApiService.saveMessage(newMessage)

        .then(response => {
          console.log(response.data);
          // history.push(`/my-cart`);
        })
        .catch(error => {
          console.log(error);
        })

      // setMessages([...messages, newMessage]);
      setMessageContent('');
    }
  };

  const formatTimestamp = (timestamp: string) => {
    if (timestamp) {
      const date = new Date(timestamp);

      if (isToday(date)) {
        const differenceInMinutes = Math.floor((Date.now() - date.getTime()) / (1000 * 60));
        if (differenceInMinutes < 1) {
          return "now";
        } else if (differenceInMinutes < 60) {
          return `${differenceInMinutes} min`;
        } else {
          return format(date, 'hh:mm a');
        }
      } else if (isYesterday(date)) {
        return `Yesterday ${format(date, 'hh:mm a')}`;
      } else {
        return format(date, 'yyyy-MM-dd hh:mm a');
      }
    } else
      return '';
  };

  const todayLine = (timestamp: string) => {
    if (timestamp) {
      const date = new Date(timestamp);

      if (isToday(date) && !isTodayLineDisplay) {
        return isTodayLineDisplay = true;
      } 
    } 
  };

  useEffect(() => {
    setTimeout(() => {

      const messageId = 'message-' + (messages.length - 1);
      console.log(messageId)
      const messageElement: any = document.querySelector(`div[id="${messageId}"]`);

      console.log(messageElement)
      if (messageElement) {
        messageElement.focus();

        setTimeout(() => {
          messageElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 0);

      }
    });
  }, [messages]);

  return (
    <Card>
      {/* <Card.Header>Messages between {user} and {admin}</Card.Header> */}
      <Card.Body>
        <ListGroup variant="flush">
          {filteredMessages.map((message, index) => (
            <ListGroup.Item key={message.id} id={`message-${index}`}>
               {todayLine(message.timestamp) && <div className="today-line-wrapper">
                <span className='today-text'>Today</span>
                <div className='hz-line'></div>
              </div>}
               
              {message.senderId === userId ? (
                <>
                  <div className="d-flex justify-content-end">
                    <div className="sender">
                      <div className="sender__msg ms-auto">
                        <p className="mb-0">{message.content}</p>
                      </div>
                      <div className="sender__info ms-auto mt-1">
                        <p className="text-end mb-0">
                          <span className="sender__info--name me-1">{message.sendBy}</span>
                          <span className="sender__info--time me-1">{formatTimestamp(message.timestamp)}</span>
                          <span className="sender__info--status"><CheckIcon fontSize="small" /></span>
                        </p>
                      </div>
                    </div>
                    <div className="avatar">
                      <span>B</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="d-flex">
                    <div className="avatar">
                      <span>A</span>
                    </div>
                    <div className="receiver">
                      <div className="receiver__info ms-1">
                        <p className="text-start mb-0">
                          <span className="receiver__info--name me-1">{message.sendBy}</span>
                        </p>
                      </div>
                      <div className="receiver__msg">
                        <p className="mb-0">{message.content}</p>
                      </div>
                      <div className="receiver__info ms-1 mt-1">
                        <p className="text-start mb-0">
                          <span className="receiver__info--time me-1">{formatTimestamp(message.timestamp)}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </ListGroup.Item>
          ))}

        </ListGroup>
      </Card.Body>
      <Card.Footer className='border-0 bg-transparent'>
        <Form onSubmit={handleSubmit} className="send-msg position-relative">
          <Form.Group controlId="messageContent">
            <Form.Control
              type="text"
              placeholder="Type a reply..."
              value={messageContent}
              onChange={(e) => setMessageContent(e.target.value)}
            />
          </Form.Group>
          {/* <Button variant="primary" type="submit" className="mt-2">
            Send
          </Button> */}
          <IconButton type="submit" aria-label="submit" sx={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)', backgroundColor: '#FFFFFF' }}>
            <SendOutlinedIcon />
          </IconButton>
        </Form>
      </Card.Footer>
    </Card>
  );
};

export default MessageBox;
