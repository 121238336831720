import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import FavoriteBorderRoundedIcon from "@mui/icons-material/FavoriteBorderRounded";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import WhiskyBarrel from "../../assets/images/products/WhiskyBarrel.png";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { OrderApiService } from "../../Service/OrderApiService";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import EditPurpose from "../popups/EditPurpose";
import RemoveOrderItem from "../popups/RemoveOrderItem";
import Constant from "../../Constant";
import Modal from "react-bootstrap/Modal";
import { Button as BootstrapButton } from "react-bootstrap";
import ShippingCrossIcon from "../../assets/images/icons/LocalShippingCrossIcon.svg";
import AccountTransferIcon from "../../assets/images/icons/AccountTransferIcon.svg";
import AccountTransferCrossIcon from "../../assets/images/icons/AccountTransferCrossIcon.svg";
import DeleteItemIcon from "../../assets/images/icons/DeleteItem.png";
import { useHistory, useLocation } from "react-router";
import PlaceOrder from "../popups/PlaceOrder";
import { searchClient } from "../../typesenseAdapter";
import Cookies from "universal-cookie";
import { Configure, Hits, InstantSearch } from "react-instantsearch";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ListGroup from "react-bootstrap/ListGroup";
import Swal from "sweetalert2";
// import withReactContent from 'sweetalert2-react-content';
import { CommonService } from "../../Service/CommonService";
import EmptyCart from "./EmptyCart";

// Create a custom theme with adjusted breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768, // Customizing the md breakpoint to start at 992px
      lg: 992,
      xl: 1200,
    },
  },
});

const ProductImage = styled("img")({
  maxWidth: "100%",
  border: "1px solid #D9D9D9", // 1px border for the image
  borderRadius: "10px",
});

const ProductCaption = styled(Typography)({
  textAlign: "center",
  marginTop: "8px",
});

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
  },
  firstColumn: {
    minWidth: 115,
    width: "max-content",
    borderBottom: "none",
    color: "#333333",
    fontWeight: 600,
  },
  secondColumn: {
    width: "100%",
    borderBottom: "none",
    color: "#21252980",
  },
});

const MyCart: React.FC = () => {
  const classes = useStyles();
  const [count, setCount] = React.useState(1);
  const [errorMessage, setErrorMessage] = useState<{ [key: string]: string }>(
    {}
  );
  const [checked, setChecked] = React.useState([0]);
  const [addedCartItems, setCartItems] = React.useState<any>([]);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const userId = new Cookies().get("userId");
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const [isLoading, setIsLoading] = useState<Boolean>(false);
  let newErrorMessages = { ...errorMessage };
  const collectionName = process.env.REACT_APP_TYPESENSE_COLLECTIONNAME; //Typesense collection name

  const handleToggle = (value: number) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const [totalPrice, setTotalPrice] = React.useState<number>(0);
  const [totalHandlingFees, setTotalHandlingFees] = useState<number>(0);
  const [totalServiceFees, setTotalServiceFees] = useState<number>(0);
  const [subTotal, setSubTotal] = useState<number>(0);
  const [isChecked, setIsChecked] = useState(false);

  let orderItems: any;
  // orderItems = {
  //     "inventoryId": hit.inventory_id,
  //     "price": hit.price,
  //     "quantity": count,
  //     "totalPrice": hit.price * count
  // }

  const fetchData = async () => {
    try {
      const response: any = await OrderApiService.getOrderItems();
      console.log(response?.data);

      if (response?.data?.length === 0) history.push(`/user/empty-cart`);
      else {
        setIsLoading(true);
        setCartItems(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateItemDetail = (item: any) => {
    const index = addedCartItems.findIndex((data: any) => data.id === item.id);

    // If the item is found
    if (index !== -1) {
      // Create a new array with the replaced item
      const updatedItems = [...addedCartItems];
      updatedItems[index] = item;
      // Set the state to the new array
      setCartItems(updatedItems);
    }
  };

  // Function to remove an item at a specific index
  const removeItemAtIndex = (id: any) => {
    Swal.fire({
      html: `
      <style>
        .custom-swal .icon-container {
            background-color: #ffebee;
            border-radius: 50%;
            width: 105px;
            height: 105px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto 20px;
          }
          .custom-swal .icon-container i {
            color: #ff3e4e;
            font-size: 24px;
          }
          .custom-swal h2 {
            font-size: 20px;
            margin-bottom: 10px;
            margin-top: 20px;
          }
          .custom-swal p {
            font-size: 14px;
            color: #666;
            margin-bottom: 20px;
          }
          .custom-swal .button-container {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            gap: 20px;
          }
          .custom-swal button {
            border: none;
            padding: 10px 50px;
            border-radius: 30px;
            font-size: 14px;
            cursor: pointer;
          }
          .custom-swal .cancel-btn {
            background-color: #e0e0e0;
            color: #333;
          }
          .custom-swal .delete-btn {
            background-color: #ec2323;
            color: white;
          }
          
      </style>
      <div class="custom-swal">

      <div class="icon-container" id="iconContainer">
        </div>

        <h2>Are you sure?</h2>
        <p>The Bornboun Whisky will be remove from your cart.</p>
        <div class="button-container">
          <button class="cancel-btn" id="cancelBtn">Cancel</button>
          <button class="delete-btn" id="deleteBtn">Delete</button>
        </div>
      </div>
    `,
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      focusCancel: true,
      customClass: {
        popup: "custom-popup",
      },
      backdrop: `rgba(0,0,0,0.4)`,
      width: "500px",
      background: "#f8f8f8",
      didOpen: () => {
        // Add the image after the popup has been rendered
        const iconContainer = Swal.getPopup()!.querySelector("#iconContainer");
        if (iconContainer) {
          const img = document.createElement("img");
          img.src = DeleteItemIcon;
          img.width = 47;
          img.height = 51;
          iconContainer.appendChild(img);
        }

        // Add event listeners to the buttons
        const cancelBtn = Swal.getPopup()!.querySelector("#cancelBtn");
        const deleteBtn = Swal.getPopup()!.querySelector("#deleteBtn");

        cancelBtn?.addEventListener("click", () => {
          Swal.close();
        });

        deleteBtn?.addEventListener("click", () => {
          // Remove the item from the cart locally
          const index = addedCartItems.findIndex((data: any) => data.id === id);
          var array = [...addedCartItems]; // make a separate copy of the array
          if (index !== -1) {
            array.splice(index, 1);
            setCartItems(array);
          }

          //also call the api which remove the item from the cart
          OrderApiService.removeItemFromCart(id)

            .then((response) => {
              // After removing the item from the cart, show this message
              CommonService.Toast.fire({
                title: "Item successfully removed from your cart!",
                icon: "success",
              });
              
              console.log("addedCartItems.length--------" + addedCartItems.length)
              if (addedCartItems.length === 0) history.push(`/user/empty-cart`);

              console.log("success");
            })
            .catch((error) => {
              CommonService.Toast.fire({
                title: "Error occurred while removing item from your cart!",
                icon: "error",
              });
              console.log("error occured");
            });

          Swal.close();
        });
      },
    });
  };

  const handleSubmit = (item: any) => {
    console.log(item);

    orderItems = {
      id: item.id,
      orderId: item.orderId,
      quantity: item.quantity,
      totalPrice: item.totalPrice,
      purposeNote: item.purposeNote,
      handlingFees: item.handlingFees,
      serviceFees: item.serviceFees,
    };

    console.log("this is my order items" + orderItems.value);

    OrderApiService.saveOrderItems(orderItems)

      .then((response) => {
        console.log(response.data);
        // history.push(`/my-cart`);
      })
      .catch((error) => {
        console.log(error);
      });

    // closeModal();
  };

  // const saveOrderCount=()=>{
  //     console.log(count);
  // }

  // Function to update quantity locally and on server
  const updateQuantity: any = (
    itemId: any,
    newQuantity: any,
    index: number
  ) => {
    console.log("inside updateQuantity");
    console.log(
      "itemId" + itemId + "newQuantity" + newQuantity + "index" + index
    );
    // Update local state
    const updatedItems = addedCartItems.map((item: any) =>
      item.id === itemId
        ? {
            ...item,
            quantity: newQuantity,
            totalPrice: item.price * newQuantity,
            handlingFees:
              item.isRelocationRequired == true ? newQuantity * 10 : 0,
            // serviceFees: item.isAccountTransfer == true ? 10 + (item.isThirdPartyStorage ? newQuantity * 10 : 0) : 0,
            serviceFees: item.isAccountTransfer == true ? 10 : 0,
          }
        : item
    );
    setCartItems(updatedItems);

    handleSubmit(updatedItems[index]);
  };

  const updateCountFromInput: any = (
    itemId: any,
    newQuantity: any,
    index: number
  ) => {
    newQuantity = isNaN(newQuantity) ? 0 : newQuantity;

    // Update local state
    const updatedItems = addedCartItems.map((item: any) =>
      item.id === itemId
        ? {
            ...item,
            quantity: newQuantity,
            totalPrice: item.price * newQuantity,
          }
        : item
    );
    setCartItems(updatedItems);

    handleSubmit(updatedItems[index]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Effect to calculate sum whenever the items list changes
  useEffect(() => {
    // const sum = addedCartItems.reduce((acc: any, item: any) => acc + (item['totalPrice'] || 0), 0);
    // setTotalPrice(sum);
    const sum = addedCartItems.reduce(
      (acc: number, item: any) => acc + (item.totalPrice || 0),
      0
    );

    // Calculate total handling fee
    const totalHandlingFees = addedCartItems.reduce(
      (sum: any, item: any) =>
        sum + (item.isRelocationRequired ? 10 * item.quantity : 0),
      0
    );

    // Calculate total service fee
    const totalServiceFees = addedCartItems.reduce(
      // (sum: any, item: any) => sum + (item.isAccountTransfer ? 10 : 0) + (item.isThirdPartyStorage ? 10 * item.quantity : 0),
      (sum: any, item: any) => sum + (item.isAccountTransfer ? 10 : 0),
      0
    );

    // Calculate final total fee
    const finalTotalAmount = sum + totalHandlingFees + totalServiceFees;

    //set the total fee
    setTotalPrice(finalTotalAmount);

    //set the subTotal fee
    setSubTotal(sum);

    //set the total handling fee
    setTotalHandlingFees(totalHandlingFees);

    //set the total service fee
    setTotalServiceFees(totalServiceFees);
    let filters =
      addedCartItems.length > 0
        ? [
            `owner_id:!=${userId}`,
            `(inventory_id:!=${addedCartItems
              .map((item: { inventoryId: any }) => item.inventoryId)
              .join(" || inventory_id:=")})`,
          ]
        : [`owner_id:!=${userId}`];

    const filterValue = Array.isArray(filters)
      ? filters?.join(" && ").replace(/\\/g, "")
      : filters;

    setGlobalFilter(filterValue);
  }, [addedCartItems]);

  const handleAgreeToggle = () => {
    setIsChecked(!isChecked);
  };

  const placeOrder = () => {
    let placeOrder: any = {};
    placeOrder.id = addedCartItems[0].orderId;
    placeOrder.status = Constant.OrderStatus.PENDING_REVIEW;
    placeOrder.totalAmount = totalPrice;
    console.log(placeOrder);
    OrderApiService.updateOrderStatus(placeOrder).then((res) => {
      setShow(true);
    });
  };

  const handleClose = () => {
    setShow(false);
    history.push(`/user/inventories`);
  };

  function calculateWidth(
    count: number
  ): import("csstype").Property.Width<string | number> | undefined {
    const countLength = count.toString().length;
    if (countLength <= 2) {
      return "30px"; // Base width + some increment for 2 digits
    } else if (countLength === 3) {
      return "35px"; // More width for 3 or more digits
    } else if (countLength === 4) {
      return "40px"; // More width for 3 or more digits
    }
    return "45px"; // Base width for 1 digit
  }

  // Function to handle change in isAccountTransfer checkbox
  const handleAccountTransferChange = (index: number) => {
    const updatedItems = [...addedCartItems];
    updatedItems[index] = {
      ...updatedItems[index],
      isAccountTransfer:
        updatedItems[index].isAccountTransfer == true ? false : true,
    };
    setCartItems(updatedItems);
  };

  // Function to handle change in isRelocationRequired checkbox
  const handleRelocationChange = (index: number) => {
    const updatedItems = [...addedCartItems];
    updatedItems[index] = {
      ...updatedItems[index],
      isRelocationRequired:
        updatedItems[index].isRelocationRequired == true ? false : true,
    };
    setCartItems(updatedItems);
  };

  // useEffect(() => {
  //   if (!isLoading && addedCartItems.length === 0) {
  //     history.push(`/user/empty-cart`);
  //   }
  // }, [isLoading, addedCartItems, history]);
  return (
    <>
      <InstantSearch searchClient={searchClient} indexName={collectionName}>
        <Configure filters={globalFilter} hitsPerPage={5} />{" "}
        {/* Use the state variable directly here */}
        <section className="customer--view__cart">
          <div className="container-xxl">
            <div className="row d-flex justify-content-between align-items-center mb-3">
              <div className="col-auto">
                <Typography
                  variant="h5"
                  sx={{ color: "#242424", fontWeight: 700, fontSize: "24px" }}
                >
                  {isLoading == false ? "Your Cart is Loading ...." : ""}
                </Typography>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8">
                <Box
                  sx={{
                    maxHeight: "calc(100vh - 75px)",
                    overflowY: "auto",
                    overflowX: "hidden",
                    msOverflowY: "auto",
                    borderRadius: 1,
                    // Custom scrollbar styles
                    "&::-webkit-scrollbar": {
                      width: "7px", // Width of the scrollbar
                      height: "calc(100% - 20px)",
                    },
                    "&::-webkit-scrollbar-track": {
                      boxShadow: "inset 0 0 5px grey",
                      backgroundColor: "#f1f1f1", // Background color of the track
                      borderRadius: "10px",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "#97AEB8", // Color of the thumb
                      borderRadius: "10px", // Round the corners of the thumb
                    },
                    "&::-webkit-scrollbar-thumb:hover": {
                      backgroundColor: "#496673", // Color of the thumb on hover
                    },
                  }}
                >
                  <div>
                    {addedCartItems?.map((item: any, index: number) => (
                      <div className="row">
                        <List sx={{ py: 0 }}>
                          <ListItem sx={{ fontSize: "16px", pr: 3, py: 0 }}>
                            {/* <div className="card mb-3 w-100">
                                                        <div className="row g-0">
                                                            <div className="col-md-4">
                                                                <img src={CircleBarrel} className="img-fluid rounded-start" alt="CircleBarrel" />
                                                            </div>
                                                            <div className="col-md-8">
                                                                <div className="card-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-7 mb-2 mb-lg-0">
                                                                            <Typography variant="h5" sx={{ mb: 1, fontWeight: 800, color: '#1E5EFF' }}>{item?.typeSenseResponse?.product_name}</Typography>
                                                                            <div className="row">
                                                                                <div className="col-auto">
                                                                                    <Typography variant="body2" sx={{ mb: 0, fontWeight: 700, fontSize: '16px' }}>{item?.typeSenseResponse?.spirit_age_year} years {item?.typeSenseResponse?.spirit_age_month} months</Typography>
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <Typography variant="body2" sx={{ mb: 0, fontWeight: 700, fontSize: '16px' }}>{item?.typeSenseResponse?.volume.toFixed(1)} {item?.typeSenseResponse?.volume_unit}</Typography>
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <Typography variant="body2" sx={{ mb: 0, fontWeight: 700, fontSize: '16px' }}>{item?.typeSenseResponse?.mash_bill}</Typography>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row d-flex">
                                                                                <div className="col-auto mb-2">
                                                                                    <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '16px' }}>Purpose: </Typography>
                                                                                </div>
                                                                                <div className="col-9 ps-0">
                                                                                    <Typography variant="body2" sx={{ fontSize: '16px' }}>{item?.purposeNote}</Typography>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-lg-5">
                                                                            <div className="row d-flex justify-content-lg-center">
                                                                                <div className="col-auto">
                                                                                    <ButtonGroup>
                                                                                        <Button
                                                                                            aria-label="reduce"
                                                                                            onClick={() => {
                                                                                                const newQuantity = Math.max(item?.quantity - 1, 0);
                                                                                                updateQuantity(item?.id, newQuantity, index);
                                                                                            }}
                                                                                        >
                                                                                            <RemoveIcon fontSize="small" />
                                                                                        </Button>
                                                                                        <TextField
                                                                                            size="small"
                                                                                            inputProps={{
                                                                                                min: 0,
                                                                                                style: { textAlign: 'center', width: calculateWidth(item.quantity), border: '1px solid #1976D2' },
                                                                                                'aria-label': 'count'
                                                                                            }}
                                                                                            value={item.quantity}
                                                                                            onChange={(e: any) => {
                                                                                                const newCount = parseInt(e.target.value);
                                                                                                console.log(newCount);
                                                                                                if (!isNaN(newCount)) {
                                                                                                    if (newCount < 1) {
                                                                                                        updateCountFromInput(item.id, 1, index);
                                                                                                    } else if (newCount > 99999) {
                                                                                                        updateCountFromInput(item.id, 99999, index);
                                                                                                    } else {
                                                                                                        updateCountFromInput(item.id, newCount, index);
                                                                                                    }
                                                                                                } else
                                                                                                    updateCountFromInput(item.id, 1, index);
                                                                                            }}

                                                                                        />
                                                                                        <Button
                                                                                            aria-label="increase"
                                                                                            onClick={() => {
                                                                                                const newQuantity = item?.quantity + 1;
                                                                                                updateQuantity(item?.id, newQuantity, index);
                                                                                            }}
                                                                                        >
                                                                                            <AddIcon fontSize="small" />
                                                                                        </Button>
                                                                                    </ButtonGroup>
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <EditPurpose rejectUser={item} updateItemDetail={updateItemDetail} />
                                                                                </div>
                                                                                <div className="col-auto">
                                                                                    <RemoveOrderItem orderId={item.id} updateItemDetail={removeItemAtIndex} />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                            <div className="card product__card row d-flex flex-row rounded-4 mb-3 mx-0">
                              <div className="card-header d-flex flex-column flex-sm-row bg-transparent border-0">
                                <Typography
                                  variant="h5"
                                  sx={{
                                    mb: 0,
                                    my: 1,
                                    fontSize: "20px",
                                    fontWeight: 800,
                                    color: "#242424",
                                    alignSelf: "center",
                                  }}
                                >
                                  {item?.typeSenseResponse?.product_name}
                                </Typography>

                                <button
                                  className="border-0 bg-transparent ms-auto me-2"
                                  onClick={() => removeItemAtIndex(item.id)}
                                >
                                  Remove item
                                </button>

                                <div className="cart__add--btn d-inline-flex justify-content-end">
                                  <Tooltip
                                    key={item.id}
                                    title={errorMessage[item.id] || ""}
                                    arrow
                                    open={!!errorMessage[item.id]}
                                  >
                                    <ButtonGroup>
                                      <Button
                                        aria-label="reduce"
                                        onClick={() => {
                                          const newQuantity = Math.max(
                                            item?.quantity - 1,
                                            0
                                          );
                                          if (newQuantity < 1) {
                                            updateQuantity(item?.id, 1, index);
                                            delete newErrorMessages[item.id];
                                          } else {
                                            updateQuantity(
                                              item?.id,
                                              newQuantity,
                                              index
                                            );
                                            delete newErrorMessages[item.id];
                                          }

                                          setErrorMessage(newErrorMessages);
                                        }}
                                      >
                                        <RemoveIcon fontSize="small" />
                                      </Button>

                                      <TextField
                                        size="small"
                                        inputProps={{
                                          min: 0,
                                          style: {
                                            textAlign: "center",
                                            width: calculateWidth(
                                              item.quantity
                                            ),
                                          },
                                          "aria-label": "count",
                                        }}
                                        value={item.quantity}
                                        onChange={(e: any) => {
                                          const newCount = parseInt(
                                            e.target.value
                                          );
                                          if (!isNaN(newCount)) {
                                            if (newCount < 1) {
                                              updateCountFromInput(
                                                item.id,
                                                1,
                                                index
                                              );
                                              delete newErrorMessages[item.id];
                                            } else if (newCount > 99999) {
                                              updateCountFromInput(
                                                item.id,
                                                99999,
                                                index
                                              );
                                              delete newErrorMessages[item.id];
                                            } else {
                                              updateCountFromInput(
                                                item.id,
                                                newCount,
                                                index
                                              );
                                              delete newErrorMessages[item.id];
                                            }
                                          } else {
                                            updateCountFromInput(
                                              item.id,
                                              1,
                                              index
                                            );
                                            delete newErrorMessages[item.id];
                                          }
                                          setErrorMessage(newErrorMessages);
                                        }}
                                      />
                                      <Button
                                        aria-label="increase"
                                        onClick={() => {
                                          const newQuantity =
                                            item?.quantity + 1;

                                          updateQuantity(
                                            item?.id,
                                            newQuantity,
                                            index
                                          );
                                          delete newErrorMessages[item.id];

                                          setErrorMessage(newErrorMessages);
                                        }}
                                      >
                                        <AddIcon fontSize="small" />
                                      </Button>
                                    </ButtonGroup>
                                  </Tooltip>
                                </div>
                              </div>
                              <div className="card-img-wrapper col-md-4 col-xxl-4 p-2 rounded-4">
                                <img
                                  src={WhiskyBarrel}
                                  className="img-fluid object-fit-cover h-100 rounded-4 w-100"
                                  alt="WhiskyBarrel"
                                />
                              </div>
                              <div className="card-content-wrapper detail col-md-8 col-xxl-8 p-2">
                                <div className="card-body px-0">
                                  <Typography
                                    variant="h6"
                                    sx={{
                                      mb: 0,
                                      fontSize: "20px",
                                      fontWeight: 800,
                                      color: "#9E874A",
                                      lineHeight: 1,
                                    }}
                                  >
                                    <CurrencyFormatter
                                      amount={item?.price}
                                      locale="en-US"
                                      currency="USD"
                                    />
                                  </Typography>
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="d-flex flex-row">
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 0,
                                            mt: 1,
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            color: "#496673",
                                            width: "40%",
                                          }}
                                        >
                                          VOLUME
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 0,
                                            mt: 1,
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            color: "#242424",
                                            width: "60%",
                                          }}
                                        >
                                          {item?.typeSenseResponse?.volume.toFixed(
                                            1
                                          )}{" "}
                                          {item?.typeSenseResponse?.volume_unit}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="d-flex flex-row">
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 0,
                                            mt: 1,
                                            fontFamily: "Segoe UI Regular",
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            color: "#496673",
                                            width: "40%",
                                          }}
                                        >
                                          MASH BILL
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 0,
                                            mt: 1,
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            color: "#242424",
                                            width: "60%",
                                          }}
                                        >
                                          {item?.typeSenseResponse?.mash_bill}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="d-flex flex-row">
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 0,
                                            mt: 1,
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            color: "#496673",
                                            width: "40%",
                                          }}
                                        >
                                          AGE
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 0,
                                            mt: 1,
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            color: "#242424",
                                            width: "60%",
                                          }}
                                        >
                                          {
                                            item?.typeSenseResponse
                                              ?.spirit_age_year
                                          }{" "}
                                          years{" "}
                                          {
                                            item?.typeSenseResponse
                                              ?.spirit_age_month
                                          }{" "}
                                          months
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className="col-lg-6">
                                      <div className="d-flex flex-row">
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 0,
                                            mt: 1,
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            color: "#496673",
                                            width: "40%",
                                          }}
                                        >
                                          PROOF
                                        </Typography>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            mb: 0,
                                            mt: 1,
                                            fontWeight: 700,
                                            fontSize: "14px",
                                            color: "#242424",
                                            width: "60%",
                                          }}
                                        >
                                          {item?.typeSenseResponse?.proof}{" "}
                                          {item?.typeSenseResponse?.proof_unit}
                                        </Typography>
                                      </div>
                                    </div>
                                    <div className="col-12">
                                      <div className="d-flex flex-row">
                                        <ThemeProvider theme={theme}>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              mb: 0,
                                              mt: 1,
                                              fontWeight: 700,
                                              fontSize: "14px",
                                              color: "#496673",
                                              width: {
                                                xs: "40%",
                                                lg: "19.25%",
                                              },
                                            }}
                                          >
                                            PURPOSE
                                          </Typography>
                                          <Typography
                                            variant="body2"
                                            sx={{
                                              mb: 0,
                                              mt: 1,
                                              fontWeight: 700,
                                              fontSize: "14px",
                                              color: "#242424",
                                              width: {
                                                xs: "60%",
                                                lg: "79.75%",
                                              },
                                            }}
                                          >
                                            {item?.purposeNote}
                                          </Typography>
                                        </ThemeProvider>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="card-footer bg-transparent px-0">
                                  <div className="row d-flex justify-content-between m-0">
                                    <div className="col-lg-6">
                                      {item?.typeSenseResponse
                                        .is_relocation_required == true ? (
                                        <>
                                          <div className="d-flex flex-column">
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                py: 1,
                                                fontSize: "14px",
                                                color: "#9E874A",
                                                display: "inline-flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <LocalShippingOutlinedIcon
                                                sx={{ color: "#9E874A", mr: 1 }}
                                              />
                                              Relocation Required
                                            </Typography>
                                            {/* <div className="d-flex align-items-start">
                                              <Checkbox
                                                sx={{
                                                  color: "#AFAFB0",
                                                  p: 0,
                                                  mr: 1,
                                                  '&.Mui-checked': {
                                                    color: '#6750A4', 
                                                    '& .MuiSvgIcon-root': {
                                                      backgroundColor: '#FFF'
                                                    },
                                                  },
                                                }}
                                                checked={
                                                  !item.isRelocationRequired
                                                }
                                                onChange={() =>
                                                  handleRelocationChange(index)
                                                }
                                              />
                                              I would not like to relocate the
                                              barrels
                                            </div> */}
                                          </div>
                                        </>
                                      ) : (
                                        item?.typeSenseResponse
                                          .is_relocation_required == false && (
                                          <>
                                            <div className="d-flex flex-column">
                                              <Typography
                                                variant="body2"
                                                sx={{
                                                  py: 1,
                                                  fontSize: "14px",
                                                  color: "#AFAFB0",
                                                  display: "inline-flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <img
                                                  src={ShippingCrossIcon}
                                                  alt="ShippingCrossIcon"
                                                  className="me-2"
                                                  width={30}
                                                  height={19}
                                                />
                                                Relocation not Required
                                              </Typography>
                                              <div className="d-flex align-items-start">
                                                <Checkbox
                                                  sx={{
                                                    color: "#AFAFB0",
                                                    p: 0,
                                                    mr: 1,
                                                    "&.Mui-checked": {
                                                      color: "#6750A4",
                                                      "& .MuiSvgIcon-root": {
                                                        backgroundColor: "#FFF",
                                                      },
                                                    },
                                                  }}
                                                  checked={
                                                    item.isRelocationRequired
                                                  }
                                                  onChange={() =>
                                                    handleRelocationChange(
                                                      index
                                                    )
                                                  }
                                                />
                                                I would like to relocate the
                                                barrels
                                              </div>
                                            </div>
                                          </>
                                        )
                                      )}
                                    </div>

                                    <div className="col-lg-6">
                                      {item?.typeSenseResponse
                                        .is_account_transfer == true ? (
                                        <>
                                          <div className="d-flex flex-column">
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                py: 1,
                                                fontSize: "14px",
                                                color: "#9E874A",
                                                display: "inline-flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={AccountTransferIcon}
                                                alt="ShippingCrossIcon"
                                                className="me-2"
                                                width={28}
                                                height={24}
                                              />
                                              Change of Account Required
                                            </Typography>
                                            {/* <div className="d-flex align-items-start">
                                              <Checkbox
                                                sx={{
                                                  color: "#AFAFB0",
                                                  p: 0,
                                                  mr: 1,
                                                  '&.Mui-checked': {
                                                    color: '#6750A4', 
                                                    '& .MuiSvgIcon-root': {
                                                      backgroundColor: '#FFF'
                                                    },
                                                  },
                                                }}
                                                checked={!item.isAccountTransfer}
                                                onChange={() =>
                                                  handleAccountTransferChange(index)
                                                }
                                              />
                                              I would not like to change the account
                                            </div> */}
                                          </div>
                                        </>
                                      ) : (
                                        <>
                                          <div className="d-flex flex-column">
                                            <Typography
                                              variant="body2"
                                              sx={{
                                                py: 1,
                                                fontSize: "14px",
                                                color: "#AFAFB0",
                                                display: "inline-flex",
                                                alignItems: "center",
                                              }}
                                            >
                                              <img
                                                src={AccountTransferCrossIcon}
                                                alt="ShippingCrossIcon"
                                                className="me-2"
                                                width={23}
                                                height={23}
                                              />
                                              Change of Account not Required
                                            </Typography>
                                            <div className="d-flex align-items-start">
                                              <Checkbox
                                                sx={{
                                                  color: "#AFAFB0",
                                                  p: 0,
                                                  mr: 1,
                                                  "&.Mui-checked": {
                                                    color: "#6750A4",
                                                    "& .MuiSvgIcon-root": {
                                                      backgroundColor: "#FFF",
                                                    },
                                                  },
                                                }}
                                                checked={item.isAccountTransfer}
                                                onChange={() =>
                                                  handleAccountTransferChange(
                                                    index
                                                  )
                                                }
                                              />
                                              I would like to change the account
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    {/* <div className="col-auto ms-auto px-1">
                                                                            <RemoveOrderItem orderId={item.id} updateItemDetail={removeItemAtIndex} />
                                                                        </div>
                                                                        <div className="col-auto px-1">
                                                                            <EditPurpose rejectUser={item} updateItemDetail={updateItemDetail} />
                                                                        </div> */}

                                    {/* <div className="col-auto">
                                                            <ButtonGroup>
                                                                <Button
                                                                    aria-label="reduce"
                                                                    onClick={() => {
                                                                        const newQuantity = Math.max(item?.quantity - 1, 0);
                                                                        updateQuantity(item?.id, newQuantity, index);
                                                                    }}
                                                                >
                                                                    <RemoveIcon fontSize="small" />
                                                                </Button>

                                                                <TextField
                                                                    size="small"
                                                                    inputProps={{
                                                                        min: 0,
                                                                        style: { textAlign: 'center', width: calculateWidth(item.quantity), border: '1px solid #1976D2' },
                                                                        'aria-label': 'count'
                                                                    }}
                                                                    value={item.quantity}
                                                                    onChange={(e: any) => {
                                                                        const newCount = parseInt(e.target.value);
                                                                        console.log(newCount);
                                                                        if (!isNaN(newCount)) {
                                                                            if (newCount < 1) {
                                                                                updateCountFromInput(item.id, 1, index);
                                                                            } else if (newCount > 99999) {
                                                                                updateCountFromInput(item.id, 99999, index);
                                                                            } else {
                                                                                updateCountFromInput(item.id, newCount, index);
                                                                            }
                                                                        } else
                                                                            updateCountFromInput(item.id, 1, index);
                                                                    }}

                                                                />
                                                                <Button
                                                                    aria-label="increase"
                                                                    onClick={() => {
                                                                        const newQuantity = item?.quantity + 1;
                                                                        updateQuantity(item?.id, newQuantity, index);
                                                                    }}
                                                                >
                                                                    <AddIcon fontSize="small" />
                                                                </Button>
                                                            </ButtonGroup>
                                                        </div>
                                                        <div className="col-auto">
                                                            <EditPurpose rejectUser={item} updateItemDetail={updateItemDetail} />
                                                        </div>
                                                        <div className="col-auto">
                                                            <RemoveOrderItem orderId={item.id} updateItemDetail={removeItemAtIndex} />
                                                        </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </ListItem>
                        </List>
                      </div>
                    ))}
                  </div>
                </Box>
                {/* Testing START */}

                {/* Testing END */}
                {/* <div className="row">
                                <List sx={{ bgcolor: 'background.paper' }}>
                                    <ListItem sx={{ fontSize: '16px' }}>
                                        <div className="row d-flex w-100">
                                            <div className="col-2">
                                                <div className="row">
                                                    <img src={BourbonWhiskyBarrel} alt="BourbonWhiskyBarrel" />
                                                </div>
                                            </div>
                                            <div className="col-10">
                                                <div className="row">
                                                    <div className="col-lg-7 mb-2 mb-lg-0">
                                                        <Typography variant="h5" sx={{ mb: 1, fontWeight: 800, color: '#1E5EFF' }}>Bourbon Whisky</Typography>
                                                        <div className="row">
                                                            <div className="col-auto">
                                                                <Typography variant="body2" sx={{ mb: 0, fontWeight: 700, fontSize: '16px' }}>2+ Years Old</Typography>
                                                            </div>
                                                            <div className="col-auto">
                                                                <Typography variant="body2" sx={{ mb: 0, fontWeight: 700, fontSize: '16px' }}>53 Gal</Typography>
                                                            </div>
                                                            <div className="col-auto">
                                                                <Typography variant="body2" sx={{ mb: 0, fontWeight: 700, fontSize: '16px' }}>C(60%) / R(33%) / W(0%) / MB (7%)</Typography>
                                                            </div>
                                                        </div>
                                                        <div className="row d-flex">
                                                            <div className="col-auto mb-2">
                                                                <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '16px' }}>Purpose: </Typography>
                                                            </div>
                                                            <div className="col-9 ps-0">
                                                                <Typography variant="body2" sx={{ fontSize: '16px' }}>For investment purposes and to build a collection of rare and sought-after bottles</Typography>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-5">
                                                        <div className="row d-flex justify-content-lg-center">
                                                            <div className="col-auto">
                                                                <ButtonGroup>
                                                                    <Button
                                                                        aria-label="reduce"
                                                                        onClick={() => {
                                                                            setCount(Math.max(count - 1, 0));
                                                                        }}
                                                                    >
                                                                        <RemoveIcon fontSize="small" />
                                                                    </Button>
                                                                    <Button>
                                                                        {count}
                                                                    </Button>
                                                                    <Button
                                                                        aria-label="increase"
                                                                        onClick={() => {
                                                                            setCount(count + 1);
                                                                        }}
                                                                    >
                                                                        <AddIcon fontSize="small" />
                                                                    </Button>
                                                                </ButtonGroup>
                                                            </div>
                                                            <div className="col-auto">
                                                                <Button variant="text"><DriveFileRenameOutlineOutlinedIcon sx={{ mr: 1 }} />Edit</Button>
                                                            </div>
                                                            <div className="col-auto">
                                                                <Button variant="text"><DeleteOutlinedIcon sx={{ mr: 1 }} />Remove</Button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ListItem>
                                </List>
                            </div> */}
              </div>
              {addedCartItems.length > 0 && (
                <div className="col-lg-4">
                  <div className="order__summary--card card border-0 h-100">
                    <div className="card-header py-3 bg-white">
                      <Typography
                        variant="h5"
                        sx={{
                          mb: 0,
                          color: "#9E874A",
                          fontWeight: 600,
                          fontSize: "16px",
                        }}
                      >
                        Order Summary
                      </Typography>
                    </div>
                    {/* <div className="card-body">
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-auto">
                                                <Typography variant="body1" sx={{ my: 1, color: '#333333', fontWeight: 600, fontSize: '16px' }}>
                                                    Bournbon Whisky
                                                </Typography>
                                            </div>
                                            <div className="col-auto">
                                                <Typography variant="body1" sx={{ my: 1, color: '#333333', fontWeight: 600, fontSize: '16px' }}>
                                                    Subtotal
                                                </Typography>
                                            </div>
                                            <div className="col-auto">
                                                <Typography variant="body1" sx={{ my: 1, color: '#333333', fontWeight: 400, fontSize: '16px' }}>
                                                    <CurrencyFormatter amount={totalPrice} locale="en-US" currency="USD" />
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-auto">
                                                <Typography variant="body1" sx={{ my: 1, color: '#333333', fontWeight: 600, fontSize: '16px' }}>
                                                    Estimated shipping
                                                </Typography>
                                            </div>
                                            <div className="col-auto">
                                                <Typography variant="body1" sx={{ my: 1, color: '#333333', fontWeight: 400, fontSize: '16px' }}>
                                                    $200
                                                </Typography>
                                            </div>
                                        </div>
                                        <div className="row d-flex justify-content-between border-top">
                                            <div className="col-auto">
                                                <Typography variant="body1" sx={{ mt: 2, color: '#333333', fontWeight: 700, fontSize: '20px' }}>
                                                    Order Total
                                                </Typography>
                                            </div>
                                            <div className="col-auto">
                                                <Typography variant="body1" sx={{ mt: 2, color: '#333333', fontWeight: 700, fontSize: '20px' }}>
                                                    <CurrencyFormatter amount={totalPrice + 200} locale="en-US" currency="USD" />
                                                </Typography>
                                            </div>
                                            <div className="col-12">
                                                <Typography variant="caption" display="block" sx={{ my: 0, color: '#333333', fontWeight: 400, fontSize: '12px' }}>
                                                    The price does not include sales tax and shipping costs.
                                                </Typography>
                                            </div>
                                        </div>
                                    </div> */}

                    <div className="card-body p-0">
                      {addedCartItems.map((item: any, index: number) => (
                        <ListGroup variant="flush" key={index}>
                          <ListGroup.Item className="border-bottom">
                            {/* Item details */}
                            <div className="row d-flex justify-content-between">
                              <div className="col-auto">
                                <Typography
                                  variant="body1"
                                  sx={{
                                    mt: 1,
                                    color: "#333333",
                                    fontWeight: 600,
                                    fontSize: "15px",
                                  }}
                                >
                                 {item?.typeSenseResponse?.product_name}
                                </Typography>
                                <Typography
                                  variant="body1"
                                  sx={{
                                    color: "#333333",
                                    fontSize: "14px",
                                  }}
                                >
                                  {item.quantity} x{" "}
                                  <CurrencyFormatter
                                    amount={item.price}
                                    locale="en-US"
                                    currency="USD"
                                  />
                                </Typography>
                              </div>
                              {/* <div className="col-auto">
                        </div> */}
                              <div className="col-auto">
                                <Typography
                                  variant="body1"
                                  sx={{
                                    my: 1,
                                    color: "#333333",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                  }}
                                >
                                  <CurrencyFormatter
                                    amount={item.totalPrice}
                                    locale="en-US"
                                    currency="USD"
                                  />
                                </Typography>
                              </div>
                            </div>

                            {/* Handling Fees */}
                            <div className="row d-flex justify-content-between">
                              <div className="col-auto">
                                <Typography
                                  variant="body1"
                                  sx={{
                                    my: 1,
                                    color: "#333333",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                  }}
                                >
                                  Handling Fees
                                </Typography>
                              </div>
                              <div className="col-auto">
                                <Typography
                                  variant="body1"
                                  sx={{
                                    my: 1,
                                    color: "#333333",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                  }}
                                >
                                  $
                                  {item.isRelocationRequired
                                    ? 10 * item.quantity
                                    : 0}
                                </Typography>
                              </div>
                            </div>

                            {/* Service Fees */}
                            <div className="row d-flex justify-content-between">
                              <div className="col-auto">
                                <Typography
                                  variant="body1"
                                  sx={{
                                    my: 1,
                                    color: "#333333",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                  }}
                                >
                                  Service Fees
                                </Typography>
                              </div>
                              <div className="col-auto">
                                <Typography
                                  variant="body1"
                                  sx={{
                                    my: 1,
                                    color: "#333333",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                  }}
                                >
                                  {/* ${(item.isAccountTransfer ? 10 : 0) + (item.isThirdPartyStorage ? 10 * item.quantity : 0)} */}
                                  ${item.isAccountTransfer ? 10 : 0}
                                </Typography>
                              </div>
                            </div>
                          </ListGroup.Item>
                        </ListGroup>
                      ))}
                      <ListGroup
                        variant="flush"
                        className="total-price-sec border-top border-bottom border-dark-subtle"
                      >
                        <ListGroup.Item className="border-0">
                          <div className="row d-flex justify-content-between">
                            <div className="col-auto">
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#333333",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                Subtotal
                              </Typography>
                            </div>
                            <div className="col-auto">
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#333333",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                }}
                              >
                                <CurrencyFormatter
                                  amount={subTotal}
                                  locale="en-US"
                                  currency="USD"
                                />
                              </Typography>
                            </div>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          <div className="row d-flex justify-content-between">
                            <div className="col-auto">
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#333333",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                Total Handling Fees
                              </Typography>
                            </div>
                            <div className="col-auto">
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#333333",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                }}
                              >
                                <CurrencyFormatter
                                  amount={totalHandlingFees}
                                  locale="en-US"
                                  currency="USD"
                                />
                              </Typography>
                            </div>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          <div className="row d-flex justify-content-between">
                            <div className="col-auto">
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#333333",
                                  fontWeight: 600,
                                  fontSize: "16px",
                                }}
                              >
                                Total Service Fees
                              </Typography>
                            </div>
                            <div className="col-auto">
                              <Typography
                                variant="body1"
                                sx={{
                                  color: "#333333",
                                  fontWeight: 600,
                                  fontSize: "15px",
                                }}
                              >
                                <CurrencyFormatter
                                  amount={totalServiceFees}
                                  locale="en-US"
                                  currency="USD"
                                />
                              </Typography>
                            </div>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                    </div>
                    <div className="card-footer bg-white border-0 p-0">
                      <ListGroup variant="flush">
                        <ListGroup.Item className="pb-0 border-0">
                          <div className="row d-flex justify-content-between">
                            <div className="col-auto">
                              <Typography
                                variant="body1"
                                sx={{
                                  mt: 1,
                                  color: "#333333",
                                  fontWeight: 700,
                                  fontSize: "20px",
                                }}
                              >
                                Order Total
                              </Typography>
                            </div>
                            <div className="col-auto">
                              <Typography
                                variant="body1"
                                sx={{
                                  mt: 1,
                                  color: "#333333",
                                  fontWeight: 700,
                                  fontSize: "20px",
                                }}
                              >
                                <CurrencyFormatter
                                  amount={totalPrice}
                                  locale="en-US"
                                  currency="USD"
                                />
                              </Typography>
                            </div>
                          </div>
                        </ListGroup.Item>
                        <ListGroup.Item className="py-0 border-0">
                          <Typography
                            variant="caption"
                            display="block"
                            sx={{
                              my: 0,
                              color: "#333333",
                              fontWeight: 400,
                              fontSize: "12px",
                            }}
                          >
                            The price does not include shipping costs.
                          </Typography>
                        </ListGroup.Item>
                        <ListGroup.Item className="border-0">
                          <div className="row d-flex justify-content-start align-items-center mt-2">
                            <Typography
                              variant="body2"
                              sx={{ fontSize: "16px" }}
                              onClick= {handleAgreeToggle}
                            >
                              <Checkbox
                                checked={isChecked}
                                sx={{
                                  p: 0,
                                  mr: 1,
                                  "&.Mui-checked": {
                                    color: "#6750A4",
                                    "& .MuiSvgIcon-root": {
                                      backgroundColor: "#FFF",
                                    },
                                  },
                                }}
                              />
                              I agree to{" "}
                              <a
                                href="/terms"
                                style={{
                                  color: "#E15A0E",
                                  textDecoration: "underline",
                                }}
                              >
                                Terms & Condition
                              </a>
                              .
                            </Typography>
                          </div>
                        </ListGroup.Item>
                      </ListGroup>
                      <div className="d-flex justify-content-center mb-3 py-2">
                        <PlaceOrder
                          totalPrice={totalPrice}
                          addedCartItems={addedCartItems}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* <div className="col-lg-12 mt-5">
                                <div className='border border-secondary-subtle p-2 h-100'>
                                    <div className="row d-flex justify-content-between align-items-center">
                                        <div className="col-auto">
                                            <Typography variant="h5" sx={{ mb: 2 }}>
                                                Summary
                                            </Typography>
                                        </div>
                                    </div>
                                    <div className="row px-3">
                                        <List sx={{ bgcolor: 'transparent', borderBottom: '1px solid #C4C8CB' }}>
                                            {addedCartItems.map((item: any, index: number) => (
                                                <ListItem sx={{ pr: 0 }}>
                                                    <div className="row d-flex justify-content-between align-items-center w-100">
                                                        <div className="col-auto">
                                                            <div className="row d-flex align-items-center">
                                                                <div className="col-auto">
                                                                    <div className="row d-flex flex-column">
                                                                        <div className="col-auto">
                                                                            <Typography variant="body1" component="span" sx={{ mb: 2 }}>
                                                                                Bourbon Whisky
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <Typography variant="body1" component="span">
                                                                                <CurrencyFormatter amount={item.price} locale="en-US" currency="USD" />  / barrel
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-auto">
                                                                    <div className="row d-flex">
                                                                        <div className="col-auto">
                                                                            <Typography variant="body1" component="span">
                                                                                <CloseOutlinedIcon />
                                                                            </Typography>
                                                                        </div>
                                                                        <div className="col-auto">
                                                                            <Typography variant="body1" component="span">
                                                                                {item.quantity}
                                                                            </Typography>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-auto">
                                                            <Typography variant="body1">
                                                                <CurrencyFormatter amount={item.totalPrice} locale="en-US" currency="USD" />
                                                            </Typography>
                                                        </div>
                                                    </div>
                                                </ListItem>
                                            ))}
                                        </List>
                                    </div>
                                    <div className="row d-flex justify-content-end p-2">
                                        <div className="col-auto">
                                            <Typography variant="h6">Total Price</Typography>
                                        </div>
                                        <div className="col-auto">
                                            <Typography variant="h6"> <CurrencyFormatter amount={totalPrice} locale="en-US" currency="USD" /></Typography>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-end px-2">
                                        <div className="col-auto">
                                            <Typography variant="body2">Ownership Transfer Fee and Transport Fee are excluded</Typography>
                                        </div>
                                    </div>
                                    <div className="row d-flex justify-content-center py-3">
                                        <div className="col-auto">
                                            <PlaceOrder totalPrice={totalPrice} addedCartItems={addedCartItems} />
                                        </div>
                                    </div>
                                </div>
                            </div> */}
            </div>
          </div>
          {/* <div className="container border-2 border-top pt-5 mt-5">
            <div className="row mx-0">
              <div className="col-12">
                <Typography
                  variant="h5"
                  sx={{ color: "#9E874A", fontWeight: 700, fontSize: "24px" }}
                >
                  Also consider these barrels
                </Typography>
              </div>
            </div>
            <div className="grid-view inventory__grid-view barrel__suggestion-grid">
              <Hits hitComponent={HitItem} />
            </div>
          </div> */}
        </section>
      </InstantSearch>
      <Modal show={show} onHide={handleClose} className="modal__wd">
        <Modal.Header className="flex-column align-items-start">
          <p className="modal-text text-start"></p>
        </Modal.Header>
        <Modal.Footer className="justify-content-center bg-transparent">
          <div className="col-12">
            <button className="secondary-btn w-100" onClick={handleClose}>
              Close
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
// const HitItem: React.FC<{ hit: any }> = ({ hit }) => {
//   return (
//     <div className="grid-item">
//       <div className="card">
//         <img src={WhiskyBarrel} className="card-img-top" alt="Whisky Barrel" />
//         <div className="card-body">
//           <div className="card-title">
//             <h5>
//               {hit.ttb_type}
//               {/* <span>{hit.mash_bill}</span> */}
//             </h5>
//           </div>
//           <div className="card-text d-flex justify-content-between">
//             <div className="grid-cell">
//               {hit.price}
//               <span>/ Barrel</span>
//             </div>
//             <div className="grid-cell">
//               <FavoriteIcon sx={{ cursor: "pointer", color: "red" }} />
//             </div>
//           </div>
//           <div className="details">
//             <div className="grid-cell">
//               <span>
//                 {hit.spirit_age_year} years {hit.spirit_age_month} months
//               </span>
//               {/* <span>Years Old</span> */}
//             </div>
//           </div>
//           <div className="details">
//             <div className="grid-cell">
//               <span>{hit.mash_bill}</span>
//             </div>
//           </div>
//           <div className="details">
//             <div className="grid-cell">
//               <span>{hit.volume} Gallon</span>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

export default MyCart;
