import "../../App.css";
import { CookieService } from "../../Service/CookieService";
import Cookies from "universal-cookie";
import { useHistory, useLocation } from "react-router";
import { useEffect, useState } from "react";
import Logo from "../Logo/Logo";
import ProfileBar from "../Static/Profile/ProfileBar";
import { Link } from "react-router-dom";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import MessageNotification from "./MessageNotification";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

const UserHeader = () => {
  const cookies = new Cookies();
  const history = useHistory();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string | null>(null);
  const [userRole, setUserRole] = useState<string | null>(null);
  const currentPath = location.pathname;

  const logout = () => {
    CookieService.removeAuthToken();
    history.push("/login");
  };

  // useEffect(() => {
  //     // Initialize Bootstrap components when the component mounts
  //     const dropdownElementList = [].slice.call(document.querySelectorAll('.dropdown-toggle'));
  //     dropdownElementList.map(function (dropdownToggleEl) {
  //       return new window.bootstrap.Dropdown(dropdownToggleEl);
  //     });
  //   }, []);

  useEffect(() => {
    const fetchUserRole = () => {
      const cookies = new Cookies();
      const role = cookies.get("userRole");
      setUserRole(role);
    };

    const fetchCurrentPath = () => {
      // Update the activeTab state based on the current pathname
      if (currentPath === "/master/dashboard") {
        setActiveTab("dashboard");
      } else if (currentPath === "/user/my-warehouse") {
        setActiveTab("dashboard");
      } else if (currentPath === "/inventories") {
        setActiveTab("inventories");
      } else if (currentPath === "/my-inventory") {
        setActiveTab("my-inventory");
      } else if (currentPath === "/inventory-sell-requests") {
        setActiveTab("inventory-sell-requests");
      } else if (currentPath === "/login-history") {
        setActiveTab("login-history");
      } else if (currentPath.includes("/view-csv")) {
        setActiveTab("view-csv");
      } else if (currentPath.includes("/view-sell_request")) {
        setActiveTab("view-sell-request");
      } else if (currentPath.includes("/my-cart")) {
        setActiveTab("my-cart");
      } else {
        setActiveTab("");
      }
    };

    fetchUserRole();

    fetchCurrentPath();
  }, [location.pathname]);

  function route(route: string) {
    history.push(route);
  }

  function historyBack() {
    console.log("historyBack")
    if (
      currentPath == "/user/empty-cart" ||
      currentPath.startsWith("/user/empty-cart")
    ) {
      history.push(`/user/inventories`);
    } else history.goBack();
  }

  return (
    <header className="wd__header wd__header--user">
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <Logo size={"200px"} />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul
              className="navbar-nav ms-auto mb-2 mb-lg-0"
              style={{ cursor: "pointer" }}
            >
              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/inventories")}
                >
                  Home
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/wish-list")}
                >
                  My Wishlist
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/messages")}
                  role="button"
                  aria-expanded="false"
                >
                  Inbox
                </a>
              </li>

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/my-requests")}
                  role="button"
                  aria-expanded="false"
                >
                  My Requests
                </a>
              </li>

              {/* {userRole === "ADMIN" && (
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    My Requests
                    <ExpandMoreRoundedIcon />
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/user/active-order")}
                      >
                        Order Requests
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/user/sample-request")}
                      >
                        Sample Requests
                      </a>
                    </li>
                    <li>
                      <a
                        className="dropdown-item"
                        onClick={() => route("/user/offer-request")}
                      >
                        Offer Requests
                      </a>
                    </li>
                  </ul>
                </li>
              )} */}

              <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/my-warehouse")}
                  role="button"
                  aria-expanded="false"
                >
                  My Warehouse
                </a>
              </li>

              {/* <li className="nav-item">
                <a
                  className="nav-link"
                  aria-current="page"
                  onClick={() => route("/user/my-inventory")}
                >
                  My Inventories
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <div className="header__banner">
        <div className="d-flex flex-column flex-sm-row justify-content-between align-items-sm-center">
          <div className="d-inline-flex">
            {currentPath !== "/user/inventories" && (
              <div
                className="back__button me-3 d-flex align-items-center"
                onClick={() => historyBack()}
              >
                <IconButton
                  aria-label="ArrowBackIcon"
                  sx={{
                    backgroundColor: "#FFF",
                    mb: 1,
                    "&:hover": { backgroundColor: "#FFF" },
                  }}
                >
                  <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
                </IconButton>
                {(currentPath == "/user/active-order" ||
                  currentPath.startsWith("/user/order-detail")) && (
                  <h3 className="ms-2 fw-bold text-white">My orders</h3>
                )}

                {(currentPath == "/user/my-warehouse/active-order" 
                // ||
                //   currentPath.startsWith("/user/order-detail")
                  ) && (
                  <h3 className="ms-2 fw-bold text-white">Order Request</h3>
                )}

                {(currentPath == "/user/sample-request" ||
                  currentPath.startsWith("/user/view-sample-request")) && (
                  <h3 className="ms-2 fw-bold text-white">
                    My Sample Requests
                  </h3>
                )}

                {(currentPath == "/user/my-warehouse/sample-request" 
                // ||
                //   currentPath.startsWith("/user/view-sample-request")
                  ) && (
                  <h3 className="ms-2 fw-bold text-white">
                    Sample Request
                  </h3>
                )}
                {(currentPath == "/user/offer-request" ||
                  currentPath.startsWith("/user/view-offer-request")) && (
                  <h3 className="ms-2 fw-bold text-white">My Offer Requests</h3>
                )}
                {(currentPath == "/user/my-requests" ||
                  currentPath.startsWith("/my-requests")) && (
                  <h3 className="ms-2 fw-bold text-white">My Requests</h3>
                )}
              </div>
            )}
            {/* {userRole == "ADMIN" && (
              <div className="header--title">
                <h1 className="title text-white fs-2 fw-semibold mb-0">
                  My Inventory
                  <i className="bi bi-heart-fill"></i>
                </h1>
              </div>
            )} */}
          </div>
          <ProfileBar />
        </div>
      </div>

      {/* <div>
    
                <div className="logout"> 
                    <Button variant="contained" size="small" onClick={logout}>Logout</Button>
                </div>
            </div> */}
    </header>
  );
};

export default UserHeader;
