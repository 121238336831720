import { Redirect, Route } from "react-router-dom";
import  Cookies from 'universal-cookie';

const PrivateRoute = (props:any) => {
  const cookies = new Cookies();
  const token = cookies.get('authToken');

  return <>{(token!== null && token!== undefined) ? <Route {...props} /> : (<Redirect to="/login" />)}</>;
};

export default PrivateRoute;
