import React, { useEffect, useState, useCallback, useRef } from "react";
import notificationWebSocketService from "../../Websocket/NotificationWebSocketService";
import MarkunreadOutlinedIcon from "@mui/icons-material/MarkunreadOutlined";
import DraftsOutlinedIcon from "@mui/icons-material/DraftsOutlined";
import { SvgIcon, Typography } from "@mui/material";
import Dropdown from "react-bootstrap/Dropdown";
import NotificationsNoneRoundedIcon from "@mui/icons-material/NotificationsNoneRounded";
import { CommonService } from "../../Service/CommonService";
import { OrderApiService } from "../../Service/OrderApiService";

interface Notification {
  id: string;
  createdBy: string;
  createdDate: string;
  inventoryName: string | null;
  inventorySellRequestId: string | null;
  isActive: boolean;
  isDelete: boolean;
  isSeen: boolean;
  modifiedBy: string;
  modifiedDate: string;
  orderRequestId: string | null;
  orderStatus: string;
  purchaseOfferRequestId: string | null;
  quantity: number | null;
  requestType: string;
  sampleRequestId: string | null;
  userId: string;
  fileName: string;
}

interface NotificationComponentProps {
  userId: string;
}

const NotificationComponent: React.FC<NotificationComponentProps> = ({
  userId,
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [notificationCount, setNotificationCount] = useState<number>(0);
  const reconnectTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const handleDropdownToggle = async (isOpen: boolean) => {
    setIsDropdownOpen(isOpen);
    if (!isOpen) {
      if (notificationCount > 0) {
        await OrderApiService.updateIsSeenFlag(userId);
        fetchAllNotifications();
      }
      setNotificationCount(0);
    }
  };

  const handleNotification = useCallback(
    (data: string | object) => {
      try {
        console.log("Raw notification data:", data);
        const newNotification: Notification =
          typeof data === "string" ? JSON.parse(data) : (data as Notification);
        console.log("Parsed notification data:", newNotification);

        //add notification
        setNotifications((prevNotifications) => [
          newNotification,
          ...prevNotifications,
        ]);

        //increase the notification count
        setNotificationCount((prevCount) => prevCount + 1);
      } catch (error) {
        console.error("Error handling notification:", error);
      }
    },
    [isDropdownOpen]
  );

  const subscribeToNotifications = useCallback(() => {
    notificationWebSocketService.subscribeToUserChannel(
      userId,
      handleNotification
    );
  }, [userId, handleNotification]);

  const connectWebSocket = useCallback(() => {
    if (
      notificationWebSocketService.client &&
      notificationWebSocketService.client.connected
    ) {
      if (!isConnected) {
        setIsConnected(true);
        subscribeToNotifications();
      }
    } else {
      notificationWebSocketService.connect(
        () => {
          setIsConnected(true);
          subscribeToNotifications();
        },
        () => {
          setIsConnected(false);
          // Attempt to reconnect after 5 seconds
          reconnectTimeoutRef.current = setTimeout(connectWebSocket, 1000);
        }
      );
    }
  }, [subscribeToNotifications, isConnected]);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
    };
  }, [connectWebSocket]);

  const getUnseenMessageCount = async () => {
    try {
      const response = await OrderApiService.getUnseenMessageCount(userId);
      console.log("This is your notification count: " + response.data);
      setNotificationCount(response.data);
    } catch (error) {
      console.log(
        "Error occurring while getting count of unseen messages",
        error
      );
    }
  };

  const fetchAllNotifications = async () => {
    try {
      const response = await OrderApiService.getAllNotifications(userId);
      console.log("These are your notifications: ", response);
      setNotifications(response.data);
    } catch (error) {
      console.log("Error occurring while fetching all notifications", error);
    }
  };

  useEffect(() => {
    getUnseenMessageCount();
    fetchAllNotifications();
  }, []);

  const renderNotificationMessage = (notification: Notification): string => {
    const { requestType, orderStatus, quantity, inventoryName, fileName } =
      notification;

    switch (requestType) {
      case "ORDER_REQUEST":
        return `Order request for ${quantity} barrels is ${orderStatus
          ?.toLowerCase()
          .replace("_", " ")}.`;
      case "SELL_REQUEST":
        return `Inventory sell request for '${fileName?.slice(
          4
        )}' is ${orderStatus?.toLowerCase().replace("_", " ")}.`;
      case "SAMPLE_REQUEST":
        return `Sample request for ${
          inventoryName || "unknown inventory"
        } is ${orderStatus?.toLowerCase().replace("_", " ")}.`;
      case "OFFER_REQUEST":
        return `Offer request for ${
          inventoryName || "unknown inventory"
        } is ${orderStatus?.toLowerCase().replace("_", " ")}.`;
      default:
        return `Request of type ${requestType} is ${orderStatus
          ?.toLowerCase()
          .replace("_", " ")}.`;
    }
  };

  return (
    <Dropdown onToggle={handleDropdownToggle}>
      <Dropdown.Toggle
        variant="basic"
        className="rounded-circle"
        id="dropdown-basic"
      >
        <NotificationsNoneRoundedIcon />
        {notificationCount > 0 && (
          <span
            className="msg-count"
            aria-label={`${notificationCount} notifications`}
          >
            {notificationCount}
          </span>
        )}
      </Dropdown.Toggle>

      <Dropdown.Menu align="start">
        {notifications.length === 0 ? (
          <Dropdown.Item disabled>No notifications</Dropdown.Item>
        ) : (
          notifications.map((notification, index) => (
            <React.Fragment key={notification.id}>
              <Dropdown.Item href="#/action-1">
                <div className="notification-msg-wrapper d-flex flex-column flex-sm-row w-100">
                  <div className="notification-msg d-inline-flex me-3">
                    {!notification.isSeen && (
                      <SvgIcon
                        component={MarkunreadOutlinedIcon}
                        sx={{
                          color: "#000000",
                          marginRight: "8px",
                        }}
                      />
                    )}

                    {notification.isSeen && (
                      <SvgIcon
                        component={DraftsOutlinedIcon}
                        sx={{
                          color: "#A1A1A1",
                          marginRight: "8px",
                        }}
                      />
                    )}

                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: notification.isSeen ? 400 : 700,
                        fontSize: "14px",
                        maxWidth: "275px",
                        textWrap: "wrap",
                        wordWrap: "break-word",
                        "&:hover": { color: "#191f25" },
                      }}
                    >
                      {renderNotificationMessage(notification)}
                    </Typography>
                  </div>
                  <div className="notification-time d-inline-flex flex-sm-column align-items-end ms-auto">
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#A1A1A1",
                        fontWeight: 600,
                        fontSize: "13px",
                      }}
                    >
                      {CommonService.formatDate(notification.modifiedDate)}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#A1A1A1",
                        fontWeight: 600,
                        fontSize: "13px",
                        marginLeft: "6px",
                      }}
                    >
                      {CommonService.formatTime(notification.modifiedDate)}
                    </Typography>
                  </div>
                </div>
              </Dropdown.Item>
              {index < notifications.length - 1 && <Dropdown.Divider />}
            </React.Fragment>
          ))
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default React.memo(NotificationComponent);
