import React, { createContext, useContext, useEffect, useState } from 'react';
import webSocketService from './websocketService';

const WebSocketContext = createContext();

export const WebSocketProvider = ({ children, requestId }) => {
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    if (requestId) {
      webSocketService.connect(requestId);
      setConnected(true);

      return () => {
        webSocketService.disconnect();
        setConnected(false);
      };
    }
  }, [requestId]);

  return (
    <WebSocketContext.Provider value={{ connected, webSocketService }}>
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = () => useContext(WebSocketContext);

