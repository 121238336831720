import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { Url } from '../Constants/Url';

class NotificationWebSocketService {
  constructor(baseUrl) {
    this.baseUrl = baseUrl;
    this.client = null;
    this.callbackMethods = {};
    this.userId = null;
    this.reconnectTimeout = null;
  }

  connect(userId, onConnect, onDisconnect) {
    this.userId = userId;
    const sock = new SockJS(this.baseUrl);
    this.client = Stomp.over(sock);
    
    this.client.connect({}, (frame) => {
      console.log('Connected to WebSocket for notifications', frame);
      this.subscribeToUserChannel(userId, (notificationDto) => {
        console.log('Received notification:', JSON.parse(notificationDto));
        const module = window.location.pathname.replace(/\//g, '');
        this.handleRequest('notifications', JSON.parse(notificationDto));
      });
      if (onConnect) onConnect();
    }, (error) => {
      console.error('Error connecting to WebSocket:', error);
      if (onDisconnect) onDisconnect();
      this.reconnect();
    });

    this.client.onDisconnect = () => {
      console.log('Disconnected from WebSocket');
      if (onDisconnect) onDisconnect();
      this.reconnect();
    };
  }

  reconnect() {
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
    this.reconnectTimeout = setTimeout(() => {
      console.log('Attempting to reconnect...');
      this.connect(this.userId);
    }, 5000); // Try to reconnect after 5 seconds
  }

  disconnect() {
    if (this.client !== null) {
      this.client.disconnect();
      this.client = null;
    }
    if (this.reconnectTimeout) {
      clearTimeout(this.reconnectTimeout);
    }
  }

  subscribeToUserChannel(userId, callback) {
    if (this.client && this.client.connected) {
      this.client.subscribe(`/topic/user/${userId}`, (message) => {
        console.log("Received message:", message);
        callback(message.body);
      });
    } else {
      console.error('WebSocket is not connected. Unable to subscribe.');
    }
  }

  subscribe(module, callbackMethod) {
    this.callbackMethods[module] = callbackMethod;
  }

  handleRequest(module, data) {
    if (this.callbackMethods[module]) {
      this.callbackMethods[module](data);
    }
  }

  isConnected() {
    return this.client !== null && this.client.connected;
  }
}

const baseUrl = process.env.REACT_APP_SOCK_URL || Url.BASE_URL_SOCKET;
const notificationWebSocketService = new NotificationWebSocketService(baseUrl);

export default notificationWebSocketService;