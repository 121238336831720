import { useHistory } from 'react-router-dom';

export function useRoute() {
  const history = useHistory();

  function route(path: string) {
    history.push(path);
  }

  return route;
}
