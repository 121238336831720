import React, { Fragment, useEffect, useRef, useState } from 'react';
import { CurrentRefinements, Hits, InstantSearch, Pagination, RangeInput, RefinementList, SearchBox, SortBy, Configure, ToggleRefinement } from 'react-instantsearch';
import { createTypesenseAdapter, searchClient } from "../../typesenseAdapter.js";
import { ClearFilters } from '../Widgets/ClearFilters';
import '../../App.css'
import '../Dynamic/Typesense.scss';
import { FacetDropdown } from '../Widgets/FacetDropdown';
import Cookies from 'universal-cookie';
import { useHistory, useLocation } from 'react-router';
import CurrencyFormatter from '../Static/CurrencyFormatter';
import { PriceRangeSlider } from './PriceRangeSlider.js';
import { AgeRangeSlider } from './AgeRangeSlider.js';
import '../Dynamic/Typesense.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import WhiskeyBarrel from '../../assets/images/products/WhiskyBarrel.png'
import PurchaseOffer from '../popups/PurchaseOffer';
import GridViewIcon from '@mui/icons-material/GridView';
import ListSharpIcon from '@mui/icons-material/ListSharp';
import { Tooltip, Typography } from '@mui/material';
import Select from 'react-select';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { OrderApiService } from '../../Service/OrderApiService';
import SampleOrder from '../popups/SampleOrder';

const Typesense: React.FC = () => {
    const closeOnChange = () => window.innerWidth > 375;
    const location = useLocation();
    const currentPath = location.pathname;
    const [gridView, setView] = useState<boolean>(true);
    const [spiritAgeYears, setSpiritAgeYears] = useState<string[]>([]);
    const [spiritAgeMonths, setSpiritAgeMonths] = useState<string[]>([]);
    const [hits, setHits] = useState([]);
    const [filters, setFilters] = useState<string[]>([]);
    const [wishlistItems, setWishlistItems] = useState<any[]>([]);
    const userId = new Cookies().get('userId');
    const companyId = new Cookies().get('companyId');
    const [globalFilter, setGlobalFilter] = useState<string>('');
    const collectionName = process.env.REACT_APP_TYPESENSE_COLLECTIONNAME; //Typesense collection name

    const yearOptions = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
    ];

    const monthOptions = [
        { value: '0', label: '0' },
        { value: '1', label: '1' },
        { value: '2', label: '2' },
        { value: '3', label: '3' },
        { value: '4', label: '4' },
        { value: '5', label: '5' },
        { value: '6', label: '6' },
        { value: '7', label: '7' },
        { value: '8', label: '8' },
        { value: '9', label: '9' },
        { value: '10', label: '10' },
        { value: '11', label: '11' },
    ];

    const getUserWishlist = async () => {
        try {
            const response: any = await OrderApiService.getUserWishlist(userId);
            setWishlistItems(response.data);
        } catch (error) {
            console.error('Error while fetching wishlist:', error);
        }
    };

    useEffect(() => {
        getUserWishlist();
    }, []);

    useEffect(() => {

        let filters = userId;
        if (currentPath === '/user/inventories') {
            filters = userId ? [`company_id:!=${companyId}`] : ['company_id'];
        } else if (currentPath === '/user/my-inventory') {
            filters = userId ? [`company_id:=${companyId}`] : ['company_id'];
        } else if (currentPath === '/user/wish-list') {
            filters = wishlistItems.length > 0 ? [`owner_id:!=${userId}`, `(inventory_id:${wishlistItems.join(' || inventory_id:')})`] : [`owner_id:!=${userId}  &&  inventory_id:null`];
        }

        // Generate dynamic filter string
        const filterValue = Array.isArray(filters) ? filters?.join(' && ').replace(/\\/g, '') : filters;
        setGlobalFilter(filterValue);

    }, [location.pathname, wishlistItems]);

    const switchView: any = (view: any) => {
        if (view == 'grid')
            setView(true);
        else
            setView(false);
    };

    // const searchClientData: any = (filters: string[]) => {

    //     const filterValue = filters?.join(' && ');
    //     const dynamicAdapter = createTypesenseAdapter();
    //     setDynamicSearchClient(dynamicAdapter.searchClient);
    //     dynamicAdapter.searchClient
    //         .search({
    //             q: '',
    //             queryBy: '*',
    //         })
    //         .then((response: any) => {
    //             setHits(response.hits);
    //         })
    //         .catch((error: any) => {
    //             console.error('Error:', error);
    //         });
    // };

    // const collectHits = (hits: any[]) => {
    //     for (const hit of hits) {
    //         setSpiritAgeYears(prevAllHits => {
    //             // Check if hit.spirit_age_year is not already in the array before adding it
    //             if (!prevAllHits.includes(hit.spirit_age_year)) {
    //                 return [...prevAllHits, hit.spirit_age_year];
    //             }
    //             return prevAllHits; // If it's a duplicate, return the previous state
    //         });
    //         setSpiritAgeMonths(prevAllHits => {
    //             // Check if hit.spirit_age_month is not already in the array before adding it
    //             if (!prevAllHits.includes(hit.spirit_age_month)) {
    //                 return [...prevAllHits, hit.spirit_age_month];
    //             }
    //             return prevAllHits; // If it's a duplicate, return the previous state
    //         });
    //     }
    // };

    // const onMinSpiritAgeYearChange = (selectedOption: any) => {

    //     if (selectedOption) {
    //         const selectedFilter = `spirit_age_year:>=${selectedOption.value}`;
    //         setFilters(prevFilters => {
    //             const updatedFilters = prevFilters.includes(selectedFilter)
    //                 ? prevFilters
    //                 : [...prevFilters.filter(filter => !filter.startsWith("spirit_age_year:>=")), selectedFilter];
    //             searchClientData(updatedFilters);
    //             return updatedFilters;
    //         });
    //     }
    // };

    // const onMinSpiritAgeMonthChange = (selectedOption: any) => {

    //     if (selectedOption) {
    //         const selectedFilter = `spirit_age_month:>=${selectedOption.value}`;
    //         setFilters(prevFilters => {
    //             const updatedFilters = prevFilters.includes(selectedFilter)
    //                 ? prevFilters
    //                 : [...prevFilters.filter(filter => !filter.startsWith("spirit_age_month:>=")), selectedFilter];
    //             searchClientData(updatedFilters);
    //             return updatedFilters;
    //         });
    //     }
    // };

    // const onMaxSpiritAgeYearChange = (selectedOption: any) => {

    //     if (selectedOption) {
    //         const selectedFilter = `spirit_age_year:<=${selectedOption.value}`;
    //         setFilters(prevFilters => {
    //             const updatedFilters = prevFilters.includes(selectedFilter)
    //                 ? prevFilters
    //                 : [...prevFilters.filter(filter => !filter.startsWith("spirit_age_year:<=")), selectedFilter];
    //             searchClientData(updatedFilters);
    //             return updatedFilters;
    //         });
    //     }
    // };

    // const onMaxSpiritAgeMonthChange = (selectedOption: any) => {

    //     if (selectedOption) {
    //         const selectedFilter = `spirit_age_month:<=${selectedOption.value}`;
    //         setFilters(prevFilters => {
    //             const updatedFilters = prevFilters.includes(selectedFilter)
    //                 ? prevFilters
    //                 : [...prevFilters.filter(filter => !filter.startsWith("spirit_age_month:<=")), selectedFilter];
    //             searchClientData(updatedFilters);
    //             return updatedFilters;
    //         });
    //     }
    // };

    const addToWishList = async (itemId: string) => {
        const wishListRequest = {
            inventoryId: itemId,
            userId: userId,
        };

        try {
            const response: any = await OrderApiService.addToWishlist(wishListRequest);
            getUserWishlist();
        } catch (error) {
            console.error('Error while adding wishlist data:', error);
        }
    };

    const removeFromWishList = async (itemId: string) => {
        const wishListRequest = {
            inventoryId: itemId,
            userId: userId,
        };

        try {
            const response: any = await OrderApiService.removeFromWishlist(wishListRequest);
            getUserWishlist();
        } catch (error) {
            console.error('Error while removing wishlist data:', error);
        }
    };

    function transformRefinementItems(items: any) {
        return items.map((item: any) => {
            if (item.attribute === 'spirit_age') {
                return {
                    ...item,
                    refinements: item.refinements.map((refinement: any) => {
                        // Extract relational operator and numeric value
                        const match = refinement.label.match(/([<>=]*\s*)(\d+)/);
                        if (match && match.length >= 3) {
                            const prefix = match[1]; // Operators like ≤, ≥, <, >
                            const months = parseInt(match[2], 10); // Numeric value
                            const years = Math.floor(months / 12);
                            const remainingMonths = months % 12;
                            const yearLabel = years > 1 ? `${years} years` : `${years} year`;
                            const monthLabel = remainingMonths > 1 ? `${remainingMonths} months` : `${remainingMonths} month`;

                            // Reassemble the label with the original operator and converted values
                            return {
                                ...refinement,
                                label: `${prefix}${yearLabel}, ${monthLabel}`
                            };
                        }
                        return refinement;
                    })
                };
            }
            return item;
        });
    }

    const headerRef = useRef<HTMLDivElement>(null);
    const bodyRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const bodyTable = bodyRef.current;
        const headerTable = headerRef.current;

        const handleScroll = () => {
            if (headerTable && bodyTable) {
                headerTable.scrollLeft = bodyTable.scrollLeft;
            }
        };

        if (bodyTable) {
            bodyTable.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (bodyTable) {
                bodyTable.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);


    return (
        <div className="Typesense__container">
            <InstantSearch searchClient={searchClient} indexName={collectionName}>
                <Configure filters={globalFilter} /> {/* Use the state variable directly here */}
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-3 mb-5 mb-lg-0">
                            <div className="Typesense__filters-wrapper d-flex flex-wrap justify-content-center align-items-center pt-4 pb-5 w-100">
                                <div className="filters--type d-flex flex-column">
                                    <div className="filter mb-3">
                                        <SearchBox placeholder="Search for inventories... " />
                                    </div>
                                    <div className="filter filter--matches mb-3">
                                        <div className="filter--title mb-2">
                                            Matches
                                        </div>
                                        <CurrentRefinements transformItems={transformRefinementItems} />
                                    </div>
                                    {/* <div className="filter filter--border filter--spiritAge mb-3 pb-3">
                                        <div className="filter--title mb-2">
                                            Spirit age
                                        </div>
                                        <div className='spiritAge__wrapper'>
                                            <div className="filter--title mb-2">
                                                Years
                                            </div>
                                            <div className="spiritAge-range mb-3">
                                                <RangeInput attribute="spirit_age_year" />
                                            </div>
                                            <div className="filter--title mb-2">
                                                Months
                                            </div>
                                            <div className="spiritAge-range">
                                                <RangeInput attribute="spirit_age_month" />
                                            </div>
                                        </div>
                                    </div> */}

                                    <div className="filter filter--border filter--proof mb-3 pb-3">
                                        <div className="filter--title mb-2">
                                            Proof (proof)
                                        </div>
                                        <div className="spiritAge-range">
                                            <RangeInput attribute="proof" />
                                        </div>
                                    </div>
                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        <div className="filter--title mb-2">
                                            Price ($)
                                        </div>
                                        <div className="">
                                            <PriceRangeSlider attribute="price" />
                                        </div>
                                    </div>

                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        <div className="filter--title mb-2">
                                            Age
                                        </div>

                                        <div className="">
                                            <AgeRangeSlider attribute="spirit_age" />
                                        </div>
                                    </div>

                                    {/* <FacetDropdown
                                buttonText={({ refinements }) => {
                                    const [start, end] = refinements;
                                    return start || end
                                        ? `Price $(${(start || end).label}${end ? ' & ' + end.label : ''
                                        })`
                                        : `Price $`;
                                }}
                                closeOnChange={closeOnChange}
                                classNames={{ root: 'my-BrandDropdown' }}> */}

                                    {/* </FacetDropdown> */}

                                    {/* <FacetDropdown
                                closeOnChange={closeOnChange}
                                buttonText={({ refinements }) => {
                                    const label = refinements[0]?.label.split(' > ').pop();
                                    return label ? `Producer (${label})` : `Producer`;
                                }}
                                classNames={{ root: 'my-BrandDropdown' }}> */}
                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        <div className="filter--title mb-2">
                                            Producers
                                        </div>
                                        <div className="">
                                            <RefinementList attribute="producer" searchablePlaceholder="Search Producer" />
                                        </div>
                                    </div>
                                    {/* </FacetDropdown> */}

                                    {/* <FacetDropdown
                                closeOnChange={closeOnChange}
                                buttonText={({ refinements }) => {
                                    const label = refinements[0]?.label.split(' > ').pop();
                                    return label ? `Origin (${label})` : `Origin`;
                                }}
                                classNames={{ root: 'my-BrandDropdown' }}> */}
                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        <div className="filter--title mb-2">
                                            Facilities
                                        </div>
                                        <div className="">
                                            <RefinementList attribute="facility" searchablePlaceholder="Search Facility" />
                                        </div>
                                    </div>
                                    {/* </FacetDropdown> */}
                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        <div className="filter--title mb-2">
                                            Mash bill
                                        </div>
                                        <div className="">
                                            <RefinementList
                                                attribute="mash_bill"
                                                searchablePlaceholder="Search Mashbill"
                                            />
                                        </div>
                                    </div>
                                    {/* <FacetDropdown
                                buttonText={({ refinements }) => {
                                    // const label = refinements[0]?.label.split(' > ').pop();
                                    const count = refinements.length;
                                    return count ? `Liquor (${count})` : `Liquor`;
                                }}
                                closeOnChange={closeOnChange}
                                classNames={{ root: 'my-BrandDropdown' }}> */}
                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        <div className="filter--title mb-2">
                                            TTB type
                                        </div>
                                        <div className="">
                                            <RefinementList attribute="ttb_type" searchablePlaceholder="Search TTB Type" />
                                        </div>
                                    </div>
                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        <div className="filter--title mb-2">
                                            State
                                        </div>
                                        <div className="">
                                            <RefinementList attribute="state" searchablePlaceholder="Search TTB Type" />
                                        </div>
                                    </div>
                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        {/* <div className="filter--title mb-2">
                                            Is Relocation Required?
                                        </div> */}
                                        <div className="cursor-pointer">
                                            <RefinementList attribute="is_relocation_required" transformItems={items =>
                                                items.map(item => ({
                                                    ...item,
                                                    label: item.label === 'true' ? 'Relocation required' : item.label === 'false' ? 'Relocation not required' : item.label
                                                }))
                                            } />
                                        </div>
                                    </div>

                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        {/* <div className="filter--title mb-2">
                                            Is Available for Offer?
                                        </div> */}
                                        <div className="cursor-pointer">
                                            <RefinementList attribute="is_make_an_offer" transformItems={items =>
                                                items.map(item => ({
                                                    ...item,
                                                    label: item.label === 'true' ? 'Available for offer' : item.label === 'false' ? 'Not available for offer' : item.label
                                                }))
                                            }/>
                                        </div>
                                    </div>

                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        {/* <div className="filter--title mb-2">
                                            Is Available for Offer?
                                        </div> */}
                                        <div className="cursor-pointer">
                                            <RefinementList attribute="is_account_transfer" transformItems={items =>
                                                items.map(item => ({
                                                    ...item,
                                                    label: item.label === 'true' ? 'Account transfer required' : item.label === 'false' ? 'Account transfer not required' : item.label
                                                }))
                                            }/>
                                        </div>
                                    </div>

                                    <div className="filter filter--border filter--price mb-3 pb-3">
                                        {/* <div className="filter--title mb-2">
                                            Is Available for Offer?
                                        </div> */}
                                        <div className="cursor-pointer">
                                            <RefinementList attribute="is_third_party_storage" transformItems={items =>
                                                items.map(item => ({
                                                    ...item,
                                                    label: item.label === 'true' ? 'Stored at third party storage' : item.label === 'false' ? 'Not stored at third party storage' : item.label
                                                }))
                                            }/>
                                        </div>
                                    </div>
                                    
                                    {/* </FacetDropdown> */}

                                    <ClearFilters />
                                </div>

                            </div>
                        </div>
                        <div className="col-12 col-lg-9">
                            <div className="row d-flex justify-content-between align-items-center align-items-sm-end">
                                <div className="col-auto mb-2">
                                    <h3 className='table__heading'>Available Barrels</h3>
                                </div>
                                <div className="col-auto mb-2">
                                    <div className="view__type--select">
                                        <ButtonGroup>
                                            <Button variant="link"
                                                aria-label="View as" disabled
                                                className='d-none d-sm-block'
                                            >
                                                View As
                                            </Button>
                                            <Button aria-label="Grid" className='ms-1 ms-sm-0'
                                                onClick={() => {
                                                    switchView("grid");
                                                }} style={{ backgroundColor: gridView === true ? '#0C3344' : '#D7D7D7' }} >
                                                <GridViewIcon style={{ color: gridView === true ? 'white' : 'inherit' }} />
                                            </Button>
                                            <Button
                                                aria-label="Table"
                                                onClick={() => {
                                                    switchView("table");
                                                }}
                                                style={{ backgroundColor: gridView === false ? '#0C3344' : '#D7D7D7' }} >
                                                <ListSharpIcon style={{ color: gridView === false ? 'white' : 'inherit' }} />
                                            </Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </div>
                            <hr className='my-1' />
                            <div className="inventory__table--container">
                                {!gridView && (
                                    <div className="table-container">
                                        <div className="table--header" ref={headerRef}>
                                            <table className="table table-striped mb-0">
                                                <thead className="thead-dark">
                                                    <tr>
                                                        <th>
                                                            <div className='d-flex justify-content-between'>
                                                                <span className='thead__heading'>Spirit</span>
                                                                <SortBy
                                                                    items={[
                                                                        { label: 'asc', value: 'inventory/sort/ttb_type:asc' },
                                                                        { label: 'desc', value: 'inventory/sort/ttb_type:desc' },
                                                                    ]}
                                                                    classNames={{
                                                                        root: 'MyCustomSortBy',
                                                                        select: 'MyCustomSortBySelect MyCustomSortBySelect--subclass',
                                                                    }}
                                                                />
                                                            </div>
                                                        </th>
                                                        {/* <th>
                                                    <div className='d-flex justify-content-between'>
                                                        <span className='thead__heading'>Origin</span>
                                                        <SortBy
                                                            items={[
                                                                { label: 'asc', value: 'inventory/sort/facility:asc' },
                                                                { label: 'desc', value: 'inventory/sort/facility:desc' },
                                                            ]}
                                                        />
                                                    </div>
                                                </th> */}
                                                        <th>
                                                            <div className='d-flex justify-content-between'>
                                                                <span className='thead__heading text-nowrap'>Price $</span>
                                                                <SortBy
                                                                    items={[
                                                                        { label: 'asc', value: 'inventory/sort/price:asc' },
                                                                        { label: 'desc', value: 'inventory/sort/price:desc' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='d-flex justify-content-between'>
                                                                <span className='thead__heading text-nowrap'>Spirit Age</span>
                                                                <SortBy
                                                                    items={[
                                                                        { label: 'asc', value: 'inventory/sort/spirit_age:asc' },
                                                                        { label: 'desc', value: 'inventory/sort/spirit_age:desc' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='d-flex justify-content-between'>
                                                                <span className='thead__heading text-nowrap'>Proof</span>
                                                                <SortBy
                                                                    items={[
                                                                        { label: 'asc', value: 'inventory/sort/proof:asc' },
                                                                        { label: 'desc', value: 'inventory/sort/proof:desc' },
                                                                    ]}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className='d-flex justify-content-between'>
                                                                <span className='thead__heading'>Action</span>
                                                            </div>
                                                        </th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                        <div className="table--body" ref={bodyRef}>
                                            <table className="table table-striped table-bordered mb-0">
                                                <tbody>
                                                    <Hits style={{ cursor: 'pointer' }} hitComponent={(props) => (<HitItem {...props} addToWishList={addToWishList} removeFromWishList={removeFromWishList} wishlistItems={wishlistItems} />)} />
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}
                                {/* {!gridView && (
                                )} */}
                                {gridView && (
                                    <Hits style={{ cursor: 'pointer' }} hitComponent={(props) => (<GridHitItem {...props} addToWishList={addToWishList} removeFromWishList={removeFromWishList} wishlistItems={wishlistItems} />)} />
                                )}
                                <Pagination />
                            </div>
                        </div>
                    </div>
                </div>
                {/*<h4>Filter by Spirit Age (Year)</h4>
                <PriceSlider attribute="spirit_age" />

                <h4>Filter by Price ($)</h4>
                <PriceSlider attribute="price" />
                
                <h4>Filter by Producers</h4>
                <RefinementList attribute="producer" searchable={true} searchablePlaceholder="Search Producer" />

                <h4>Filter by Mash Bill</h4>
                <RefinementList attribute="mash_bill" searchable={true} searchablePlaceholder="Search Mashbill" />

                <h4>Filter by State</h4>
                <RefinementList attribute="state" searchable={true} searchablePlaceholder="Search State" />

                <h4>Filter by Proof (Proof)</h4>
                <PriceSlider attribute="proof" /> */}
            </InstantSearch>
        </div>
    );
}

const HitItem: React.FC<{ hit: any, addToWishList: (itemId: string) => void, removeFromWishList: (itemId: string) => void, wishlistItems: any[] }> = ({ hit, addToWishList, removeFromWishList, wishlistItems }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const history = useHistory();
    const [isInWishlist, setIsInWishlist] = useState(wishlistItems.includes(hit.inventory_id));
    const [description, setDescription] = useState("");
    const [status, setStatus] = useState(false);

    const handleBuyNowClick = () => {
        // Redirect to another page with the hit id
        console.log(hit.id);
        history.push(`/user/product-details/${hit.id}`);
    };

    const manageWishList = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        isInWishlist ? removeFromWishList(hit.inventory_id) : addToWishList(hit.inventory_id);
        setIsInWishlist(prev => !prev);
    };

    const updateDescription = (updatedDescription: any) => {
        setDescription(updatedDescription);
    };

    const stopPropagation = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
    };

    return (

        <>
            <table className="table inventory__table--data table-hover align-middle mb-0">
                <tbody className="thead-dark">
                    <tr className='border-bottom border-secondary-subtle' onClick={handleBuyNowClick}>
                        <td>
                            <div className='d-flex flex-column'>
                                <span>{hit.ttb_type}</span>
                                <span className='text-body-tertiary'>{hit.mash_bill}</span>
                            </div>
                        </td>
                        {/* <td>
                            <div className='d-flex flex-column'>
                                <span>{hit.facility}</span>
                                <span className='text-body-tertiary'>{hit.state}</span>
                            </div>
                        </td> */}
                        <td>
                            <div className='d-flex flex-column'>
                                <CurrencyFormatter amount={hit.price} locale="en-US" currency="USD" />
                                <span className='text-body-tertiary'>Per Barrel</span>
                            </div>
                        </td>
                        <td>
                            <div className='d-flex flex-column'>
                                <span>{hit.spirit_age_year} years {hit.spirit_age_month} months </span>
                            </div>
                        </td>
                        <td>
                            <div className='d-flex flex-column'>
                                <span>{hit.proof}</span>
                                <span className='text-body-tertiary'>{hit.proof_unit.toLowerCase()
                                    .split(' ')
                                    .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                                    .join(' ')}</span>
                            </div>
                        </td>
                        {(currentPath === '/user/inventories' || currentPath === '/user/wish-list') && (
                            <td>
                                <div className="d-flex align-items-center">
                                    <div className='manageWishList me-2' onClick={manageWishList}>
                                        {isInWishlist ? (
                                            <Tooltip title="Remove from Wishlist" arrow>
                                                <FavoriteIcon sx={{ cursor: 'pointer', color: 'red' }} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Add to Wishlist" arrow>
                                                <FavoriteBorderIcon sx={{ cursor: 'pointer', color: '#D9D9D9' }} />
                                            </Tooltip>
                                        )}
                                    </div>
                                    <div className="request__sample ms-1" onClick={stopPropagation}>
                                        <SampleOrder
                                            id={hit.id}
                                            inventoryName={hit.product_name}
                                            text={false}
                                        />
                                    </div>
                                </div>
                            </td>
                        )}
                    </tr>
                </tbody>
            </table>
        </>
    );
}

const GridHitItem: React.FC<{ hit: any, addToWishList: (itemId: string) => void, removeFromWishList: (itemId: string) => void, wishlistItems: any[] }> = ({ hit, addToWishList, removeFromWishList, wishlistItems }) => {
    const location = useLocation();
    const currentPath = location.pathname;
    const history = useHistory();
    const [isInWishlist, setIsInWishlist] = useState(wishlistItems.includes(hit.inventory_id));

    const handleRowClick = () => {
        // Redirect to another page with the hit id
        history.push(`/user/product-details/${hit.id}`);
    };

    const manageWishList = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.stopPropagation();
        isInWishlist ? removeFromWishList(hit.inventory_id) : addToWishList(hit.inventory_id);
        setIsInWishlist(prev => !prev);
    };

    return (

        <>
            <div className="grid-view inventory__grid-view" onClick={handleRowClick}>
                <div className="grid-item" >
                    <div className="card bg-transparent">
                        <img src={WhiskeyBarrel} className="card-img-top" alt="..." />
                        <div className="card-body">
                            <div className="card-title">
                                <h5>
                                    {hit.product_name}
                                    {/* <span>{hit.mash_bill}</span> */}
                                </h5>
                            </div>
                            <div className="card-text d-flex justify-content-between">
                                <div className='grid-cell'>
                                    <CurrencyFormatter amount={hit.price} locale="en-US" currency="USD" />&nbsp;
                                    <span>/ Barrel</span>
                                </div>
                                {(currentPath === '/user/inventories' || currentPath === '/user/wish-list') && (
                                    <div className='grid-cell' onClick={manageWishList}>
                                        {isInWishlist ? (
                                            <Tooltip title="Remove from Wishlist" arrow>
                                                <FavoriteIcon sx={{ cursor: 'pointer', color: 'red' }} />
                                            </Tooltip>
                                        ) : (
                                            <Tooltip title="Add to Wishlist" arrow>
                                                <FavoriteBorderIcon sx={{ cursor: 'pointer', color: '#D9D9D9' }} />
                                            </Tooltip>
                                        )}
                                    </div>

                                )}
                            </div>
                            <div className='details'>
                                <div className='grid-cell'>
                                    <span>{hit.spirit_age_year} years {hit.spirit_age_month} months</span>
                                    {/* <span>Years Old</span> */}
                                </div>
                            </div>
                            <div className='details'>
                                <div className='grid-cell'>
                                    <span>{hit.mash_bill}</span>
                                </div>
                            </div>
                            <div className='details'>
                                <div className='grid-cell'>
                                    <span>{hit.proof} {hit.proof_unit.toLowerCase()
                                        .split(' ')
                                        .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
                                        .join(' ')}</span>
                                </div>
                            </div>
                            {/* <div className='details'>
                                <div className='grid-cell'>
                                    <span>{hit.quantity}</span>&nbsp;
                                    <span>{hit.reserved_quantity}</span>
                                </div>
                            </div> */}

                            {/* <div className="col-auto px-1">
                                <div className="cart__add--btn d-flex justify-content-end">
                                    <Typography variant="body2" sx={{ fontWeight: 700, fontSize: '14px', color: "#496673" }}>Remaining Quantity :  </Typography>
                                    <Typography variant="body2" className='mrl' sx={{ fontWeight: 700, fontSize: '14px', color: "#242424" }}> {hit?.quantity} </Typography>


                                </div>
                            </div> */}
                            {/* <div className='details'>
                                <div className='grid-cell'>
                                    <span>{hit.producer}</span>
                                </div>
                            </div>
                            <div className='details'>
                                <div className='grid-cell'>
                                    <span>{hit.facility}</span>
                                    <span>{hit.state}</span>
                                </div>
                            </div> */}
                            {/* {currentPath === '/inventories' && (
                                <div className='grid-cell'>
                                    <button className='btn primary-btn'>Buy Now</button>
                                </div>
                            )} */}
                            {/* <div className='grid-cell d-flex justify-content-between align-items-center'>
                                <button className='btn primary-btn'>Buy Now</button>
                                <PurchaseOffer inventoryId={hit.id} />
                            </div> */}
                        </div>
                    </div>

                    {/* {<div className='grid-cell'>
                        <span>{hit.ttb_type}</span>
                        <span className='text-body-tertiary'>{hit.mash_bill}</span>
                    </div> }
                    {<div className='grid-cell'>
                        <span>{hit.facility}</span>
                        <span className='text-body-tertiary'>{hit.state}</span>
                    </div> }
                    {<div className='grid-cell'>
                        <CurrencyFormatter amount={hit.price} locale="en-US" currency="USD" />
                        <span className='text-body-tertiary'>Per Barrel</span>
                    </div> }
                    {<div className='grid-cell'>
                        <span>{hit.spirit_age}</span>
                        <span className='text-body-tertiary'>Years Old</span>
                    </div> }
                    {<div className='grid-cell'>
                        <span>{hit.producer}</span>
                    </div> }
                    {currentPath === '/user/inventories' && (
                        <div className='grid-cell'>
                            <button className='btn primary-btn'>Buy Now</button>
                        </div>
                    )}  */}
                </div>
            </div>
        </>
    );
}

export default Typesense;