import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import RestrictedRoute from "./Auth/RestrictedRoute";
import PrivateRoute from "./Auth/PrivateRoute";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Layout from "./Components/Common/Layout";
import Login from "./Components/Static/Login/Login";
import Register from "./Components/Static/Register";
import RegisterSuccess from "./Components/Static/RegisterSuccess";
import SetPassword from "./Components/Static/SetPassword";
import UserLayout from "./Components/Common/UserLayout";
import EmailSender from "./Components/Static/EmailSender";
import ResetPassword from "./Components/Static/ResetPassword";
import PrivateUserRoute from "./Auth/PrivateUserRoute";
import PrivateAdminRoute from "./Auth/PrivateAdminRoute";
function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/forgot-password" component={EmailSender} />
        <Route exact path = "/reset-password/:id" component={ResetPassword}/>
        <Route exact path="/register/:id" component={Register} />
        <Route exact path="/successRegister" component={RegisterSuccess}></Route>
        <Route exact path="/setPassword/:id" component={SetPassword}></Route>

        {/* <PrivateRoute path="/master" component={Layout} />
        <PrivateRoute path="/user" component={UserLayout} /> */}

        <PrivateUserRoute path="/user" component={UserLayout} />
        <PrivateAdminRoute path="/master" component={Layout} />

        <PrivateRoute path="*" component={Layout} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
