import { faDownload, faTimes, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Service } from '../../Service/ApiService';
import { CommonService } from '../../Service/CommonService';
import Tooltip from '@mui/material/Tooltip';
import { InventoryApiService } from '../../Service/InventoryApiService';

interface ChildProps {
    docs: any;
    // displayIcon: boolean;
    // reloadTable: any;
}

const ViewDocs: React.FC<ChildProps> = ({ docs }) => {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedUserName, setSelectedUserName] = useState([]);
    const [isApproved, setisApproved] = useState(false);
    const handleClose = () => {
        setisApproved(false);
        // reloadTable();
        setShow(false)
    };

    // useEffect(() => {
    //     if (approveUser.length) {
    //         let c = approveUser.map((user: { email: any; }) => { return user.email })
    //         setSelectedUserName(c);
    //     }
    // }, [approveUser]);

    const downloadDoc = async (item: any) => {
        const requestDto = {
            id: item.id,
            siteId: docs.siteId,
            companyId: docs.ecommUser.companyId,
            fileName: item.fileName
        };

        try {
            const response: any = await InventoryApiService.downloadDoc(requestDto);
            // Assuming response.data is the blob data
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = item.fileName;
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };



    return (
        <>
            <button onClick={handleShow} className="white-outline-btn d-inline-flex justify-content-between align-items-center ms-1">
                View All Docs
            </button>

            <Modal show={show} onHide={handleClose} className='modal__wd_small'>
                <Modal.Header className='d-flex justify-content-between align-items-start'>
                    <Modal.Title>All Docs</Modal.Title>
                    <Tooltip arrow title='close'>


                        <IconButton style={{ color: '#F8DFA1' }} onClick={handleClose}>
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </Tooltip>
                </Modal.Header>
                {docs?.docs && docs?.docs.length &&
                    <Modal.Body className="d-flex flex-column">
                        <ul className="list-unstyled d-flex flex-wrap justify-content-between align-items-center m-0 p-0">
                            {docs?.docs.map((item: any, index: any) => (
                                <li
                                    key={item.id}
                                    className="d-flex justify-content-between align-items-center mb-3 w-100"
                                    style={{ maxWidth: '400px' }}
                                >
                                    <span>{item.fileName}</span>
                                    <Tooltip arrow title={'Download ' + item.fileName}>
                                        <IconButton onClick={() => downloadDoc(item)}>
                                            <FontAwesomeIcon icon={faDownload} />
                                        </IconButton>
                                    </Tooltip>
                                </li>
                            ))}
                        </ul>
                    </Modal.Body>
                }
                <Modal.Footer className='justify-content-center'>
                    {/* <div className="btn-group row d-flex justify-content-between w-100">
                        <div className={isApproved ? "col-12" : "col-6 ps-0"}>
                            <Button className="secondary-btn w-100" onClick={handleClose}>
                                Close
                            </Button>
                        </div>
                    </div> */}
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default ViewDocs;