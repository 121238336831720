import { useState } from "react";
import { useHistory, useParams } from "react-router";
import useFormValidation from "../Dynamic/FormValidation";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PasswordStrength from "../Dynamic/PasswordStrength";
import { CommonService } from "../../Service/CommonService";
import { Service } from "../../Service/ApiService";
import Spinner from "../Common/Spinner";
import { useRoute } from "../Common/route";
import { Typography } from "@mui/material";

interface ChangePasswordProps {
  onClose: () => void;
}
const ChangePassword: React.FC<ChangePasswordProps> = ({ onClose }) => {
  const route = useRoute();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const handleClose = () => onClose();
  // { CommonService.isAdmin() ? route("/master/my-profile/" + id) : route("/user/my-profile/" + id) };
  const initialFormData = {
    id: id,
    password: "",
    retypePassword: ""
  };
  const { formData, errors, handleChange, handleBlur, setErrors, validateForm, setFormData } = useFormValidation(initialFormData);
  const checkPasswordStrength = (password) => {
    if (password.length < 6) return "Weak";
    if (password.length < 10) return "Medium";
    return "Strong";
  };
  // Toggle password visibility
  const togglePasswordVisibility = () => { setShowPassword(!showPassword); };
  // Toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => { setShowRetypePassword(!showRetypePassword); };

  // Validate user fields
  const validateUserFields = async (): Promise<boolean> => {
    let isValidationPass = true;

    if (formData.password.trim().length === 0 || errors.password) {
      if (formData.password.trim().length === 0) {
        setErrors((prevState) => ({
          ...prevState,
          password: "Please enter password",
        }));
      } else if (formData.password.trim().length > 0 && formData.password.trim().length < 6) {
        setErrors((prevState) => ({
          ...prevState,
          password: "Password must be at least 6 characters long",
        }));
      }
      isValidationPass = false;
    }

    if (formData.retypePassword.length === 0 || formData.retypePassword !== formData.password) {
      setErrors((prevState) => ({
        ...prevState,
        retypePassword:
          formData.retypePassword.length === 0
            ? "Please retype your password"
            : "Passwords do not match",
      }));
      isValidationPass = false;
    }

    return isValidationPass;
  }

  const onSubmitChangePassword = async (e: React.FormEvent) => {
    console.log("hiiiii")
    e.preventDefault();
    setIsLoading(true);

    const isFormValid = await validateUserFields();

    if (!isFormValid) {
      setIsLoading(false);
      return;
    }

    try {
      const response = await Service.changePassword(formData);
      if (response.data === true) {
        CommonService.Toast.fire({
          title: "Password Changed Successfully",
          icon: "success",
        });
        history.push("/user/my-profile/" + id);
      }
    } catch (error: any) {
      CommonService.Toast.fire({
        title: error.response.data.error,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
      onClose();
    }
  }


  return (
    <>
      <div className="row">
        <div className="col-12">
          <Typography variant="body2" sx={{ color: '#333333', fontSize: '20px', mt: 3, mb: 3 }}>
            Enter a new password below to change your password.
          </Typography>
        </div>
        <div className="col-12">
          <form
            className="form__inner row mx-auto pb-5"
            onSubmit={onSubmitChangePassword}
            noValidate
          >
            <div className="col-lg-6">

              <div className="mb-4">
                <label htmlFor="password" className="form-label">
                  Password <span className="star-required text-danger">*</span>
                </label>
                <div className="position-relative">
                  <input
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    value={formData.password}
                    onChange={(e) => {
                      handleChange(e);
                      setPasswordStrength(
                        checkPasswordStrength(e.target.value)
                      );
                    }}
                    placeholder="Password"
                    onBlur={handleBlur}
                    className={`form-control ${errors.password ? "is_invalid" : ""
                      }`}
                  />
                  <span
                    className="text-secondary position-absolute top-50 end-0 translate-middle-y me-2"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                {formData.password && (
                  <PasswordStrength password={formData.password} />
                )}
                {errors.password && (
                  <div className="error-message">{errors.password}</div>
                )}
              </div>

              <div className="mb-3">
                <label htmlFor="retypePassword" className="form-label">
                  Re-Enter New Password <span className="star-required text-danger">*</span>
                </label>
                <div className="position-relative">
                  <input
                    type={showRetypePassword ? "text" : "password"}
                    id="retypePassword"
                    name="retypePassword"
                    placeholder="Re-type password"
                    value={formData.retypePassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${errors.retypePassword &&
                      (formData.retypePassword !== formData.password ||
                        !formData.password ||
                        !formData.retypePassword)
                      ? "is_invalid"
                      : ""
                      }`}
                  />
                  <span
                    className="text-secondary position-absolute top-50 end-0 translate-middle-y me-2"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showRetypePassword ? <FaEye /> : <FaEyeSlash />}
                  </span>
                </div>
                {formData.retypePassword === formData.password &&
                  formData.password &&
                  formData.retypePassword && (
                    <div className="mt-1 text-success">Matched</div>
                  )}
                {errors.retypePassword &&
                  (formData.retypePassword !== formData.password ||
                    !formData.password ||
                    !formData.retypePassword) && (
                    <div className="error-message">
                      {errors.retypePassword}
                    </div>
                  )}
              </div>

            </div>
            <div className="col-lg-6">
              <div className="password-list rounded-4 py-3">
                <Typography variant="body2" sx={{ color: '#333333', fontSize: '16px', fontWeight: 600, mx: 3, mb: 1, mt: 2 }}>
                  Your Password must :
                </Typography>
                <ul className="mx-2">
                  <li className="py-1">Be at least 6 characters.</li>
                  <li className="py-1">Include at least one uppercase letter (A-Z).</li>
                  <li className="py-1">Include at least one lowercase letter (a-z).</li>
                  <li className="py-1">Include at least one number (0-9).</li>
                  <li className="py-1">Include at least one symbol (e.g. “@,#,$,%,!..”).</li>
                </ul>
              </div>
            </div>
            <div className="col-12 mb-3">
              <hr />
            </div>
            <div className="col-12 mb-3">
              <div className="form__btn--group d-flex justify-content-center">
                <div className="pe-1">
                  <button
                    type="reset"
                    className="btn secondary-btn register-btn mb-2"
                    onClick={handleClose}
                    style={{ minWidth: '125px' }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="ps-1">
                  <button
                    type="submit"
                    className="btn primary-btn register-btn mb-2"
                    style={{ minWidth: '125px' }}
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner />} Save
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ChangePassword;