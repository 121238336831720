// useFormValidation.ts
import { useState } from "react";

type FormValues = Record<any, any>;
type FormErrors = Record<string, string>;

const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const validatePassword = (password: string): boolean => {
  return password.length >= 6;
};

const calculateAge = (birthDate: Date): number => {
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();

  const hasBirthdayOccurred =
    today.getMonth() > birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() >= birthDate.getDate());

  if (!hasBirthdayOccurred) {
    age--;
  }

  return age;
};



const useFormValidation = (initialState: FormValues) => {
  const [formData, setFormData] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<FormErrors>({});


 

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | { target: { name: string; value: string } }
  ) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    if (errors[name]) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: "",
      }));
    }
  };

  const validateField = (name: string, value: string): string => {
    let error = "";
    switch (name) {
      case "firstName":
        if (value.length === 0) {
          error = `Please enter first name`;
        }
        break;

      case "companyName":
        if (value.length === 0) {
          error = `Please enter company name`;
        }
        break;

      case "email":
      case "username":
        if (value.length === 0) {
          error = "Please enter email";
        } else if (!validateEmail(value)) {
          error = "Please enter a valid email address";
        }
        break;
      case "companyEmail":
        if (value.length === 0) {
          error = "Please enter email";
        } else if (!validateEmail(value)) {
          error = "Please enter a valid email address";
        }
        break;
      case "password":
        if (value.trim().length === 0) {
          error = "Please enter password";
        } else if (!validatePassword(value)) {
          error = "Password must be at least 6 characters long";
        }
        break;
      case "retypePassword":
        if (value.length === 0) {
          error = "Please retype your password";
        } else if (
          value !== formData.password &&
          formData.password.length != 0
        ) {
          error = "Passwords do not match";
        }
        break;

      case "birthdate":
        if (value.length === 0) {
          error = "Please enter birthdate";
        } else {
          const [year, month, day] = value.split("-").map(Number);
          const birthDate = new Date(year, month - 1, day);

          // Check if the date is valid
          if (isNaN(birthDate.getTime())) {
            error = "Please enter a valid date of birth";
          } else {
            const age = calculateAge(birthDate);

            if (age < 0 || age > 100) {
              error = "Please enter a valid date of birth";
            } else if (age < 21) {
              error = "Age must be 21 years or older";
            } else {
              error = "";
            }
          }
        }
        break;

      case "zipcode":
        if (value.length === 0) {
          error = "Please enter zipcode";
        }
        break;

      case "price":
        if (value.trim().length === 0 || Number(value) === 0) {
          error = "Please enter price";
        }
        break;

      case "title":
        if (value.trim().length === 0) {
          error = "Please enter title";
        }
        break;

      case "initialQuantity":
        if (value.trim().length === 0 || Number(value) === 0) {
          error = "Please enter quantity";
        }
        break;

      case "mashBill":
        if (value.trim().length === 0) {
          error = "Please enter mash bill";
        }
        break;

      case "facility":
        if (value.trim().length === 0) {
          error = "Please enter facility";
        }
        break;

      // case "barrelNumber":
      //   if(value.trim().length === 0 || Number(value) === 0){
      //     error = "Please enter barrel number"
      //   }
      // break;

      case "fillDate":
        if (value.trim().length === 0 || Number(value) === 0) {
          error = "Please enter fill date";
        }
        break;

      case "spiritAgeYear":
        if (value.trim().length === 0 || Number(value) === 0) {
          error = "Please enter spirit age";
        }
        break;

      case "lotNo":
        if (value.trim().length === 0 || Number(value) === 0) {
          error = "Please enter lot id";
        }
    }
    return error;
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    console.log("call blur");
    const { name, value } = e.target;
    const error = validateField(name, value);
    if (error) {
      setErrors((prevState) => ({
        ...prevState,
        [name]: error,
      }));
    }
  };

  const validateForm = (): boolean => {
    const newErrors: FormErrors = {};
    let isValid = true;

    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
        isValid = false;
      }
    });

    setErrors(newErrors);
    return isValid;
  };

  return {
    formData,
    errors,
    handleChange,
    handleBlur,
    setErrors,
    validateForm,
    setFormData,
  };
};

export default useFormValidation;
