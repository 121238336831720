import React, { useEffect, useState } from 'react';
import CurrencyFormatter from '../Static/CurrencyFormatter';
import { Table } from 'react-bootstrap';
import AccountTransferIcon from "../../assets/images/icons/AccountTransferIcon.svg";
import AccountTransferCrossIcon from "../../assets/images/icons/AccountTransferCrossIcon.svg";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import ShippingCrossIcon from "../../assets/images/icons/LocalShippingCrossIcon.svg";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { CommonService } from '../../Service/CommonService';
import BorderColorIcon from '@mui/icons-material/BorderColor';
interface CartProps {
    cartItems: any[];
    onEditClick: (itemId: string) => void;
}

const InventoryFileListTable: React.FC<CartProps> = ({ cartItems, onEditClick }) => {
    const [isOpen, setIsOpen] = useState<boolean[]>();

    useEffect(() => {
        setIsOpen(cartItems.map(() => false));
    }, [cartItems]);

    const toggleSection = (index: number) => {
        setIsOpen(prevState => prevState.map((open, i) => (i === index ? !open : open)));
    };

    return (
        <div className="table-responsive-lg table-responsive-fixed-header mb-lg-3">
            <Table className='orderRequest-inv-table'>
                <thead>
                    <tr>
                        <th>Inventory</th>
                        <th>Purpose</th>
                        <th>Seller</th>
                        <th>Buyer</th>
                        <th>Total Quantity</th>
                        <th>Price</th>
                        <th>Total</th>
                       {CommonService.isAdmin() && cartItems[0]?.status == "PENDING_REVIEW" &&  (<th>action</th>)}
                    </tr>
                </thead>
                <tbody>
                    {cartItems.length > 0 ? (
                        cartItems.map((item: any, index: number) => (
                            <React.Fragment key={index}>
                                <tr>
                                    <td>
                                        <div className="header d-flex align-items-center" onClick={() => toggleSection(index)}>
                                            {/* <div className={`icon ${isOpen[index] ? 'minus' : 'plus'}`}></div> */}
                                            <>
                                                {isOpen[index] ? <RemoveCircleOutlineIcon sx={{ color: "#737376", mr: 1, cursor: 'pointer' }} /> : <AddCircleOutlineIcon sx={{ color: "#737376", mr: 1, cursor: 'pointer' }} />}
                                            </>
                                            <span>{item.inventoryName}</span>
                                        </div>
                                    </td>
                                    <td>{item.purposeNote}</td>
                                    <td>
                                        {item.isRelocationRequired ? (
                                            <>
                                            <span className="d-flex my-1">
                                                <LocalShippingOutlinedIcon sx={{ color: "#9E874A", mr: 1 }} />
                                                Relocation Required
                                            </span>
                                            </>
                                        ) : (
                                            <>
                                            <span className="d-flex my-1">
                                                <img src={ShippingCrossIcon} alt="AccountTransferCrossIcon" className='me-2' width={23} height={23} />
                                                Relocation not Required
                                            </span>
                                            </>
                                        )}
                                        {item.isAccountTransfer ? (
                                            <>
                                            <span className="d-flex my-1">
                                                <img src={AccountTransferIcon} alt="AccountTransferIcon" className='me-2' width={28} height={24} />
                                                Change of Account Required
                                            </span>
                                            </>
                                        ) : (
                                            <>
                                            <span className="d-flex my-1">
                                                <img src={AccountTransferCrossIcon} alt="AccountTransferCrossIcon" className='me-2' width={23} height={23} />
                                                Change of Account not Required
                                            </span>
                                            </>
                                        )}
                                    </td>
                                    <td>{item.typeSenseResponse?.is_relocation_required ? 'Want to relocate' : '-'}
                                        <br />
                                        {item.typeSenseResponse?.is_account_transfer ? 'Want to change account' : '-'}
                                    </td>
                                    <td>{item.quantity} barrels</td>
                                    <td><CurrencyFormatter amount={item.price} locale="en-US" currency="USD" /></td>
                                    <td><CurrencyFormatter amount={item.totalPrice} locale="en-US" currency="USD" /></td>
                                    {CommonService.isAdmin() && item.status === "PENDING_REVIEW" && (
                                        <td>
                                            <BorderColorIcon 
                                                onClick={() => onEditClick(item.id)} 
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </td>
                                    )}
                                </tr>
                                {isOpen[index] && (
                                    <tr className="product-info">
                                        <td colSpan={7}>
                                            <div className="details">
                                                <span className="title d-inline-block me-2">Proof Gallons :</span>
                                                <span className="text d-inline-block">{item.typeSenseResponse?.proof_gallons}pf-gal</span>
                                                <span className="title d-inline-block me-2">Spirit Age:</span>
                                                <span className="text d-inline-block">{item.typeSenseResponse?.spirit_age_year}{item.typeSenseResponse.spirit_age_month === 0 ? '+' : ''} years</span>
                                                <span className="title d-inline-block me-2">Lot ID:</span>
                                                <span className="text d-inline-block">{item.typeSenseResponse?.lot_no}</span>
                                                <span className="title d-inline-block me-2">Batch Number:</span>
                                                <span className="text d-inline-block">{item.typeSenseResponse?.barrel_number}</span>
                                            </div>
                                            {/* <b>Proof Gallons:</b> {item.typeSenseResponse.proof_gallons}pf-gal | 
                                            <b>Spirit Age:</b> {item.typeSenseResponse.spirit_age_year}{item.typeSenseResponse.spirit_age_month === 0 ? '+' : ''} years | 
                                            <b>Lot ID:</b> {item.typeSenseResponse.lot_no} | 
                                            <b>Batch Number:</b> {item.typeSenseResponse.barrel_number} */}
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={7} style={{ textAlign: 'center' }}>Fetching records...</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default InventoryFileListTable;