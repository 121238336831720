import React, { useEffect } from "react";
import { Url } from "../../Constants/Url";


const UATbelt = () => {
  
  useEffect(() => {
    
    // You can add side effects or other logic here if needed
  }, []);

  // Conditional rendering inside JSX
  return (
    <>
      {Url.REACT_APP_IS_BELT == "true" && (
        <div className="uat-banner">
          <span className="uat-text">You are on UAT environment</span>
        </div>
      )}
    </>
  );
};

export default UATbelt;
