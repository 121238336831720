import React, { useEffect, useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { useParams } from "react-router";
import { DataGrid, GridColDef, GridRowId } from "@mui/x-data-grid";
import { CommonService } from "../../Service/CommonService";
import "../Dynamic/InventoryCSV.scss";
import SearchIcon from "../../assets/images/icons/search.svg";
import { InventoryApiService } from "../../Service/InventoryApiService";
import UploadCSV from "../popups/UploadCSV";
import { CookieService } from "../../Service/CookieService";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Typography } from "@mui/material";
import SearchBar from "../Static/SearchBar";
import { useHistory, useLocation } from "react-router";
import ViewDocs from "../popups/ViewDocs";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import { Button as BootstrapButton, Form } from "react-bootstrap";
import InfoIcon from "@mui/icons-material/Info";
import { Link } from "react-router-dom";

const MyInventories: React.FC = () => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [isLoading, setIsLoading] = useState(false);
  // Define the localeText object based on the isLoading flag
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };
  const history = useHistory();
  const [search, setSearch] = useState("");
  const userRole = CookieService.getCookie("userRole");
  const { id } = useParams<{ id: string }>();
  const params = id === undefined ? CommonService.getCompanyId() : id;
  const [tableData, setTableData] = useState<any[]>([]);
  const [inventoryCsvWithDocs, setInventoryCsvWithDocs] = useState<any>(null);
  const [selectionModel, setSelectionModel] = React.useState<GridRowId[]>([]);
  const [service, setService] = useState<string>("");
  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response: any = await InventoryApiService.getMyInventories(service);

      setTableData(Object.values(response.data));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const fetchInventoryChangeRequests = async () => {
    try {
      setIsLoading(true);
      let response: any;
      if (currentPath === "/user/inventory-change-requests")
        response = await InventoryApiService.getInventoryChangeRequests(
          params,
          false
        );
      else response = await InventoryApiService.getAllInventoryChangeRequests();

      setTableData(Object.values(response.data));
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const handleServiceDropdown = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setService(event.target.value);
  };

  useEffect(() => {
    if (
      currentPath === "/user/inventory-change-requests" ||
      currentPath === "/master/inventory-change-requests"
    )
      fetchInventoryChangeRequests();
    else fetchData();
  }, []);

  useEffect(() => {
    if (
      currentPath === "/user/inventory-change-requests" ||
      currentPath === "/master/inventory-change-requests"
    )
      fetchInventoryChangeRequests();
    else fetchData();
  }, [service]);

  const handleSelectionModelChange = (newSelection: GridRowId[]) => {
    setSelectionModel(newSelection);
    console.log(newSelection.length);
  };

  const columns: GridColDef[] = [
    {
      field: "title",
      headerName: "Inventory",
      flex: 1,
      //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
      headerClassName: "table-header",
    },
    {
      field: "facility",
      headerName: "Facility",
      // flex: 1
      //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
      headerClassName: "table-header",
    },
    {
      field: "age",
      headerName: "Age",
      flex: 0.5,
      valueGetter: (value: any, row: any) =>
        `${row.spiritAgeYear || ""}years ${row.spiritAgeMonth || ""}months`,
      headerClassName: "table-header",
    },
    {
      field: "initialQuantity",
      headerName: "Initial Quantity",
      // // flex: 1
      //valueGetter: (value: any, row: any) => `${row.ecommUser.firstName || ''} ${row.ecommUser.lastName || ''}`,
      headerClassName: "table-header",
    },
    {
      field: "quantity",
      headerName: "Quantity for Sales",
      // // flex: 1
      valueGetter: (value: any, row: any) =>
        row.isAvailableForSell ? row.quantity : 0,
      headerClassName: "table-header",
    },
    {
      field: "isNotAvailableForSell",
      headerName: "Quantity not for Sale",
      headerClassName: "table-header",
      cellClassName: "info-dropdown",
      renderCell: (params) => {
        return (
          <div>
            {!params.row.isAvailableForSell
              ? params.row.quantity +
                params.row.reservedQuantity +
                params.row.soldQuantity
              : 0}
            <Dropdown drop="end" className="d-inline-block">
              <Dropdown.Toggle
                variant="basic"
                id="dropdown-basic"
                className="p-0"
              >
                <InfoIcon sx={{ color: "#6F6F6F" }} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item href="#/action-1">
                  Avilable Quantity{" "}
                  {!params.row.isAvailableForSell ? params.row.quantity : 0}
                </Dropdown.Item>
                <Dropdown.Item href="#/action-2">
                  Reserved Quantity {params.row.reservedQuantity ?? ""}
                </Dropdown.Item>
                <Dropdown.Item href="#/action-3">
                  Sold Quantity {params.row.soldQuantity ?? ""}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
    {
      field: "lotNo",
      headerName: "Lot ID",
      headerClassName: "table-header",
    },
    {
      field: "price",
      headerName: "Price",
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
    },
    {
      field: "platformFee",
      headerName: "Fees",
      headerClassName: "table-header",
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.value}
          locale="en-US"
          currency="USD"
        />
      ),
    },
    {
      field: "total",
      headerName: "Total",
      // flex: 1
      renderCell: (params: any) => (
        <CurrencyFormatter
          amount={params.row.price * params.row.platformFee}
          locale="en-US"
          currency="USD"
        />
      ),
    },
  ];

  function routeBack() {
    if (userRole == "ADMIN") history.push("/user/my-warehouse");
    else history.push("/master/inventory-sell-requests");
  }

  // Filter rows based on search query
  const filteredRows = tableData.filter(
    (row) =>
      row.mashBill?.toLowerCase().includes(search?.toLowerCase()) ||
      row.title?.toLowerCase().includes(search?.toLowerCase()) ||
      row.price === parseFloat(search) ||
      row.barrelNumber?.toLowerCase().includes(search?.toLowerCase()) ||
      row.ttbType?.toLowerCase().includes(search?.toLowerCase())
  );

  // Map filtered rows with unique IDs
  const tableDataWithIds = filteredRows.map((rowData, index) => ({
    ...rowData,
  }));

  const handleRowClick = (params) => {
    const rolePrefix = CommonService.isAdmin() ? "master" : "user";
    const linkPrefix =
      currentPath === "/user/inventory-change-requests" ||
      currentPath === "/master/inventory-change-requests"
        ? "compare-inventory"
        : "edit-inventory";
  
    history.push(`/${rolePrefix}/${linkPrefix}/${params.row.id}`);
  };  

  return (
    <div className="usersList usersList__user">
      {/* <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
        <div className="back__button me-3" onClick={routeBack}>
          <IconButton
            aria-label="ArrowBackIcon"
            sx={{ backgroundColor: "#FFF", mb: 1 }}
          >
            <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
          </IconButton>
        </div>

        {userRole == "ADMIN" && (
          <div className="header--title mb-2 me-2">
            <h1 className="title mb-0">
              My Inventory
              <i className="bi bi-heart-fill"></i>
            </h1>
          </div>
        )}
      </div> */}
      <div className="user-table-container">
        <div className="form__inner position-relative">
          <Form.Select
            style={{ cursor: "pointer" }}
            value={service}
            className="form-control mb-1"
            onChange={handleServiceDropdown}
          >
            <option value="">Select Service</option>
            <option value="REQUEST_FOR_SALE">Request for Sale</option>
            <option value="REQUEST_FOR_SAMPLE">Request for Sample</option>
            <option value="REQUEST_FOR_OFFER">Request for Offer</option>
            <option value="REQUEST_FOR_SHIPMENT">Request for Shipment</option>
            <option value="REQUEST_FOR_PRIVATE_SELL">
              Request for Private Sell
            </option>
            <option value="REQUEST_FOR_OWNERSHIP">Request for Ownership</option>
            <option value="BARREL_MAINTEENANCE">Barrel Maintenance</option>
          </Form.Select>
        </div>
        <div className="userTable">
          <DataGrid
            localeText={localeText}
            rows={tableDataWithIds}
            columns={columns}
            className="data-grid data-grid__myinventories"
            initialState={{
              pagination: {
                paginationModel: { page: 0, pageSize: 10 },
              },
            }}
            pageSizeOptions={[10, 20, 30, 40, 50]}
            // checkboxSelection
            disableRowSelectionOnClick={true}
            rowSelectionModel={selectionModel}
            onRowClick={handleRowClick}
            onRowSelectionModelChange={handleSelectionModelChange}
          />
        </div>
      </div>
    </div>
  );
};

export default MyInventories;
