 const Constant ={

     Role :{
        ADMIN :"ADMIN",
        SUPER_ADMIN : "SUPER_ADMIN",
      },

      OrderStatus :{
        CART : "CART",
        PENDING_REVIEW : "PENDING_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED",
        SHIPPED : "SHIPPED",
        CANCELLED : "CANCELLED",
        DELIVERED : "DELIVERED",
        SELLER_REJECTED: "SELLER_REJECTED"
      },

      SampleRequestStatus :{
        PENDING_REVIEW : "PENDING_REVIEW",
        ACCEPTED : "ACCEPTED",
        REJECTED : "REJECTED",
        APPROVED : "APPROVED",
      }
}

export default Constant;