import React from 'react';
import { Typography } from "@mui/material";
import messageRemoveIcon from '../../assets/images/icons/message-remove.svg';

const NoChatMessage = () => {
  return (
    <div className='position-absolute top-50 start-50 translate-middle'>
      <div className='d-flex flex-column align-items-center justify-content-center my-3'>
        <img src={messageRemoveIcon} alt="messageRemoveIcon" />
        <Typography variant="body2" sx={{ color: '#737376', fontSize: '16px', fontWeight: 600 }}>
          No messages yet
        </Typography>
      </div>
    </div>
  )
}

export default NoChatMessage