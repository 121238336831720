import React, { useEffect, useRef, useState } from "react";
import { Table, TableContainer } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import WhiskyBarrel from "../../assets/images/products/WhiskyBarrel.png";
import { OrderApiService } from "../../Service/OrderApiService";
import CurrencyFormatter from "../Static/CurrencyFormatter";
import Info from "../../assets/images/icons/Info.svg";
import {
  Button as BootstrapButton,
  Button,
  Dropdown,
  DropdownButton,
  Modal,
} from "react-bootstrap";
import { useHistory, useLocation } from "react-router";
import Cookies from "universal-cookie";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { useParams } from "react-router";
import { CookieService } from "../../Service/CookieService";
import UploadDocs from "../popups/uploadDocs";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ContainerMessageBox from "./ContainerMessageBox";
import { CommonService } from "../../Service/CommonService";
import InventoryFileListTable from "./InventoryFileListTable";
import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import { Stepper, Step, StepLabel, StepContent, Paper } from "@mui/material";
import HowToRegIcon from "@mui/icons-material/HowToReg";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import Constant from "../../Constant";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import NoCrashIcon from "@mui/icons-material/NoCrash";
import { VisibilityOutlined } from "@mui/icons-material";
import StatusDropdown from "../Common/StatusDropdown";
import Spinner from "../Common/Spinner";
import BorderColorIcon from '@mui/icons-material/BorderColor';
import MarkunreadOutlinedIcon from '@mui/icons-material/MarkunreadOutlined';
import EditRequest from "./EditOrderRequest";
import { HistoryStepper } from "../Common/HistoryStepper";

// Create a custom theme with adjusted breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768, // Customizing the md breakpoint to start at 992px
      lg: 992,
      xl: 1200,
    },
  },
});

const ProductImage = styled("img")({
  maxWidth: "100%",
  border: "1px solid #D9D9D9", // 1px border for the image
  borderRadius: "10px",
});

const ProductCaption = styled(Typography)({
  textAlign: "center",
  marginTop: "8px",
});

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
  },
  firstColumn: {
    minWidth: 115,
    width: "max-content",
    borderBottom: "none",
    color: "#333333",
    fontWeight: 600,
  },
  secondColumn: {
    width: "100%",
    borderBottom: "none",
    color: "#21252980",
  },
});

const OrderDetail: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const localeText = {
    noRowsLabel: isLoading ? "Fetching records ..." : "No Record Found",
  };
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const [checked, setChecked] = React.useState([0]);
  const [addedCartItems, setCartItems] = React.useState<any>([]);
  const [orderSummary, setOrderSummary] = React.useState<any>([]);
  const [orderDetails, setOrderDetails] = React.useState<any>([]);
  const [orderRequestDocs, setOrderRequestDocs] = useState<any[]>([]);
  const [historyData, setHistoryData] = useState<any[]>([]);
  const [show, setShow] = useState(false);
  const history = useHistory();
  const userId = new Cookies().get("userId");
  const [globalFilter, setGlobalFilter] = useState<string>("");
  const userRole = CookieService.getCookie("userRole");
  const [totalPrice, setTotalPrice] = React.useState<number>(0);
  const statusDetailsRef = useRef({ id: "", status: "", rejectionNote: "" });
  const rejectionNote = useRef("");
  const handleShow = () => setShow(true);
  const [rejectionNoteValue, setRejectionNote] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [isOpenEditSection, setOpenEditSection] = useState(false);
  const [isMessageBoxOpen, setMessageBoxOpen] = useState(false);
  const [orderItemId, setOrderItemId] = useState("");


  const fetchData = async () => {
    try {
      fetchOrderRequestDetail();
      fetchOrderRequestDocs();
      fetchOrderRequestHistory();
      fetchOrderSummaryDetail();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchOrderRequestDetail = async () => {
    const response: any = await OrderApiService.getItemsByOrderId(id);
    console.log("this is get orderItem by id" + response.data);
    setCartItems(response.data);
    if (response.data) setOrderDetails(response.data[0]);
  };

  const fetchOrderSummaryDetail = async () => {
    const response: any = await OrderApiService.getOrderSummaryByOrderId(id);
    console.log(response);
    if (response.data) setOrderSummary(response.data);
  };

  const fetchOrderRequestDocs = async () => {
    setIsLoading(true);
    const response: any = await OrderApiService.fetchOrderRequestDocs(id);
    setOrderRequestDocs(response.data);
    setIsLoading(false);
  };

  const fetchOrderRequestHistory = async () => {
    const response: any = await OrderApiService.fetchOrderRequestHistory(id);
    setHistoryData(response.data);
  };

  useEffect(() => {
    // Scroll to top when the component mounts
    window.scrollTo(0, 0);
  }, []);
  
  useEffect(() => {
    fetchData();
  }, []);

  // Effect to calculate sum whenever the items list changes
  useEffect(() => {
    const sum = addedCartItems.reduce(
      (acc: any, item: any) => acc + (item["totalPrice"] || 0),
      0
    );
    setTotalPrice(sum);

    let filters =
      addedCartItems.length > 0
        ? [
            `owner_id:!=${userId}`,
            `(inventory_id:!=${addedCartItems
              .map((item: { inventoryId: any }) => item.inventoryId)
              .join(" || inventory_id:=")})`,
          ]
        : [`owner_id:!=${userId}`];

    const filterValue = Array.isArray(filters)
      ? filters?.join(" && ").replace(/\\/g, "")
      : filters;

    setGlobalFilter(filterValue);
  }, [addedCartItems]);

  const routeBack = () => {
    if (userRole == "ADMIN") history.push("/user/active-order");
    else history.push("/master/active-order");
  };

  const CellWithTooltip = ({ value }: { value: string | number }) => {
    return (
      <Tooltip arrow title={value?.toString()}>
        <span>{value?.toString()}</span>
      </Tooltip>
    );
  };

  //formate the status
  function formatStatus(status?: string): string {
    let formattedStatus = "";
    if (status !== undefined) {
      formattedStatus = status
        ?.split(/[_\\]/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }

    return formattedStatus;
  }

 

//handle edit button
const handleEditButtonClick = () => {
    if(isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
    }
}

//handle message box click
const handleMessageBoxClick = () => {

    if(isMessageBoxOpen) {
      setMessageBoxOpen(false);
    }
    else {
    setOpenEditSection(false);
    setMessageBoxOpen(true);
    }
}

  const handleStatusChange = async (newStatus: string) => {
    // const statusMap: { [key: string]: string } = {
    //   "1": "Reserved",
    //   "2": "Pending Review",
    //   "3": "Rejected",
    //   "4": "Shipped",
    //   "5": "Cancelled",
    //   "6": "Delivered",
    // };

    // const newStatus = statusMap[eventKey];
    console.log(newStatus);

    const orderStatus = newStatus?.replace(/ /g, "_");

    //create the object to send
    const sd = {
      id: id,
      status: orderStatus.toUpperCase(),
      rejectionNote: rejectionNote.current,
      quantity: 0
    };

    statusDetailsRef.current = sd;
    if (statusDetailsRef.current.status === Constant.OrderStatus.REJECTED) {
      handleShow();
    } else {
      changeOrderStatus();
    }
  };

  const handleEditClick = (itemId:any) => {

    if(isOpenEditSection) {
      setOpenEditSection(false);
    } else {
      setMessageBoxOpen(false);
      setOpenEditSection(true);
      setOrderItemId(itemId);
    }
    
  }

  const changeOrderStatus = async () => {

    if(statusDetailsRef.current.status === Constant.OrderStatus.REJECTED && rejectionNoteValue.trim().length == 0){
      setError("Please enter rejection note");
      return ;
    }

    setIsLoading(true);
    
    try {
      statusDetailsRef.current.rejectionNote = rejectionNote.current;
      await OrderApiService.changeOrderStatus(statusDetailsRef.current);
      setIsLoading(false);
      CommonService.Toast.fire({
        title: "Order Request updated successfully",
        icon: "success",
      });

      handleClose();
      fetchData();
    } catch (error) {
      setIsLoading(false);
      console.log("error occurred which api calling");
    }
  };

  const handleClose = () => {
    setShow(false);
    setRejectionNote("");
    setError("");
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setRejectionNote(event.target.value);
    setError("");
    rejectionNote.current = event.target.value;
  };

  const downloadOrderRequestDOC = async (item: any) => {
    const requestDto = {
      id: item.id,
      siteId: orderDetails.siteId,
      companyId: orderDetails.companyId,
      fileName: item.fileName,
    };

    try {
      const response: any = await OrderApiService.downloadOrderRequestDOC(
        requestDto
      );
      // Assuming response.data is the blob data
      const blob = new Blob([response.data]);
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = item.fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const columnsForDoc: GridColDef[] = [
    {
      field: "createdDate",
      headerName: "Submitted On",
      flex: 1,
      valueGetter: (value: any, row: any) => {
        const dateTimeString = row.createdDate ? row.createdDate : null;
        if (dateTimeString) {
          //call the method which formate the time
          return CommonService.formatDateTime(dateTimeString);
        }
      },
      headerClassName: "table-header",
    },
    {
      field: "fileName",
      headerName: "File Name",
      flex: 1,
      valueGetter: (value: any, row: any) => `${row.fileName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "user",
      headerName: "Submitted By",
      flex: 1,
      valueGetter: (value: any, row: any) =>
        `${row.firstName || ""} ${row.lastName || ""}`,
      headerClassName: "table-header",
      renderCell: (params) => <CellWithTooltip value={params.value} />,
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      sortable: false,
      width: 200,
      headerClassName: "table-header",

      renderCell: (params: any) => {
        //check file have .pdf extension or not
        const isPdf = params.row.fileName.toLowerCase().endsWith(".pdf");

        return (
          <div>
            {/* if file contains the .pdf extension then show the view button */}
            {isPdf && (
              <Tooltip title="View PDF" arrow>
                <IconButton
                  onClick={() =>
                    CommonService.showPdfForOrder(
                      params.row.id,
                      orderDetails.companyId,
                      orderDetails.siteId,
                      params.row.fileName
                    )
                  }
                >
                  <VisibilityOutlined />
                </IconButton>
              </Tooltip>
            )}

            <Tooltip title="Download document" arrow>
              <IconButton
                onClick={(event) => downloadOrderRequestDOC(params.row)}
              >
                <CloudDownloadOutlinedIcon />
              </IconButton>
            </Tooltip>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <div className="usersList">
        <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
          {CommonService.isAdmin() && (
            <div className="back__button me-3" onClick={routeBack}>
              <IconButton
                aria-label="ArrowBackIcon"
                sx={{ backgroundColor: "#FFF", mb: 1 }}
              >
                <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
              </IconButton>
            </div>
          )}

          {CommonService.isAdmin() && (
            <div className="header--title mb-2 me-2">
              <h1 className="title mb-0">
                Order Request
                <i className="bi bi-heart-fill"></i>
              </h1>
            </div>
          )}

          <div className="header--title d-flex flex-wrap me-auto mb-xxl-0">
            <div className="company__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Order number
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: "#333333",
                  fontSize: { xl: "18px", xs: "16px" },
                  fontWeight: 700,
                }}
              >
                {orderDetails?.orderNo}
              </Typography>
            </div>
            <div className="company__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Company name
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  color: "#333333",
                  fontSize: { xl: "18px", xs: "16px" },
                  fontWeight: 700,
                }}
              >
                {orderDetails?.companyName}
              </Typography>
            </div>
            <div className="person__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted by
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {orderDetails?.firstName} {orderDetails?.lastName}
              </Typography>
            </div>
            <div className="time__details me-4 me-xl-4 mb-3">
              <Typography
                variant="h6"
                sx={{ color: "#333333", fontSize: "12px", fontWeight: 400 }}
              >
                Submitted on
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "#333333", fontSize: "18px", fontWeight: 700 }}
              >
                {CommonService.formatDateTime(orderDetails?.createdDate)}
              </Typography>

              
              {/* <img src={Info} alt="Info" /> */}
              
            </div>
          </div>

          <div className="header--menu ms-auto mb-xxl-0">
            <div className="header--menu-items">
              {/* <div className="col-auto menu-items__btnGroup d-inline-flex mb-3"> */}
                

              <IconButton aria-label="message" size="small" onClick={handleMessageBoxClick} sx={{ borderRadius: "50%", backgroundColor: isMessageBoxOpen? "#97AEB8": "#FFF", color: isMessageBoxOpen? "#FFF": "#1C1B1F", marginInline: "8px", padding: "8px" }}>
                <MarkunreadOutlinedIcon />
              </IconButton>
{/* {CommonService.isAdmin() && (<button onClick={handleEditButtonClick} className="border-0 bg-transparent"> <BorderColorIcon/></button>)} */}

      {orderDetails?.status === "PENDING_REVIEW" && CommonService.isUser() && (
        <StatusDropdown
        currentStatus={formatStatus(orderDetails?.status)}
        onStatusChange={(newStatus) => handleStatusChange(newStatus)}
        disableCondition={CommonService.isUser()}
        allowedLabels={[
          "Cancelled",
        ]}
        />
      )}

{((orderDetails?.status !== "PENDING_REVIEW" && CommonService.isUser()) || CommonService.isAdmin()) &&
                <StatusDropdown
                  currentStatus={formatStatus(orderDetails?.status)}
                  onStatusChange={(newStatus) => handleStatusChange(newStatus)}
                  disableCondition={CommonService.isUser() || orderDetails?.status === "CANCELLED"}
                  allowedLabels={[
                    "Under Review",
                    "Accepted",
                    "Rejected",
                    "Contract Sent",
                    "Contract Executed",
                    "Invoice Sent",
                    "Payment Received",
                    "Ownership Transferred",
                  ]}
                  />}
              </div>
            </div>
        </div>
        <div className="viewSampleRequest--wrapper d-flex flex-column flex-xl-row justify-content-between">
          <div className="flex-grow-1">
            <div className="row m-0">
              <div className="col-xl-8">
                <div className="view-sample-request mt-4 mb-3">
                  <div className="row">
                    <div className="inventory-file-list-table">
                      <InventoryFileListTable cartItems={addedCartItems} onEditClick={handleEditClick} />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 py-4">
                <div className="order__summary--card card border-0 mb-xl-0 h-100">
                  <div className="card-header py-3">
                    <Typography
                      variant="h5"
                      sx={{
                        background: "#F0F0F0",
                        mb: 0,
                        fontWeight: 700,
                        fontSize: "20px",
                      }}
                    >
                      Order summary
                    </Typography>
                  </div>
                  <TableContainer
                    component={Paper}
                    style={{ boxShadow: "none" }}
                  >
                    <Table
                      sx={{ minWidth: 400 }}
                      aria-label="items summary table"
                    ></Table>
                  </TableContainer>
                  <div className="card-body">
                    <div className="row d-flex justify-content-between border-bottom">
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Subtotal
                        </Typography>
                      </div>
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          <CurrencyFormatter
                            amount={orderSummary.subTotal}
                            locale="en-US"
                            currency="USD"
                          />
                        </Typography>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Total Handling Charges
                        </Typography>
                      </div>
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          <CurrencyFormatter
                            amount={orderSummary.totalHandlingFees}
                            locale="en-US"
                            currency="USD"
                          />
                        </Typography>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between">
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          Total Service Charges
                        </Typography>
                      </div>
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            my: 1,
                            color: "#333333",
                            fontWeight: 600,
                            fontSize: "16px",
                          }}
                        >
                          <CurrencyFormatter
                            amount={orderSummary.totalServiceFees}
                            locale="en-US"
                            currency="USD"
                          />
                        </Typography>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-between border-top">
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            mt: 2,
                            color: "#333333",
                            fontWeight: 700,
                            fontSize: "20px",
                          }}
                        >
                          Order Total
                        </Typography>
                      </div>
                      <div className="col-auto">
                        <Typography
                          variant="body1"
                          sx={{
                            mt: 2,
                            color: "#333333",
                            fontWeight: 700,
                            fontSize: "20px",
                          }}
                        >
                          <CurrencyFormatter
                            amount={orderSummary.orderTotal}
                            locale="en-US"
                            currency="USD"
                          />
                        </Typography>
                      </div>
                      <div className="col-12">
                        <Typography
                          variant="caption"
                          display="block"
                          sx={{
                            my: 0,
                            color: "#333333",
                            fontWeight: 400,
                            fontSize: "12px",
                          }}
                        >
                          The price does not include shipping costs.
                        </Typography>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-3 mt-lg-2 mb-3 mx-0">
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                  <h3 className="mb-0">List of Documents</h3>
                  <UploadDocs
                    fetchData={fetchData}
                    csvId={id}
                    uploadCSV={false}
                    requestType={"ORDER_REQUEST"}
                  />
                </div>
                <div
                  className="inventory-doc-table"
                  style={{ height: 350, width: "100%" }}
                >
                  <DataGrid
                    localeText={localeText}
                    rows={orderRequestDocs}
                    columns={columnsForDoc}
                    className="data-grid"
                    hideFooterPagination
                  />
                </div>
              </div>
              <div className="col-xl-6 mb-3 mb-xl-0">
                <div className="recentActivity">
                  <div className="title-bar d-flex justify-content-between align-items-center mb-3">
                    <h3 className="mb-2">Recent Activity</h3>
                  </div>
                  <div>
                    <HistoryStepper historyData={historyData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="msgContainerBox">
            {isOpenEditSection && (<EditRequest requestType="ORDER_REQUEST" id={orderItemId} refreshData={fetchData}/>)}
           {isMessageBoxOpen &&  (<ContainerMessageBox requestType={"ORDER_REQUEST"} />)}
          </div>
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          <Modal.Title>Update Order Status</Modal.Title>
          <p className="modal-text">
            Are you sure, you want to change status to Rejected ?
          </p>
        </Modal.Header>
        <Modal.Body>
          <div className="form__inner">
          <textarea
            className={`form-control ${error ? "is_invalid" : ""}`}
            aria-label="With textarea"
            value={rejectionNoteValue}
            onChange={handleInputChange}
            rows={8}
            style={{resize:"none"}}
            placeholder="Enter text here..."
          />
          {error && <div className="error-message">{error}</div>}

          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="btn-group row d-flex justify-content-between w-100">
            <div className="col-6 ps-0">
              <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                No
              </button>
            </div>

            <div className="col-6 pe-0">
              <button className="primary-btn w-100" onClick={changeOrderStatus} disabled={isLoading}>
               {isLoading && <Spinner/>} Yes
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default OrderDetail;
