import React, { FC, useState } from "react";
import { useHistory } from "react-router-dom";
import { RouteComponentProps, useParams } from "react-router";
import { CommonService } from "../../Service/CommonService";
import { Service } from "../../Service/ApiService";
import Logo from "../Logo/Logo";
import { IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../Dynamic/AddCompanyOrUser.scss";
import PasswordStrength from "../Dynamic/PasswordStrength";
import Spinner from "../Common/Spinner";
import { FaEye, FaEyeSlash } from "react-icons/fa";

type SomeComponentProps = RouteComponentProps;

const SetPassword: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [passwordError, setPasswordError] = useState(null);
  const [confirmPasswordError, setConfirmPasswordError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const routeBack = () => {
    history.goBack();
  };

  const [resetObj, setResetObj] = useState({
    password: "",
    confirmPassword: "",
    id: id,
  });

  const handleChange = (e: any) => {
    console.log(e);
    const { name, value } = e.target;
    setResetObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePasswordChange = (e: any) => {
    setPasswordError("");
    if (e.target.value === resetObj.confirmPassword) {
      setConfirmPasswordError("");
    }
    handleChange(e);
  };

  const handleConfirmPasswordChange = (e: any) => {
    setConfirmPasswordError("");
    handleChange(e);
  };

  const passwordOnBlur = (e:any) => {
      if(e.target.value.trim().length === 0) {
        setPasswordError("please enter password");
      }
      else if(e.target.value.trim().length < 6) {
         setPasswordError("Password must be at least 6 characters long");
      }
  }

 

  const resetPassword = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);
    if (
      resetObj.password.length === 0 ||
      resetObj.confirmPassword.length === 0 ||
      resetObj.confirmPassword !== resetObj.password ||
      passwordError || confirmPasswordError
    ) {
      if (resetObj.password.length === 0) {
        setPasswordError("Please enter password");
      }

      if (
        resetObj.confirmPassword.length === 0 ||
        resetObj.confirmPassword !== resetObj.password
      ) {
        resetObj.confirmPassword.length === 0
          ? setConfirmPasswordError("Please retype your password")
          : setConfirmPasswordError("Passwords do not match");
      }
      setIsLoading(false);
      return;
    }

    try {
      const res = await Service.resetUserPassword(resetObj);
      if (res.data) {
        setIsLoading(false);
        CommonService.Toast.fire({
          title: "Password set successfully!",
          icon: "success",
        });
        history.push("/login");
      }
    } catch (error) {
      setIsLoading(false);
      CommonService.Toast.fire({
        title: "Sorry, User not found!",
        icon: "error",
      });
    }
  };

  return (
    <>
      <header className="wd__header wd__header--user">
        <nav className="navbar navbar-expand-lg bg-body-tertiary">
          <div className="container-fluid">
            <a className="navbar-brand" href="#">
              <Logo size={"200px"} />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
          </div>
        </nav>
      </header>
      <div className="add-company-user-wrapper">
        <div className="d-flex align-items-center mb-3 px-3">
          <div className="back__button" onClick={routeBack}>
            <IconButton
              aria-label="ArrowBackIcon"
              sx={{ backgroundColor: "#FFF" }}
            >
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton>
          </div>
          <div className="title">
            <Typography
              variant="h1"
              component="h2"
              sx={{ ml: 2, fontWeight: 600, fontSize: "25px" }}
            >
              Setup Password
            </Typography>
          </div>
        </div>
        <div className="add-company-user-form-wrapper p-0">
          <div className="login__form register__form mx-auto p-5">
            {/* <div className="d-flex justify-content-center align-items-lg-center">
                        <div className="login__form--outer bg-none px-0 py-2">
                            <div className="row mb-3">
                                <div className="col-12">
                                    <Logo size="150px" />
                                </div>
                            </div>
                        </div>
                    </div> */}
            {/* <div className="row mb-3">
                        <div className="col-12">
                            <h1 className='login__heading text-center'>Setup Password</h1> */}
            {/* <p className='login__text text-center'>Please reset your password here</p> */}
            {/* </div>
                    </div> */}

            <form className="form__inner row d-flex" onSubmit={resetPassword}>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="password" className="form-label">
                  Password :
                </label>
                <div className="position-relative">
                  <input
                    className={`form-control ${
                      passwordError ? "is_invalid" : ""
                    }`}
                    type={showPassword ? "text" : "password"}
                    name="password"
                    value={resetObj.password}
                    placeholder="Enter password"
                    onChange={handlePasswordChange}
                    onBlur={passwordOnBlur}
                  />
                  <button
                    className="btn text-secondary position-absolute top-50 end-0 translate-middle-y"
                    type="button"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
                {resetObj.password && (
                  <PasswordStrength password={resetObj.password} />
                )}
                {passwordError && (
                  <div className="error-message">{passwordError}</div>
                )}
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label htmlFor="confirmPassword" className="form-label">
                  Re-enter Password :
                </label>
                <div className="position-relative">
                  <input
                    placeholder="Re-enter password"
                    className={`form-control ${
                      confirmPasswordError ? "is_invalid" : ""
                    }`}
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    value={resetObj.confirmPassword}
                    onChange={handleConfirmPasswordChange}
                  />
                  <button
                    className="btn text-secondary position-absolute top-50 end-0 translate-middle-y"
                    type="button"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? <FaEye /> : <FaEyeSlash />}
                  </button>
                </div>
                {!confirmPasswordError &&
                  resetObj.confirmPassword === resetObj.password &&
                  resetObj.password &&
                  resetObj.confirmPassword && (
                    <div className="mt-1 text-success">Matched</div>
                  )}
                {confirmPasswordError && (
                  <div className="error-message">{confirmPasswordError}</div>
                )}
              </div>
              <div className="col-12 mt-3">
                <div className="form__btn--group d-flex flex-wrap justify-content-center">
                  <div className="col-auto">
                    <button
                      type="submit"
                      className="btn primary-btn register-btn mb-2"
                      disabled={isLoading}
                    >
                      {isLoading && <Spinner />} Reset Password
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>

        {/* <div className="usersList mt-5">
            <div className="usersList__header--wrapper d-flex flex-wrap justify-content-between align-items-center mb-2">
                <div className="header--title mb-2 me-2">
                    <h1 className="title mb-0">
                        Setup Password <i className="bi bi-heart-fill"></i>
                    </h1>
                </div>
                <div className="main-section">
                    <div className="login-container">
                        <form onSubmit={resetPassword}>
                            <label htmlFor="password">Password :</label>
                            <input type="password" name="password" value={resetObj.password} onChange={handleChange} required />

                            <label htmlFor="confirmPassword">Re-enter Password:</label>
                            <input type="password" name="confirmPassword" value={resetObj.confirmPassword} onChange={handleChange} required />

                            <button type="submit">Reset Password</button>
                        </form>
                    </div>
                </div>
            </div>
        </div> */}
      </div>
    </>
  );
};

export default SetPassword;
