import { Stepper, Step, StepLabel, StepContent, Paper } from '@mui/material';
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import { CommonService } from '../../Service/CommonService';

interface ChildProps {
    historyData: any[];
}

export const HistoryStepper: React.FC<ChildProps> = ({ historyData }) => {
    return (
        <Paper elevation={3} style={{ padding: '16px', margin: '16px' }}>
            {historyData.map((historyGroup: any, groupIndex: any) => (
                <div key={groupIndex} style={{ display: 'flex', marginBottom: '16px' }}>
                    <Typography style={{ marginRight: '16px' }}>{new Date(historyGroup.date).toLocaleDateString('en-US', { day: '2-digit', month: 'short', year: 'numeric' })}</Typography>
                    <Stepper orientation="vertical">
                        <Step active>
                            <StepLabel></StepLabel>
                            <StepContent>
                                {historyGroup.histories.map((history: any, index: any) => (
                                    <div key={index} style={{ marginBottom: '16px' }}>
                                        <Typography variant="subtitle1">
                                            <b>{CommonService.formatRecentActivityTime(history.createdDate)}{" "}</b>
                                            {/* <b>{new Date(history.createdDate).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' })} </b>  */}
                                            {history.firstName} {history.lastName} |{" "} {history.description || 'No description provided'}
                                        </Typography>
                                    </div>
                                ))}
                            </StepContent>
                        </Step>
                    </Stepper>
                </div>
            ))}
        </Paper>
    )
}