import { ListFormat } from 'typescript';
import AxiosApiService from './AxiosService';
import { Url } from '../Constants/Url';

export const InventoryApiService = {
    getInventoryCSV, readCSV, updateInventoryCSV, downloadCSV, getInventorySellRequestWithDocs, downloadZipWithDocs, downloadDoc,
    getCsvBySellReqId, getSellReqById, getCsvHistoryBySellReqId, getCsvDocBySellReqId, showPDF, getInventorySellRequestsByCompanyId,
    showPDFForOrder,getMyInventories,getInventoryById,editInventory, getInventoryChangeRequests, getInventoryChangeRequestById, 
    updateInventoryChangeRequestStatus, getAllInventoryChangeRequests
}

async function getInventoryCSV() {
    return await AxiosApiService.get<Object>(Url.GET_INVENTORY_CSV);
}

async function getCsvBySellReqId(id : any) {
    let path = Url.GET_CSVs_BY_SELL_REQUEST_ID + "/" + id;
    return await AxiosApiService.get<Object>(path);
}

async function getCsvHistoryBySellReqId(id : any) {
    let path = Url.GET_HISTORY_BY_SELL_REQUEST_ID + "/" + id;
    return await AxiosApiService.get<Object>(path);
}

async function getCsvDocBySellReqId(id : any) {
    let path = Url.GET_DOCS_BY_SELL_REQUEST_ID + "/" + id;
    return await AxiosApiService.get<Object>(path);
}

async function getSellReqById(id : any) {
    let path = Url.GET_SELL_REQ_BY_ID_WITH_COMPANY + "/" + id;
    return await AxiosApiService.get<Object>(path);
}

async function readCSV(csvId: any) {
    return await AxiosApiService.get<Object>(Url.READ_CSV + '/' + csvId);
}

async function updateInventoryCSV(formData: any) {
    return await AxiosApiService.put<any>(Url.UPDATE_INVENTORY_CSV, formData);
}

async function downloadCSV(csvData: any) {
    return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_CSV, csvData);
}

async function getInventorySellRequestWithDocs(csvId: any) {
    return await AxiosApiService.get<Object>(Url.GET_INVENTORY_CSV_WITH_DOCS + '/' + csvId);
}

async function downloadZipWithDocs(csvId: any) {
    return await AxiosApiService.getZip<Object>(Url.DOWNLOAD_ZIP_WITH_DOCS + '/' + csvId);
}

async function downloadDoc(downloadDoc: any) {
    return await AxiosApiService.postBlob<Object>(Url.DOWNLOAD_DOC, downloadDoc);
}

async function showPDF(id:any, companyId:any, siteId:any, fileName:any) {
    return await AxiosApiService.getBlob<Object>(`${Url.SHOW_PDF_URL}?id=${id}&companyId=${companyId}&siteId=${siteId}&fileName=${fileName}`);
}

async function showPDFForOrder(id:any, companyId:any, siteId:any, fileName:any) {
    return await AxiosApiService.getBlob<Object>(`${Url.SHOW_PDF_FOR_ORDER_URL}?id=${id}&companyId=${companyId}&siteId=${siteId}&fileName=${fileName}`);
}

async function getInventorySellRequestsByCompanyId(companyId: any) {
    return await AxiosApiService.get<Object>(Url.GET_COMPANY_WISE_SELL_REQUEST + '/' + companyId);
}

async function getMyInventories(service) {
    return await AxiosApiService.get<Object>(Url.GET_MY_INVENTORIES + '?serviceType='+service);
}

async function getInventoryById(inventoryId) {
    return await AxiosApiService.get<Object>(Url.GET_INVENTORY_BY_ID + inventoryId);
}

async function editInventory(inventoryData) {
    return await AxiosApiService.post<Object>(Url.EDIT_INVENTORY,inventoryData);
}

async function getInventoryChangeRequests(companyId: any, isFetchOpenRequests: any) {
    return await AxiosApiService.get<Object>(Url.GET_INVENTORY_CHANGE_REQUESTS + '/' + companyId + '/' + isFetchOpenRequests);
}

async function getInventoryChangeRequestById(id: any) {
    return await AxiosApiService.get<Object>(Url.GET_INVENTORY_CHANGE_REQUEST_BY_ID + '/' + id);
}

async function updateInventoryChangeRequestStatus(statusDetails: any) {
    return await AxiosApiService.put<any>(Url.UPDATE_INVENTORY_CHANGE_REQUEST_STATUS, statusDetails)
}

async function getAllInventoryChangeRequests() {
    return await AxiosApiService.get<Object>(Url.GET_ALL_INVENTORY_CHANGE_REQUESTS);
}