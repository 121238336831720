import Cookies from "universal-cookie";

const cookies = new Cookies();

export const CookieService = {
  setAuthToken: (key: string, value: string) => {
    // Set the token in cookies
    cookies.set(key, value, { path: '/' });
    localStorage.setItem(key, value);
  },

  removeAuthToken: () => {
    // Remove the token from cookies
    const cookies = new Cookies();
    const allCookies = cookies.getAll();

    for (const cookieName of Object.keys(allCookies)) {
      // Set each cookie's expiration to a past date to remove it
      cookies.remove(cookieName, { path: '/', expires: new Date(0) });
    }

    //remove item from local storage 
    localStorage.clear();

  },

  getCookie: (key: string) => {
    return cookies.get(key);
  }
}