import { faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Service } from '../../Service/ApiService';
import { CommonService } from '../../Service/CommonService';
import Tooltip from '@mui/material/Tooltip';
import Spinner from '../Common/Spinner';

interface ChildProps {
    approveUser: any;
    displayIcon: boolean;
    reloadTable: any;
}

const SingleApprove: React.FC<ChildProps> = ({ approveUser, displayIcon, reloadTable }) => {

    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const [selectedUserName, setSelectedUserName] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const [isApproved, setisApproved] = useState(false);
    const handleClose = () => {
        setisApproved(false);
        reloadTable();
        setShow(false)
    };

    useEffect(() => {
        if (approveUser.length) {
            let c = approveUser.map((user: { email: any; }) => { return user.email })
            setSelectedUserName(c);
        }
    }, [approveUser]);

    const handleSubmit = () => {
        if (approveUser.length) {
            setIsLoading(true);
            // Prepare an array to store updated users
            const updatedUsers: any[] = [];

            // Map over each user in the approveUser list and update their status
            approveUser.forEach((user: any) => {
                const updatedUser = { ...user, userStatus: "ACCEPTED" };
                updatedUsers.push(updatedUser);
            });

            Service.updateUserProfile(updatedUsers)
                  .then(response => {
                    setIsLoading(false);
                    setisApproved(true)
                  })
                  .catch(error => {
                    setIsLoading(false);
                    console.log(error);
                  });
        }
        
    }

    return (
        <>
            {/* <Button variant="primary" onClick={handleShow}>
        Launch demo modal
      </Button> */}
            {displayIcon && 
            <Tooltip title="Accept" arrow>
            <IconButton onClick={handleShow}>
                <FontAwesomeIcon icon={faUserCheck} />
            </IconButton></Tooltip>}

            {!displayIcon && 
            <Tooltip title="Accept" arrow>
            <button onClick={handleShow} disabled={approveUser.length === 0} 
            style={{border:'none', background:'transparent', cursor: approveUser.length === 0 ? 'not-allowed' : 'pointer',}}> Accept </button></Tooltip>}

            <Modal show={show} onHide={handleClose} className='modal__wd modal__wd--submitSellReq'>
                <Modal.Header className='flex-column align-items-start'>
                    <Modal.Title>Accept User</Modal.Title>
                    
                    {!isApproved && (
                        <p className="modal-text text-start">
                            Are you sure, you want to accept this user?</p>
                    )}
                    {isApproved && (
                        <p className="modal-text text-start">
                            User have been accepted successfully !</p>
                    )}
                </Modal.Header>
                {selectedUserName && selectedUserName.length &&
                <Modal.Body>
                    <ul>
                        {/* Using map to iterate over the array and generate list items */}
                        {selectedUserName.map((item, index) => (
                            <li key={index}>{item}</li>
                        ))}
                    </ul>
                </Modal.Body>}
                <Modal.Footer className='justify-content-center'>
                    <div className="btn-group row d-flex justify-content-between w-100">
                        <div className={isApproved ? "col-12" : "col-6 ps-0"}>
                            <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                                {!isApproved && ('No')}
                                {isApproved && ('Close')}
                            </button>
                        </div>
                        {!isApproved && (
                            <div className="col-6 pe-0">
                                <button className="primary-btn w-100" onClick={handleSubmit} disabled={isLoading}>
                                 {isLoading && <Spinner/>} Yes
                                </button>
                            </div>
                        )}
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default SingleApprove;