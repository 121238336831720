import { Typography } from "@mui/material"
import footerLogo from "../../assets/images/logo-horizontal-white-dog.svg"
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { Form } from "react-bootstrap";

const Footer = () => {
    return (
        <div className="footer-wrapper">
            <div className="container">
                <div className="footer">
                    <div className="row d-flex justify-content-center justify-content-md-between align-items-center">
                        <div className="col-auto">
                            <div className="footer-left-part">
                                <img src={footerLogo} alt="footerLogo" className="img-fluid" />
                            </div>
                        </div>
                        <div className="col-auto my-1">
                            <div className="footer-right-part d-flex align-items-center">
                                <div className="me-2">
                                    <Typography variant="body2" sx={{ color: '#FFF', fontSize: '14px' }}>
                                        Customer Support
                                    </Typography>
                                </div>
                                <div className="me-2">
                                    <Typography variant="body2" sx={{ color: '#B7A577', fontSize: '14px', fontWeight: 700 }}>
                                        FOLLOW US
                                    </Typography>
                                </div>
                                <div>
                                    <FacebookRoundedIcon sx={{ color: '#FFFFFF', marginInline: '4px' }} />
                                    <TwitterIcon sx={{ color: '#FFFFFF', marginInline: '4px' }} />
                                    <InstagramIcon sx={{ color: '#FFFFFF', marginInline: '4px' }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row my-1">
                        <hr className="border border-light-subtle my-2" />
                    </div>
                    <div className="d-flex flex-wrap justify-content-center text-white g-2">
                        <span className="my-2 text-center">
                            © White Dog Trading Post. All rights reserved.
                        </span>
                        <span className="d-inline-flex flex-wrap justify-content-center my-1">
                            <span className="text-nowrap mx-2 my-1">Terms</span>
                            <span className="text-nowrap mx-2 my-1">Privacy</span>
                            <span className="text-nowrap mx-2 my-1">Cookie Policy</span>
                            <span className="text-nowrap mx-2 my-1">Accessibility Statement</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer