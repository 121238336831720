import React from 'react';
import { Typography } from "@mui/material";
import groupDiscussionMeetingIcon from '../../assets/images/icons/group-discussion-meeting.svg';

const NoGroupMessage = () => {
  return (
    <div className='d-flex flex-column align-items-center justify-content-center my-3'>
      <img src={groupDiscussionMeetingIcon} alt="groupDiscussionMeetingIcon" />
      <Typography variant="body2" sx={{ color: '#737376', fontSize: '16px', fontWeight: 600 }}>
        No conversation made
      </Typography>
    </div>
  )
}

export default NoGroupMessage