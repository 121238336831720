import { faCartShopping, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Tooltip } from '@mui/material';
import { JSXElementConstructor, Key, ReactElement, ReactNode, ReactPortal, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { InventoryApiService } from '../../Service/InventoryApiService';
import { CommonService } from '../../Service/CommonService';
import { CookieService } from '../../Service/CookieService';
import Spinner from '../Common/Spinner';

interface ChildProps {
    inventoryCsv: any;
    fetchData: any;
    isFromDropdown: boolean;
    popupShow: boolean
}

type FormValue = {
    status: string,
}

const SinglePublishCSV: React.FC<ChildProps> = ({ inventoryCsv, fetchData, isFromDropdown, popupShow}) => {
    const userRole = CookieService.getCookie("userRole");
    const [show, setShow] = useState(false);
    const [formData, setFormData] = useState<FormValue>({ status: 'FINALIZED' });
    const [isApproved, setisApproved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleClose = () => {
        setisApproved(false);
        fetchData();
        setFormData({ status: 'FINALIZED' });
        setShow(false)
    };
    const handleShow = () => setShow(true);

    useEffect(() => {
        console.log("called single approve CSV");
        console.log(inventoryCsv);
        
        setShow(popupShow);

    }, [inventoryCsv]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        setIsLoading(true);
        const updatedCSV = {
            ...inventoryCsv,
            status: userRole == "ADMIN" ? 'FINALIZED' : 'PUBLISHED', // Replace someCondition with your actual condition
            id: inventoryCsv.id
        };

        InventoryApiService.updateInventoryCSV(updatedCSV)
            .then(response => {
                setIsLoading(false);
                setisApproved(true)
            })
            .catch(error => {
                setIsLoading(false);
                console.log(error);
            })
    }

    return (
        <>
            {/* {isFromDropdown && <div onClick={handleShow}>Publish</div>} */}
            {!isFromDropdown && <Tooltip title="Publish" arrow>
                <IconButton onClick={handleShow}>
                    <FontAwesomeIcon icon={faCartShopping} />
                </IconButton>
            </Tooltip>}
            <Modal show={show} onHide={handleClose} className='modal__wd modal__wd--submitSellReq'>
                <Modal.Header className='flex-column align-items-start'>
                    <Modal.Title>Publish CSV</Modal.Title>

                    {!isApproved && (
                        <p className="modal-text text-start">
                            Are you sure want to publish the products for this CSV</p>
                    )}
                    {isApproved && (
                        <p className="modal-text text-start">
                            The products have been successfully released, and users can now access and view them</p>
                    )}

                </Modal.Header>

                <Modal.Footer className="justify-content-center bg-transparent ">
                    <div className="btn-group row d-flex justify-content-between w-100">
                        <div className={isApproved ? "col-12" : "col-6 ps-0"}>
                            <button className="secondary-btn w-100" onClick={handleClose} disabled={isLoading}>
                                {!isApproved && ('Cancel')}
                                {isApproved && ('Close')}
                            </button>
                        </div>
                        {!isApproved && (
                            <div className="col-6 pe-0">
                                <button className="primary-btn w-100" onClick={handleSubmit} disabled={isLoading}>
                                  {isLoading && <Spinner/>} Publish
                                </button>
                            </div>
                        )}
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    );
}

export default SinglePublishCSV;