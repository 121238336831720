import React, { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Service } from "../../Service/ApiService";
import { CommonService } from "../../Service/CommonService";
import useFormValidation from "./FormValidation";
import Logo from "../Logo/Logo";
import "../Static/Login/Login.scss";
import "./AddCompanyOrUser.scss";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton, Typography } from "@mui/material";
import Spinner from "../Common/Spinner";
import { Dropdown } from "react-bootstrap";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PasswordStrength from "./PasswordStrength";
import InputMask from "react-input-mask";
interface AddEntityProps {
  type: "user" | "company";
}

const checkPasswordStrength = (password) => {
  if (password.length < 6) return "Weak";
  if (password.length < 10) return "Medium";
  return "Strong";
};

const AddCompanyOrUser: React.FC<AddEntityProps> = ({ type }) => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [CompaniesList, setCompaniesList] = useState([]);
  const [showPasswordFields, setShowPasswordFields] = useState("no");
  const [selected, setSelected] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [isValidZipcode, setValidZipcode] = useState(false);
  const [isZipcodeLoading, setZipcodeLoading] = useState(false);

  const initialFormData = {
    firstName: "",
    lastName: "",
    birthdate: "",
    zipcode: "",
    phoneNumber: "",
    email: "",
    password: "",
    retypePassword: "",
    companyName: "",
    companyEmail: "",
    contactNumber: "",
    contactPerson: "",
    companyId: "",
    siteId: id,
    roleId: "",
    userStatus: "ACCEPTED",
    city: "",
    state: "",
    streetAddress: "",
  };

  const {
    formData,
    errors,
    handleChange,
    handleBlur,
    setErrors,
    validateForm,
    setFormData,
  } = useFormValidation(initialFormData);

  useEffect(() => {
    fetchExistingCompanies();
  }, []);

  const fetchExistingCompanies = async () => {
    try {
      const response = await Service.fetchCompaniesList();
      setCompaniesList(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  // Toggle confirm password visibility
  const toggleConfirmPasswordVisibility = () => {
    setShowRetypePassword(!showRetypePassword);
  };

  const validateZipcode = async (zipcode: string): Promise<boolean> => {
    setZipcodeLoading(true);
    try {
      const response = await Service.getCityAndState(zipcode);
      const { city, state } = response.data.zipCode;

      if (city && state) {
        setFormData((prevState) => ({
          ...prevState,
          city,
          state,
          zipcode,
        }));
        setErrors((prevErrors) => ({ ...prevErrors, zipcode: "" }));
        setValidZipcode(true);
        return true;
      } else {
        setFormData((prevState) => ({
          ...prevState,
          city: "",
          state: "",
          zipcode,
        }));
        setErrors((prevErrors) => ({
          ...prevErrors,
          zipcode: "Please enter a valid zipcode",
        }));
        setValidZipcode(false);
        return false;
      }
    } catch (error) {
      setFormData((prevState) => ({
        ...prevState,
        city: "",
        state: "",
        zipcode,
      }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        zipcode: "Please enter a valid zipcode",
      }));
      setValidZipcode(false);
      return false;
    } finally {
      setZipcodeLoading(false);
    }
  };

  const handleZipcodeBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const zipcode = e.target.value;
    await validateZipcode(zipcode);
  };

  const routeBack = () => {
    history.goBack();
  };

  // Validate user fields
  const validateUserFields = async (): Promise<boolean> => {
    let isValidationPass = true;

    const requiredFields = [
      { field: "firstName", error: "Please enter first name" },
      { field: "birthdate", error: "Please enter birthdate" },
      { field: "email", error: "Please enter email" },
      { field: "companyName", error: "Please enter company name" },
    ];

    requiredFields.forEach(({ field, error }) => {
      if (formData[field].trim().length === 0 || errors[field]) {
        if (formData[field].trim().length === 0) {
          setErrors((prevState) => ({ ...prevState, [field]: error }));
        }
        isValidationPass = false;
      }
    });

    if (showPasswordFields === "yes") {
      if (formData.password.trim().length === 0 || errors.password) {

        if(formData.password.trim().length === 0) {
        setErrors((prevState) => ({
          ...prevState,
          password: "Please enter password",
        }));
      } else if(formData.password.trim().length > 0 && formData.password.trim().length < 6){
        setErrors((prevState) => ({
          ...prevState,
          password: "Password must be at least 6 characters long",
        }));
      }
        isValidationPass = false;
      }

      if (
        formData.retypePassword.length === 0 ||
        formData.retypePassword !== formData.password
      ) {
        setErrors((prevState) => ({
          ...prevState,
          retypePassword:
            formData.retypePassword.length === 0
              ? "Please retype your password"
              : "Passwords do not match",
        }));
        isValidationPass = false;
      }
    } else {
      setFormData((prevState) => ({
        ...prevState,
        password: "",
        retypePassword: "",
      }));
    }

    if (formData.zipcode.length === 0 || errors.zipcode) {
      if (formData.zipcode.length === 0) {
        setErrors((prevState) => ({
          ...prevState,
          zipcode: "Please enter zipcode",
        }));
      }
      isValidationPass = false;
    }

    return isValidationPass;
  };

  const dateInputRef = useRef(null);

  const handleDateClick = () => {
    if (dateInputRef.current) {
      dateInputRef.current?.showPicker(); // This will trigger the date picker to open
    }
  };

  

  // Validate company fields
  const validateCompanyFields = async (): Promise<boolean> => {
    let isValidationPass = true;

    const requiredFields = [
      { field: "companyName", error: "Please enter company name" },
      { field: "companyEmail", error: "Please enter company email" },
    ];

    requiredFields.forEach(({ field, error }) => {
      if (formData[field].trim().length === 0 || errors[field]) {
        if (formData[field].trim().length === 0) {
          setErrors((prevState) => ({ ...prevState, [field]: error }));
        }
        isValidationPass = false;
      }
    });

    return isValidationPass;
  };

  const onSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    const isFormValid =
      type === "user"
        ? await validateUserFields()
        : await validateCompanyFields();

    if (!isFormValid) {
      setIsLoading(false);
      return;
    }

    try {
      let response;
      if (type === "user") {
        response = await Service.addUser(formData);
      } else {
        response = await Service.addCompany(formData);
      }

      if (response.data === true) {
        CommonService.Toast.fire({
          title:
            type === "user"
              ? "User Added Successfully"
              : "Company Added Successfully",
          icon: "success",
        });
        history.push(
          type === "user" ? "/master/user-list" : "/master/company-list"
        );
      } else {
        CommonService.Toast.fire({
          title: "This Email already exists",
          icon: "error",
        });
      }
    } catch (error: any) {
      CommonService.Toast.fire({
        title: error.response.data.error,
        icon: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleCompanySelect = (companyName: string) => {
    const selectedCompany = CompaniesList.find(
      (company) => company.companyName === companyName
    );

    if (selectedCompany) {
      setFormData((prevState) => ({
        ...prevState,
        companyName,
        siteId: selectedCompany.siteId,
        companyId: selectedCompany.id,
      }));
    }
  };

  const handleTogglePasswordFields = (value: "yes" | "no") => {
    setShowPasswordFields(value);
  };

  const getMinAgeDate = (): string => {
    const today = new Date();
    const minAgeYear = today.getFullYear() - 21;
    return `${minAgeYear}-${String(today.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(today.getDate()).padStart(2, "0")}`;
  };

  const minAgeDate = getMinAgeDate();

  return (
    <>
      <div className="add-company-user-wrapper">
        <div className="d-flex align-items-center mb-3 px-3">
          <div className="back__button" onClick={routeBack}>
            <IconButton
              aria-label="ArrowBackIcon"
              sx={{ backgroundColor: "#FFF" }}
            >
              <ArrowBackIcon sx={{ color: "#1C1B1F" }} />
            </IconButton>
          </div>
          <div className="title">
            <Typography
              variant="h1"
              component="h2"
              sx={{ ml: 2, fontWeight: 600, fontSize: "25px" }}
            >
              {type === "user" ? "Add User" : "Add Company"}
            </Typography>
          </div>
        </div>
        <div className="add-company-user-form-wrapper p-0">
          <form
            className="form__inner row d-flex mx-auto py-5"
            onSubmit={onSubmit}
            noValidate
          >
            {type === "user" && (
              <>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="firstName" className="form-label">
                    First Name <span className="star-required text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter first name"
                    value={formData.firstName}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^A-Za-z ]/g, '');
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: value
                        }
                      });
                    }}
                    onBlur={handleBlur}
                    className={`form-control ${
                      errors.firstName ? "is_invalid" : ""
                    }`}
                  />
                  {errors.firstName && (
                    <div className="error-message">{errors.firstName}</div>
                  )}
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="lastName" className="form-label">
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    placeholder="Enter last name"
                    name="lastName"
                    value={formData.lastName}
                    onChange={(e) => {
                      const value = e.target.value.replace(/[^A-Za-z ]/g, '');
                      handleChange({
                        target: {
                          name: e.target.name,
                          value: value
                        }
                      });
                    }}
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="birthdate" className="form-label">
                    Birthdate (Must be 21+ years) <span className="star-required text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    id="birthdate"
                    name="birthdate"
                    placeholder="Birthdate"
                    value={formData.birthdate}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    max={minAgeDate}
                    // ref={dateInputRef}
                    onClick={handleDateClick}
                    className={`form-control ${
                      errors.birthdate ? "is_invalid" : ""
                    }`}
                  />
                  {errors.birthdate && (
                    <div className="error-message">{errors.birthdate}</div>
                  )}
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="zipcode" className="form-label">
                    Zip Code <span className="star-required text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="zipcode"
                    name="zipcode"
                    placeholder="Enter zipcode"
                    value={formData.zipcode}
                    onChange={handleChange}
                    onBlur={handleZipcodeBlur}
                    className={`form-control ${
                      errors.zipcode ? "is_invalid" : ""
                    }`}
                  />
                  {errors.zipcode && (
                    <div className="error-message">{errors.zipcode}</div>
                  )}
                  {formData.city && formData.state && (
                    <p className="mt-1 mb-0">
                      {formData.city + ", " + formData.state}
                    </p>
                  )}
                </div>

                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="email" className="form-label">
                    Email Address <span className="star-required text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter email ID"
                    value={formData.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      errors.email ? "is_invalid" : ""
                    }`}
                  />
                  {errors.email && (
                    <div className="error-message">{errors.email}</div>
                  )}
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="phoneNumber" className="form-label">
                    Phone Number
                  </label>
                  <InputMask
                    mask="(999) 999-9999"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  >
                    {(inputProps) => (
                      <input
                        type="text"
                        id="phoneNumber"
                        name="phoneNumber"
                        placeholder="Enter phone number"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </div>

                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="streetAddress" className="form-label">
                    Street Address
                  </label>
                  <input
                    type="text"
                    id="streetAddress"
                    name="streetAddress"
                    placeholder="Enter street address"
                    value={formData.streetAddress}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                  />
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="companyName" className="form-label">
                    Select Company <span className="star-required text-danger">*</span>
                  </label>
                  <Dropdown
                    className={`select-company-dropdown from-control w-100 ${
                      errors.companyName ? "is_invalid" : ""
                    }`}
                    onSelect={(selectedCompanyName) => {
                      handleChange({
                        target: {
                          name: "companyName",
                          value: selectedCompanyName,
                        },
                      });
                      handleCompanySelect(selectedCompanyName);
                    }}
                  >
                    <Dropdown.Toggle
                      variant="none"
                      id="dropdown-basic"
                      className={`d-flex justify-content-between text-start border border-light-subtle bg-white w-100 ${
                        errors.companyName ? "is_invalid" : ""
                      }`}
                    >
                      {formData.companyName || "Select Company"}
                      <ExpandMoreRoundedIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {CompaniesList.map((company: any) => (
                        <Dropdown.Item
                          key={company.id}
                          eventKey={company.companyName}
                        >
                          {company.companyName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                  {errors.companyName && (
                    <div className="error-message">{errors.companyName}</div>
                  )}
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="barrelBroker" className="form-label">
                    Is user Barrel Broker?
                  </label>
                  <Dropdown
                    className="select-company-dropdown w-100"
                    onSelect={(eventKey) => {
                      setSelected(eventKey);
                      handleChange({
                        target: { name: "barrelBroker", value: eventKey },
                      });
                    }}
                  >
                    <Dropdown.Toggle
                      variant="none"
                      id="barrel-broker-dropdown"
                      className="d-flex justify-content-between text-start border border-light-subtle bg-white w-100"
                    >
                      {selected || "Select Option"}
                      <ExpandMoreRoundedIcon />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="Yes">Yes</Dropdown.Item>
                      <Dropdown.Item eventKey="No">No</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            )}

            {type === "company" && (
              <>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="companyName" className="form-label">
                    Company Name <span className="star-required text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    value={formData.companyName}
                    placeholder="Enter company name"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      errors.companyName ? "is_invalid" : ""
                    }`}
                  />
                  {errors.companyName && (
                    <div className="error-message">{errors.companyName}</div>
                  )}
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="companyEmail" className="form-label">
                    Company Email <span className="star-required text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    id="companyEmail"
                    name="companyEmail"
                    placeholder="Enter company email"
                    value={formData.companyEmail}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={`form-control ${
                      errors.companyEmail ? "is_invalid" : ""
                    }`}
                  />
                  {errors.companyEmail && (
                    <div className="error-message">{errors.companyEmail}</div>
                  )}
                </div>
                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="contactNumber" className="form-label">
                    Contact Number
                  </label>
                  <InputMask
                    mask="(999) 999-9999"
                    value={formData.contactNumber}
                    onChange={handleChange}
                  >
                    {(inputProps) => (
                      <input
                        type="text"
                        id="contactNumber"
                        name="contactNumber"
                        value={formData.contactNumber}
                        placeholder="Enter company contact number"
                        className="form-control"
                        {...inputProps}
                      />
                    )}
                  </InputMask>
                </div>

                <div className="col-12 col-sm-6 mb-3">
                  <label htmlFor="contactPerson" className="form-label">
                    Contact Person
                  </label>
                  <input
                    type="text"
                    id="contactPerson"
                    name="contactPerson"
                    value={formData.contactPerson}
                    placeholder="Enter company contact person"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="form-control"
                    // className={`form-control ${errors.contactPerson ? "is_invalid" : ""}`}
                  />
                  {/* {errors.contactPerson && <div className="error-message">{errors.contactPerson}</div>} */}
                </div>
              </>
            )}

            {type === "user" && (
              <>
                <div className="col-12 mb-3">
                  <div className="form-check ps-0">
                    <label
                      className="form-check-label position-relative my-3 w-100"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      <span className="d-inline-block position-relative pe-3 py-2 bg-white z-2">
                        Set Password 
                      </span>
                      <hr className="position-absolute top-50 m-0 w-100 z-1" />
                    </label>
                    <div className="d-flex align-items-center">
                      <div className="d-inline-flex">
                        <input
                          type="radio"
                          name="setPassword"
                          id="setPassword2"
                          className="custom-radio"
                          value="no"
                          checked={showPasswordFields === "no"}
                          onChange={() => handleTogglePasswordFields("no")}
                        />
                        <label
                          className="custom-radio-label"
                          htmlFor="setPassword2"
                        >
                          No
                        </label>
                      </div>
                      <div className="d-inline-flex ms-4 me-2">
                        <input
                          type="radio"
                          name="setPassword"
                          id="setPassword1"
                          className="custom-radio"
                          value="yes"
                          checked={showPasswordFields === "yes"}
                          onChange={() => handleTogglePasswordFields("yes")}
                        />
                        <label
                          className="custom-radio-label"
                          htmlFor="setPassword1"
                        >
                          Yes
                        </label>
                      </div>
                      <label
                        className="form-check-label ms-2"
                        htmlFor="flexSwitchCheckDefault"
                      >
                        <InfoOutlinedIcon
                          fontSize="small"
                          sx={{ color: "#525E9D", mr: 1 }}
                        />
                        {showPasswordFields === "no" && (
                          <Typography
                            variant="body2"
                            sx={{
                              display: "inline",
                              color: "#737376",
                              fontSize: "15px",
                            }}
                          >
                            An email will be sent to verify and set password
                          </Typography>
                        )}
                        {showPasswordFields === "yes" && (
                          <Typography
                            variant="body2"
                            sx={{
                              display: "inline",
                              color: "#737376",
                              fontSize: "15px",
                            }}
                          >
                            This account will be automatically verified
                          </Typography>
                        )}
                      </label>
                    </div>
                  </div>
                </div>

                {showPasswordFields === "yes" && (
                  <>
                    <div className="col-12 col-sm-6 mb-3">
                      <label htmlFor="password" className="form-label">
                        Enter Password <span className="star-required text-danger">*</span>
                      </label>
                      <div className="position-relative">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          value={formData.password}
                          onChange={(e) => {
                            handleChange(e);
                            setPasswordStrength(
                              checkPasswordStrength(e.target.value)
                            );
                          }}
                          placeholder="Password"
                          onBlur={handleBlur}
                          className={`form-control ${
                            errors.password ? "is_invalid" : ""
                          }`}
                        />
                        <button
                          className="btn text-secondary position-absolute top-50 end-0 translate-middle-y"
                          type="button"
                          onClick={togglePasswordVisibility}
                        >
                          {showPassword ? <FaEye /> : <FaEyeSlash />}
                        </button>
                      </div>
                      {formData.password && (
                        <PasswordStrength password={formData.password} />
                      )}
                      {/* {passwordStrength && (
                                        <div className={`mt-1 text-${passwordStrength.toLowerCase()}`}>
                                            Strength: {passwordStrength}
                                        </div>
                                    )} */}
                      {errors.password && (
                        <div className="error-message">{errors.password}</div>
                      )}
                    </div>

                    <div className="col-12 col-sm-6 mb-3">
                      <label htmlFor="retypePassword" className="form-label">
                        Re-Enter Password <span className="star-required text-danger">*</span>
                      </label>
                      <div className="position-relative">
                        <input
                          type={showRetypePassword ? "text" : "password"}
                          id="retypePassword"
                          name="retypePassword"
                          placeholder="Re-type password"
                          value={formData.retypePassword}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className={`form-control ${
                            errors.retypePassword &&
                            (formData.retypePassword !== formData.password ||
                              !formData.password ||
                              !formData.retypePassword)
                              ? "is_invalid"
                              : ""
                          }`}
                        />
                        <button
                          className="btn text-secondary position-absolute top-50 end-0 translate-middle-y"
                          type="button"
                          onClick={toggleConfirmPasswordVisibility}
                        >
                          {showRetypePassword ? <FaEye /> : <FaEyeSlash />}
                        </button>
                      </div>
                      {formData.retypePassword === formData.password &&
                        formData.password &&
                        formData.retypePassword && (
                          <div className="mt-1 text-success">Matched</div>
                        )}
                      {errors.retypePassword &&
                        (formData.retypePassword !== formData.password ||
                          !formData.password ||
                          !formData.retypePassword) && (
                          <div className="error-message">
                            {errors.retypePassword}
                          </div>
                        )}
                    </div>
                  </>
                )}
              </>
            )}

            <div className="col-12 mb-3">
              <div className="form__btn--group row d-flex">
                <div className="col-auto pe-1">
                  <button
                    type="reset"
                    onClick={routeBack}
                    className="btn secondary-btn register-btn mb-2"
                  >
                    Cancel
                  </button>
                </div>
                <div className="col-auto ps-1">
                  <button
                    type="submit"
                    className="btn primary-btn register-btn mb-2"
                    disabled={isLoading}
                  >
                    {isLoading && <Spinner />}
                    {type === "user" ? "Add User" : "Add Company"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddCompanyOrUser;
