// SearchBar.tsx

import SearchIcon from '@mui/icons-material/Search';
interface SearchBarProps {
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
}

const SearchBar: React.FC<SearchBarProps> = ({search, setSearch}) => {
  return (
    <>
          <div className="searchbar__input-container input-wrapper">
              <button className="searchbar__input--icon">
                  <SearchIcon />
              </button>
              <input  type="search" id="gsearch" name="gsearch" className="searchbar__input" placeholder="Search..." value={search}
  onChange={(e) => setSearch(e.target.value)} />
          </div>
    </>
  );
};

export default SearchBar;
