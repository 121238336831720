import SockJS from 'sockjs-client';
import { Stomp } from '@stomp/stompjs';
import { Url } from '../Constants/Url';

class WebSocketService {
  constructor(baseUrl) {
    this.client = null;
    this.callbackMethods = {};
  }

  connect(requestId) {
    console.log(Url.BASE_URL_SOCKET)
    let sock = new SockJS(Url.BASE_URL_SOCKET);
    console.log(sock)
    this.client = Stomp.over(sock);
    console.log(this.client)
    this.client.connect({}, (frame) => {
      console.log('Connected to WebSocket', frame);
      console.log("subscribing to " + requestId);
      this.subscribeToUserChannel(requestId, (notificationDto) => {
        // Handle incoming messages here
        console.log('Received message:', JSON.parse(notificationDto));
        let module = window.location.pathname.replace(/\//g, '');
        this.handleRequest("myModule", JSON.parse(notificationDto));
      });
    });
  }

  disconnect() {
    this.client.disconnect();
  }

  subscribeToUserChannel(requestId, callback) {
    this.client.subscribe(`/topic/` + requestId, (message) => {
      console.log("this is your message" + message);
      callback(message.body);
    });
  }

  subscribe(module, callbackMethod) {
    this.callbackMethods[module] = callbackMethod;
  }

  handleRequest(module, data) {
    if (this.callbackMethods[module]) {
      this.callbackMethods[module](data);
    }
  }
}

const baseUrl = process.env.REACT_APP_SOCK_URL;
const webSocketService = new WebSocketService(baseUrl);

export default webSocketService;

// import SockJS from 'sockjs-client';
// import { Stomp } from '@stomp/stompjs';
// import { Url } from '../Constants/Url';

// class WebSocketService {
//   constructor(baseUrl) {
//     this.client = null;
//     this.callbackMethods = {};
//   }

//   connect(userId) {
//     const sock = new SockJS(Url.BASE_URL_SOCKET);
//     this.client = Stomp.over(sock);
//     this.client.connect({}, (frame) => {
//       console.log('Connected to WebSocket', frame);
//       this.subscribeToUserChannel(userId, (notificationDto) => {
//         console.log('Received message:', JSON.parse(notificationDto));
//         const module = window.location.pathname.replace(/\//g, '');
//         this.handleRequest('notifications', JSON.parse(notificationDto));
//       });
//     });
//   }

//   disconnect() {
//     if (this.client !== null) {
//       this.client.disconnect();
//       this.client = null;
//     }
//   }

//   subscribeToUserChannel(userId, callback) {
//     this.client.subscribe(`/topic/user/${userId}`, (message) => {
//       callback(message.body);
//     });
//   }

//   subscribe(module, callbackMethod) {
//     this.callbackMethods[module] = callbackMethod;
//   }

//   handleRequest(module, data) {
//     if (this.callbackMethods[module]) {
//       this.callbackMethods[module](data);
//     }
//   }
// }

// const baseUrl = process.env.REACT_APP_SOCK_URL || Url.BASE_URL_SOCKET;
// const webSocketService = new WebSocketService(baseUrl);

// export default webSocketService;

