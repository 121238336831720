import React, { useEffect, useState } from "react";
import "./OrderSuccess.scss";
import EmptyCartIcon from "../../assets/images/icons/empty-cart-svgrepo-com 1.svg";
import { Typography } from "@mui/material";
import { useHistory, useLocation } from "react-router";

const EmptyCart: React.FC = () => {

  const history = useHistory();

  //redirect to the inventories page
  const redirectInventoryPage = () => {
    history.push(`/user/inventories`);
  };

  return (
    <>
      <div className="success-order-wrapper">
        <img src={EmptyCartIcon} alt="EmptyCartIcon" className="mx-auto mb-3" />
        <p className="text-center">
          <strong>Your cart is currently empty!</strong>
        </p>
        <div className="empty-cart-card">
          <Typography
            variant="body2"
            sx={{
              color: "#333333",
              fontWeight: 400,
              fontSize: "16px",
              lineHeight: "normal",
              textAlign: "center",
            }}
            gutterBottom
          >
            Before proceed to checkout you must add some products to your
            shopping cart. You will find a lot of interesting products on our
            "Shop" page.
          </Typography>
        </div>
        <div className="d-flex justify-content-center mb-5">
          <button
            className="btn primary-btn mx-1"
            onClick={redirectInventoryPage}
          >
            Continue Shopping
          </button>
        </div>
      </div>
    </>
  );
};

export default EmptyCart;
