import { ChangeEvent, useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Button as BootstrapButton, Form } from "react-bootstrap";
import Button from "@mui/material/Button";
import { useHistory } from "react-router";
import { Service } from "../../Service/ApiService";
import DriveFileRenameOutlineOutlinedIcon from "@mui/icons-material/DriveFileRenameOutlineOutlined";
import { OrderApiService } from "../../Service/OrderApiService";
import OpacityIcon from "@mui/icons-material/Opacity";
import Constant from "../../Constant";
import Cookies from "universal-cookie";
import { Tooltip, Typography } from "@mui/material";
import Spinner from "../Common/Spinner";
import { CommonService } from "../../Service/CommonService";
import { error } from "console";

interface SampleOrderProps {
  id: string; // Add the id prop
  inventoryName: string;
  text: boolean;
}

const SampleOrder: React.FC<SampleOrderProps> = ({
  id,
  inventoryName,
  text,
}) => {
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [purpose, setPurpose] = useState<string>("");
  const [size, setSize] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [multipleBarrels, setMultipleBarrels] = useState<string>("");
  const [multiplelots, setMultiplelots] = useState<string>("");
  const [quantity, setQuantity] = useState<any>(0);
  const [descriptionError, setDescriptionError] = useState<string>("");
  const [isSampleOrderRequestCreated, setSampleOrderRequestCreated] =
    useState<boolean>(false);
  const [isSampleOrderRequestUpdated, setSampleOrderRequestUpdated] =
    useState<boolean>(false);
  const [status, setStatus] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //handle close popup
  const handleClose = () => {
    setShow(false);
    setQuantity(0);
    setMultipleBarrels("");
    setMultiplelots("");
    setPurpose("");
    setSize("");
    setDescription("");
    setDescriptionError("");
    setSampleOrderRequestCreated(false);
    setSampleOrderRequestUpdated(false);
    errors.description = "";
    errors.multipleBarrels = "";
    errors.multiplelots = "";
    errors.purpose = "";
    errors.quantity = "";
    errors.size = "";
  };

  const [errors, setErrors] = useState({
    purpose: "",
    multipleBarrels: "",
    multiplelots: "",
    size: "",
    quantity: "",
    description: "",
  });

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    if (!purpose) {
      newErrors.purpose = "Please select a purpose";
      isValid = false;
    }

    if (!multipleBarrels) {
      newErrors.multipleBarrels = "Please select an option";
      isValid = false;
    }

    if (!multiplelots) {
      newErrors.multiplelots = "Please select an option";
      isValid = false;
    }

    if (!size) {
      newErrors.size = "Please select a size";
      isValid = false;
    }

    if (!quantity) {
      newErrors.quantity = "Please enter a quantity";
      isValid = false;
    }

    if (!description.trim()) {
      newErrors.description = "Please enter required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  //handle show popup
  const handleShow = () => {
    setShow(true);
    checkSampleOrderStatus();
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    value.trim().length !== 0
      ? (errors.description = "")
      : (errors.description = "Please enter description");
    console.log(value);
    setDescription(value);

    //check the length of description
    if (value.trim().length < 1) {
      setDescriptionError("description is required");
    } else {
      setDescriptionError("");
    }
  };

  const handleInputTextChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    value.trim().length !== 0
      ? (errors.quantity = "")
      : (errors.quantity = "Please enter quantity");
    setQuantity(value);
  };

  //handle submit
  const handleSubmit = () => {
    if (validateForm()) {
      console.log("Description:", description);
      console.log("Id is", id);

      const sampleOrder = {
        description: description,
        inventoryId: id,
        inventoryName: inventoryName,
        purpose: purpose,
        multipleBarrels: multipleBarrels == "yes" ? true : false,
        multiplelots: multiplelots == "yes" ? true : false,
        size: size,
        quantity: quantity,
        sampleRequestStatus: Constant.SampleRequestStatus.PENDING_REVIEW,
        companyId: CommonService.getCompanyId(),
      };

      console.log(sampleOrder);
      //check the status
      if (status == true) {
        //call the update api
        console.log("updated called");
        updateSampleOrderRequest(sampleOrder);
        setSampleOrderRequestUpdated(true);
      } else {
        //call the new Sample Order Request api
        console.log("created api");
        addSampleOrderRequest(sampleOrder);
        setSampleOrderRequestCreated(true);
      }
    }
  };

  const addSampleOrderRequest = async (sampleOrder: any) => {
    setIsLoading(true);
    try {
      //call the service
      const response = await OrderApiService.saveSampleRequest(
        sampleOrder
      ).then((response) => {
        setIsLoading(false);
        console.log("order request added");
      });
    } catch (error) {
      setIsLoading(false);
      console.log("error adding the sample order request" + error);
    }
  };

  const updateSampleOrderRequest = async (sampleOrder: any) => {
    setIsLoading(true);
    try {
      const response = OrderApiService.updateSampleRequest(sampleOrder).then(
        (response) => {
          setIsLoading(false);
          console.log("order request updated successfully");
        }
      );
    } catch (error) {
      setIsLoading(false);
      console.log("error occurred in sample order request updation");
    }
  };

  function handleRadioChangeMultipleBarrels(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    errors.multipleBarrels = "";
    setMultipleBarrels(event.target.value);
  }

  function handleRadioChangeMultiplelots(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    errors.multiplelots = "";
    setMultiplelots(event.target.value);
  }

  const handlePurposeDropdown = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    errors.purpose = "";
    setPurpose(event.target.value);
  };

  const handleSizeDropdown = (event: React.ChangeEvent<HTMLSelectElement>) => {
    errors.size = "";
    setSize(event.target.value);
  };

  //call the service and check user is already created sample order request or not
  const checkSampleOrderStatus = async () => {
    try {
      const sampleOrder = {
        inventoryId: id,
      };
      const response = await OrderApiService.isSampleRequestCreated(
        sampleOrder
      ).then((response) => {
        const data = response.data;
        if (data != "") {
          console.log(response.data);
          setStatus(true);
          setPurpose(data.purpose);
          setMultipleBarrels(data.multipleBarrels === true ? "yes" : "no");
          setMultiplelots(data.multiplelots === true ? "yes" : "no");
          setSize(data.size);
          setQuantity(data.quantity);
          setDescription(data.comment);
        }
      });
    } catch (error) {
      console.log("error occured while status checking");
      setDescription("");
    }
  };

  return (
    <>
      <Tooltip title="Request Sample" arrow>
        <Button variant="contained" onClick={handleShow}>
          {/* Request Sample */}
          <OpacityIcon sx={{ color: "#496673" }} />
        </Button>
      </Tooltip>
      {text && (
        <Typography
          variant="body2"
          sx={{ color: "#242424", fontWeight: 600, fontSize: "16px", ml: 1 }}
          onClick={handleShow}
        >
          Request Sample
        </Typography>
      )}
      {/* )} */}

      {/* {status && (
        <Button variant="contained" sx={{ ml: 2, my: 1 }} onClick={handleShow}>
          <DriveFileRenameOutlineOutlinedIcon sx={{ mr: 1 }} />
          Edit Sample Request
        </Button>
      )} */}

      <Modal
        show={show}
        onHide={handleClose}
        className="modal__wd modal__wd--submitSellReq"
      >
        <Modal.Header className="flex-column align-items-start">
          {!status && (
            <Modal.Title className="mb-1">Request Sample</Modal.Title>
          )}
          {status && (
            <Modal.Title className="mb-1"> Request Sample</Modal.Title>
          )}
          {!isSampleOrderRequestCreated && !isSampleOrderRequestUpdated && (
            <p className="modal-text text-start">
              Please provide the details to request sample
            </p>
          )}
          {isSampleOrderRequestCreated && (
            <p className="modal-text text-start">
              Your Sample order request is added successfully !
            </p>
          )}
          {isSampleOrderRequestUpdated && (
            <p className="modal-text text-start">
              Your Sample order request is updated successfully !
            </p>
          )}
        </Modal.Header>
        {!isSampleOrderRequestCreated && !isSampleOrderRequestUpdated && (
          <Modal.Body>
            <Form.Group>
              <div className="d-flex flex-column mb-3">
                <Form.Label className="mb-2">Purpose <span className="star-required text-danger">*</span></Form.Label>
                <div className="form__inner position-relative">
                  <Form.Select
                    style={{ cursor: "pointer" }}
                    value={purpose}
                    className={`form-control mb-1 ${
                      errors.purpose ? "is_invalid" : ""
                    }`}
                    onChange={handlePurposeDropdown}
                  >
                    <option value="">Select Purpose</option>
                    <option value="Taste Profile Analysis">
                      Taste Profile Analysis
                    </option>
                    <option value="Product Testing">Product Testing</option>
                    <option value="Quantity Testing">Quantity Testing</option>
                  </Form.Select>
                  {errors.purpose && (
                    <div className="error-message position-absolute lh-1 mt-0">{errors.purpose}</div>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-center mb-3">
                <Form.Label className="mb-0 pe-3">
                  Multiple barrels from same lot? <span className="star-required text-danger">*</span>
                </Form.Label>
                <div className="form__inner position-relative">
                  <div
                    className="form-check form-check-inline mb-0 ps-0"
                    style={{ cursor: "pointer" }}
                  >
                    <Form.Check
                      type="radio"
                      id="radioOption1"
                      name="radioOptions1"
                      value="yes"
                      label="Yes"
                      onChange={handleRadioChangeMultipleBarrels}
                      checked={multipleBarrels === "yes"}
                      style={{ cursor: "pointer", paddingLeft: 0 }}
                    />
                  </div>
                  <div
                    className="form-check form-check-inline mb-0 ps-0"
                    style={{ cursor: "pointer" }}
                  >
                    <Form.Check
                      type="radio"
                      id="radioOption2"
                      name="radioOptions1"
                      value="no"
                      label="No"
                      onChange={handleRadioChangeMultipleBarrels}
                      checked={multipleBarrels === "no"}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {errors.multipleBarrels && (
                    <div className="error-message position-absolute mt-0 lh-1">
                      {errors.multipleBarrels}
                    </div>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-center mb-3">
                <Form.Label className="mb-0 pe-3">Multiple lots needed? <span className="star-required text-danger">*</span></Form.Label>
                <div className="form__inner position-relative">
                  <div className="form-check form-check-inline mb-0 ps-0">
                    <Form.Check
                      type="radio"
                      id="radioOption3"
                      name="radioOptions2"
                      value="yes"
                      label="Yes"
                      onChange={handleRadioChangeMultiplelots}
                      checked={multiplelots === "yes"}
                      style={{ cursor: "pointer", paddingLeft: 0 }}
                    />
                  </div>
                  <div className="form-check form-check-inline mb-0 ps-0">
                    <Form.Check
                      type="radio"
                      id="radioOption4"
                      name="radioOptions2"
                      value="no"
                      label="No"
                      onChange={handleRadioChangeMultiplelots}
                      checked={multiplelots === "no"}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                  {errors.multiplelots && (
                    <div className="error-message position-absolute mt-0 lh-1">{errors.multiplelots}</div>
                  )}
                </div>
              </div>
              <div className="d-flex flex-column mb-3">
                <Form.Label className="mb-2">Size Needed? <span className="star-required text-danger">*</span></Form.Label>
                <div className="form__inner position-relative">
                  <Form.Select
                    style={{ cursor: "pointer" }}
                    className={`form-control mb-1 ${
                      errors.size ? "is_invalid" : ""
                    }`}
                    value={size}
                    onChange={handleSizeDropdown}
                  >
                    <option value="">Select Size</option>
                    <option value="750mL">750mL</option>
                    <option value="200mL">200mL</option>
                    <option value="100mL">100mL</option>
                  </Form.Select>
                  {errors.size && (
                    <div className="error-message position-absolute lh-1 mt-0">{errors.size}</div>
                  )}
                </div>
              </div>
              <div className="d-flex flex-column mb-3">
                <Form.Label className="mb-2">Qty Requested of Size <span className="star-required text-danger">*</span></Form.Label>
                <div className="form__inner position-relative">
                  <Form.Control
                    className={`mb-1 ${errors.quantity ? "is_invalid" : ""}`}
                    type="text"
                    value={quantity}
                    onChange={handleInputTextChange}
                    placeholder="Enter Qty..."
                  />
                  {errors.quantity && (
                    <div className="error-message position-absolute lh-1 mt-0">{errors.quantity}</div>
                  )}
                </div>
              </div>
              <div className="d-flex flex-column mb-3">
                <Form.Label className="mb-2">Request Notes <span className="star-required text-danger">*</span></Form.Label>
                <div className="form__inner position-relative">
                  <Form.Control
                    as="textarea"
                    className={`form-control ${
                      errors.description ? "is_invalid" : ""
                    }`}
                    style={{ resize: "none" }}
                    aria-label="With textarea"
                    value={description}
                    onChange={handleInputChange}
                    rows={8}
                    placeholder="Enter notes..."
                  />
                  {errors.description && (
                    <div className="error-message position-absolute lh-1">{errors.description}</div>
                  )}
                </div>
              </div>
            </Form.Group>
          </Modal.Body>
        )}
        {!isSampleOrderRequestCreated && !isSampleOrderRequestUpdated && (
          <Modal.Footer className="justify-content-center">
            <div className="btn-group row d-flex justify-content-between w-100">
              <div className="col-6 ps-0">
                <button className="secondary-btn w-100" onClick={handleClose}>
                  Cancel
                </button>
              </div>
              <div className="col-6 pe-0">
                <button
                  className="primary-btn w-100"
                  onClick={handleSubmit}
                  disabled={isLoading}
                >
                  {isLoading && <Spinner />} Submit Request
                </button>
              </div>
            </div>{" "}
          </Modal.Footer>
        )}

        {(isSampleOrderRequestCreated || isSampleOrderRequestUpdated) && (
          <Modal.Footer className="justify-content-center bg-transparent">
            <div className="btn-group row d-flex justify-content-between w-100">
              <div>
                <button
                  className="secondary-btn w-100"
                  onClick={handleClose}
                  disabled={isLoading}
                >
                  Close
                </button>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default SampleOrder;
