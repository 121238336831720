import React, { useEffect, useState } from 'react';
import { Service } from '../../Service/ApiService';
import { Button } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router';
import StatusDropdown from '../Common/StatusDropdown';
import { CommonService } from '../../Service/CommonService';
const RecentRequest: React.FC = () => {

    const [recentRequests, setRecentRequests] = useState<any[]>([]);
    const { id } = useParams<{ id: string }>();
    const location = useLocation();
    const isDashboard = location.pathname === '/master/dashboard';

    const fetchRecentRequestCount = async () => {
        
        const params = CommonService.isUser() ? {id : CommonService.getCompanyId()} : {id};
        const response: any = await Service.fetchRecentRequestCount(params);
        setRecentRequests(response.data);
    }

    const toTitleCase = (str: string) => {
        return str
            .replace(/_/g, " ") // Replace underscores with spaces
            .toLowerCase()
            .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
    };

    useEffect(() => {
        fetchRecentRequestCount();
    }, [])


    function formatStatus(status: string): string {
        const formattedStatus = status
          .split(/[_\\]/)
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(" ");
    
        return formattedStatus;
      }

    return(
        <div className="row mt-5">
        <div className="d-flex justify-content-between mb-3">
            <h5 className='mb-0'><b>Recent Requests</b></h5>
            {/* <Button className='p-0' variant="link" onClick={() => route("/master/messages")}>View all</Button> */}
        </div>
        <div className="table-responsive">
            <table className="recentRequests__table">
                <thead>
                    <th>Request Type</th>
                    {isDashboard && (<th>Company Name</th>)}
                    <th>User</th>
                    {isDashboard && (<th>Email</th>)}
                    {isDashboard && (<th>Phone</th>)}
                    <th>Created At</th>
                    <th>Order Status</th>
                </thead>
                <tbody>
                    {recentRequests.map((request: any) => (
                        <tr>
                            {/* key={request.requestId} */}
                            <td>{request.requestType}</td>
                            {isDashboard && (<td>{request.companyName}</td> )}
                            <td>{request.createdBy}</td>
                            {isDashboard && (<td>{request.email}</td> )}
                            {isDashboard && (<td>{request.phoneNumber}</td> )}
                            <td>{CommonService.formatDateTime(request.modifiedDate)}</td>
                            <td>
                                <div>
                                    <StatusDropdown
                                    currentStatus={formatStatus(request.status)}
                                    onStatusChange={(newStatus)=>{

                                    }}
                                    disableCondition={true}
                                    allowedLabels={[]}
                                    />

                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
        {recentRequests.length == 0 && (<p className="no-record">No Recent Request found</p>)}
    </div>
    );
}

export default RecentRequest;