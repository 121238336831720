import React, { useEffect, useState } from "react";
import { Service } from "../../Service/ApiService";
import "../Dynamic/Dashboard.scss";
import OfferPercentage from "../../assets/images/icons/OfferPercentage.svg";
import SampleDrop from "../../assets/images/icons/Sample.svg";
import ShoppingCartSimple from "../../assets/images/icons/ShoppingCartSimple.svg";
import SellRequestSimple from "../../assets/images/icons/SellRequestSimple.svg";
import MailOutlineSharpIcon from "@mui/icons-material/MailOutlineSharp";
import { useHistory, useParams } from "react-router";
import { CommonService } from "../../Service/CommonService";

const UnreadMessage: React.FC = () => {
  const history = useHistory();
  const [unreadMessages, setUnreadMessages] = useState<any[]>([]);
  const [totalMessageCount, setTotalMessageCount] = useState(0);
  const { id } = useParams<{ id: string }>();

  function route(route: string) {
    history.push(route);
  }

  const toTitleCase = (str: string) => {
    return str
      .replace(/_/g, " ") // Replace underscores with spaces
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };

  const fetchUnreadMessages = async () => {
    try {

      const params = CommonService.isUser() ? { id: CommonService.getCompanyId() } : { id };
      const response: any = await Service.fetchUnreadMessages(params);
      setUnreadMessages(response.data);

      const totalCount = response.data.reduce((sum: number, message: any) => {
        const messageCount = JSON.parse(message.messages[0]).length || 0;
        return sum + messageCount;
      }, 0);

      setTotalMessageCount(totalCount);
    } catch (error) {
      console.error("Error fetching unread messages:", error);
    }
  };

  useEffect(() => {
    fetchUnreadMessages();
  }, []);

  return (
    <div className="col-xl-5">
      <div className="dashboard__messageBox mb-5 mb-xl-0">
        <div className="dashboard__messageBox--title">
          <h4 className="mb-0 lh-base">Unread Messages</h4>
          <div className="msg-count position-relative cursor-pointer" onClick={() => CommonService.isAdmin() ? route('/master/messages') : route('/user/messages')}>
            <MailOutlineSharpIcon sx={{ color: "#97AEB8" }} />
            <span className="count position-absolute top-0 start-100 translate-middle badge">
              {totalMessageCount}
            </span>
          </div>
        </div>
        {unreadMessages.map((message: any) => (
          <div className="dashboard__messageBox--wrapper">
            <div className="row d-flex m-0">
              <div className="col-auto px-1 ps-0">
                <div className="user__avatar">
                  {message?.createdBy?.charAt(0)}
                </div>
              </div>
              <div className="col-auto px-1">
                <div className="d-flex flex-column">
                  <div className="user__name">{message.createdBy}</div>
                  <div className="d-flex">
                    <div className="user__companyname me-1">
                      {message.companyName}
                    </div>
                    <div>|</div>
                    <div className="user__reqType ms-1">
                      {message.requestType == "OFFER_REQUEST" && (
                        <img src={OfferPercentage} alt="OfferPercentage" />
                      )}

                      {message.requestType == "SAMPLE_REQUEST" && (
                        <img src={SampleDrop} alt="SampleDrop" />
                      )}

                      {message.requestType == "ORDER_REQUEST" && (
                        <img src={ShoppingCartSimple} alt="ShoppingCartSimple" />
                      )}

                      {message.requestType == "SELL_REQUEST" && (
                        <img src={SellRequestSimple} alt="SellRequestSimple" />
                      )}

                      {toTitleCase(message.requestType)}
                    </div>
                  </div>
                  <div className="user__message">
                    <p>
                      {
                        JSON.parse(message.messages[0])[
                        JSON.parse(message.messages[0]).length - 1
                        ]
                      }
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-auto px-1 pe-0 ms-auto">
                <div className="user__requestTime text-end">
                  {/* {message.modifiedDate} */}
                  <span className="date">
                    {message.modifiedDate.split(" | ")[0]}
                  </span>
                  <br />
                  <span className="time">
                    {message.modifiedDate.split(" | ")[1]}
                  </span>
                </div>
              </div>
              {/* <div className="request__header">{message.companyName}</div>
                    <div className="details">{message.createdBy} | {message.requestType}</div>
                    <ul className="message-list">
                        {message.messages.map((msg: any) => (
                            <li className="message-item">{msg}</li>
                        ))}
                    </ul>
                    <div className="details">{message.modifiedDate}</div> */}
            </div>
          </div>
        ))}



      </div>
      {totalMessageCount == 0 && (<p className="no-record">No Message Found</p>)}
    </div>
  );
};
export default UnreadMessage;
